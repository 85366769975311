import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { ApiManager } from './../common/api-manager.service';
import { ConstantsService } from './../common/constants.service';
import { HelpTextContainer } from './../model/api/help-text-container.class';

@Injectable()
export class HelpTextService {



  constructor(private http: Http, private router: Router, private apiManager: ApiManager, private consts: ConstantsService) { }

  // getHelpText(requestModuleName: string, requestPageName: string, requestSectionID: string) {
  //   let params: any = [];
  //   params["moduleName"] = requestModuleName;
  //   params["page"] = requestPageName;
  //   if (requestSectionID) {    // optional
  //     params["sectionId"] = requestSectionID;
  //   }
  //   this.apiManager.fetchData('helpText', params, ApiManager.GET, undefined)
  //     .map((res: Response) => {
  //       return res;
  //     }).subscribe(res => {
  //       let helpContainer: HelpTextContainer = res as any as HelpTextContainer;
  //       if (helpContainer && helpContainer.helpTexts && helpContainer.helpTexts[requestModuleName]) {
  //         for (let module of helpContainer.helpTexts[requestModuleName]) {
  //           if (module[requestPageName]) {
  //             for (let hepltexts of module[requestPageName]) {
  //               ConstantsService.helpTextMap.set(hepltexts.sectionId, hepltexts.helpText);
  //             }

  //           }
  //         }

  //       }
  //     });
  // }
}
