
import { BaseBean } from './base-bean.class';
export class InterviewSteps extends BaseBean{
    constructor(
        public initiateInterviewComplete: boolean = false, 
        public programStatusComplete: boolean = false,
        public programStatusRequired: boolean = false,
        public householdInformationRequired: boolean = false,
        public householdInformationComplete: boolean = false, 
    //	this is under HouseholdInformation
        public individualDetailsRequired: boolean = false,
        public individualDetailsComplete: boolean = false, 
        public citizenshipOrImmigrationRequired: boolean = false,
        public citizenshipOrImmigrationComplete: boolean = false, 
        public studentInformationRequired: boolean = false,
        public studentInformationComplete: boolean = false,
        public healthOrDisabilityRequired: boolean = false,
        public healthOrDisabilityComplete: boolean = false,
        public institutionInformationRequired: boolean = false,
        public institutionInformationComplete: boolean = false, 
        public contactInformationRequired: boolean = false,
        public contactInformationComplete: boolean = false, 
        public financialInformationRequired: boolean = false,
        public financialInformationComplete: boolean = false, 
        public emergencyAssitanceInformationRequired: boolean = false,
        public emergencyAssitanceInformationComplete: boolean = false, 
        public administrativeInformationRequired: boolean = false,
        public administrativeInformationComplete: boolean = false,
        public summaryRequired: boolean = false,
        public summaryComplete: boolean = false,
        public trialBudgetComplete: boolean = false, 
        public interviewComplete: boolean = false,
        public taxInformationRequired:boolean = false,
        public taxInformationComplete: boolean = false
    ){
        super();
    }
}
