import { BaseBean } from './base-bean.class';
import { TrialBudgetRequest } from './trial-budget-request.class';
import { TrialBudgetAmountType } from './trial-budget-amount-type.class';
export class TrialBudget extends BaseBean{
    constructor(
        public request: TrialBudgetRequest = new TrialBudgetRequest(),
        public eligibleBudget: Set<TrialBudgetAmountType> = null,
        public budgetType: string = '',
        public reason: string = ''
    ) {
        super();
    }
}
