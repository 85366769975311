import { BaseBean } from './base-bean.class';
export class PersonName extends BaseBean{
    constructor(
        public recordId: number = undefined,
        public firstName: string = '',
        public lastName: string = '',
        public middleName: string = '',
        public type: string = '',
        public suffix: string = '' // enum
    ) {
        super();
    }
}
