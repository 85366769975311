export class hohMembersClass {

  constructor(
    public firstName: string = '',
    public middleName: string = '',
    public lastName: string = '',
    public suffix: string = '',
    public dateOfBirth: any = null,
    public individualId: string = '',
    public relationship: string = '',
    public controlId: number = null,
    public personId: any = '',
    public dirtyStatus: string = '',
    public dummyIndividualId: string = '',
    public caseId: string = '',
    public isHoh: string = '',
    public gender: string = '',
    public importSelected: boolean = false,
    public clearanceStatus: string = null,
    // public cpIndividualId: string = '',
    // public deleteInd: string = ''
    ){

  }

}
