import { Worker } from './worker.class';
import { AppointmentManagement } from './appointment-management.class';
export class ScheduleInterview extends AppointmentManagement {
    constructor(
        public worker: Worker = new Worker(),
        public schduleInterviewId: number = undefined,
        public inquiryDate: Date = null, // date
        public districtOffice: string = '',
        public emailAssistanceRequestFormYN: string = ''
    ) {
        super();
    }
}
