import { ClientVerification } from './client-verification.class';
import { BaseBean } from './base-bean.class';

export class IndividualTaxStatus extends BaseBean {
  constructor(
    public lastYearTaxFilingStatusCode: string = '',
    public thisYearTaxFilingStatusCode: string = '',
    public lastYearPrimaryFilerIndicator: string = '',
    public thisYearPrimaryFilerIndicator: string = '',
    public lastYearDependentClaimingPersonId: string = '',
    public thisYearDependentClaimingPersonId: string = '',
    public thisYearIncomeAmount: string = '',
    public dataMatchPercentDifference: string = '', 
    public incomeVerification: ClientVerification = new ClientVerification() 
  ) {
    super();
  }
}
