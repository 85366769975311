import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import {Injectable} from '@angular/core';
import {Response, ResponseContentType} from '@angular/http';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DOCUMENT} from '@angular/platform-browser';
import {Inject} from '@angular/core';
//import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {environment} from '../../environments/environment';
import {ConstantsService} from './constants.service';
import {AlertService} from './alert.service';
import {EventEmitterService} from './event.emitter.service';
import {LoaderService} from "./loader.service";
import {StorageService} from "./storage.service";
//import { NgxSpinnerService } from 'ngx-spinner';

let wso2Version = '';
let sdrWso2Version = '';

if (environment.isWso2) {
  wso2Version = environment.isWso2Ver;
  sdrWso2Version = environment.sdrWso2Ver;
}  

@Injectable()
export class ApiManager {

  static readonly customLogin: string = '/access-management' + wso2Version + '/dummy-login';
  static readonly accessSecurity: string = 'access-management' + wso2Version + '/lookup';
  static readonly lookup: string = 'public' + wso2Version + '/lookup';
  static readonly lookupType: string = 'public' + wso2Version + '/lookup?lookupType=DistrictOffice';
  static readonly getTimeSlots: string = 'public' + wso2Version + '/consumer/get-available-slots';
  static readonly getScheduledInterviews: string = 'public' + wso2Version + '/consumer/get-scheduled-interviews';
  static readonly interviewScheduler: string = 'public' + wso2Version + '/consumer/schedule-interview';
  static readonly applicationProcess = 'application-process' + wso2Version + '/screening/~~';
  static readonly activateFeatures = 'public' + wso2Version + '/activate-features';
  static readonly baseJsonUrlDev: String = './assets/mock-data';
  static readonly localLookupUrl: string = '/lookupsApi.json';
  static readonly indivStats: string = 'public' + wso2Version + '/consumer/case-count';
  static readonly cboAdminStats: string = 'public' + wso2Version + '/consumer/cbo-case-count';
  static readonly householdMember3Url: string = ConstantsService.deploymentMode + '/householdmember3.json';
  static readonly interviewServiceUrl: string = 'application-process' + wso2Version + '/retrieveCases';
  static readonly retrieveCaseNarrativeByCaseId = 'application-process' + wso2Version + '/~~/narrative';
  static readonly finalizeServiceUrl: string = ConstantsService.deploymentMode + '/finalizeService.json';
  static readonly customerParticipationUrl: string = 'application-process' + wso2Version + '/inquiry/cdbp-search';
  static readonly getDocumentsForScreen = 'application-process' + wso2Version + '/screening/documents/~~/individual/~~/location/~~';
  public static readonly documentsForAllNHCaseUrl = 'application-process' + wso2Version + '/screening/documents/~~/~~';
  public static readonly documentsForACaseWithPagination = 'application-process' + wso2Version + '/screening/getUploadedDocuments';
  static readonly cisUrl: string = 'application-process' + wso2Version + '/retrieveCases?moduleType=screening&moduleType=interview';
  static readonly authorizedRepresentativeUrl: string = ConstantsService.deploymentMode + '/authorizedRepresentative.json';
  static readonly addressUSUrl: string = ConstantsService.deploymentMode + '/addressUS.json';
  static readonly elegibilityServiceUrl: string = ConstantsService.deploymentMode + '/elegibilityService.json';
  static readonly scrapHeader = 'application-process' + wso2Version + '/scrap-header';
  static readonly programScreening = 'public' + wso2Version + '/consumer/program-screening';
  public static readonly POST = 1;
  public static readonly GET = 2;
  public static readonly PUT = 3;
  public static readonly JSONFILE = 4;
  public static readonly FILEUPLOAD = 5;
  public static readonly FILEDOWNLOAD = 6;
  public static readonly FILEBULKDOWNLOAD = 9;
  public static readonly FILEDELETE = 7;
  public static readonly CUSTOMFILEDOWNLOAD = 8;
  public static readonly SUBMIT = 10;
  public static readonly FILEDOWNLOADGET = 11;
  public static readonly DOCGET = 12;
  public static readonly DOWNLOAD = 13;
  public static readonly contactInfoUrl = 'application-process' + wso2Version + '/screening/~~/contact';
  public static readonly eligibilityStatus = 'case-management' + wso2Version + '/get_member_details/~~';
  public static readonly icInitiate = 'case-management' + wso2Version + '/cp_ic_initiate';
  public static readonly icSave = 'case-management' + wso2Version + '/~~/cp_ic_save/~~';
  public static readonly icSearch = 'case-management' + wso2Version + '/case-management/interim/case-search/~~/~~';
  public static readonly icGet = 'case-management' + wso2Version + '/cp_ic_retrieve';
  public static readonly documentsForACaseUrl = 'application-process' + wso2Version + '/screening/documents/~~';
  public static readonly ldssAddressUrl = 'public' + wso2Version + '/ldss-assistance/~~';
  public static readonly contactInterview = 'application-process' + wso2Version + '/interview/~~/contact';
  public static readonly authorizedRepresentative = 'application-process' + wso2Version + '/~~/auth-rep';
  public static readonly HOUSEHOLDCIRCUMSTANCE = '/household-circumstances';
  public static readonly labelLookUp = 'public' + wso2Version + '/labelLookUp';
  public static readonly consumerNav = 'public' + wso2Version + '/consumer/navigation';
  public static readonly getConsumerApps = 'public' + wso2Version + '/consumer/Application/get';
  public static readonly searchConsumerApps = 'public' + wso2Version + '/consumer/Application/search';
  public static readonly searchConsumerARApps = 'public' + wso2Version + '/consumer/Application/arsearch';
  public static readonly searchConsumerARHistoryApps = 'public' + wso2Version + '/consumer/Application/arhistory';
  public static readonly insertConsumerApps = 'public' + wso2Version + '/consumer/Application/new';
  public static readonly insertConsumerARApps = 'public' + wso2Version + '/consumer/ArApplication/new';
  public static readonly updateConsumerApps = 'public' + wso2Version + '/consumer/Application';
  public static readonly nursingHomeStaffList = 'public' + wso2Version + '/consumer/Application/nursingHomeStaffList/~~';
  public static readonly CBOStaffList = 'public' + wso2Version + '/consumer/Application/CBOStaffList/~~';
  public static readonly nursingHomeList = 'public' + wso2Version + '/consumer/Application/nursingHomeList/~~';
  public static readonly bulletinBoard = 'public' + wso2Version + '/bulletinboard';
  public static readonly managerApplications = 'public' + wso2Version + '/consumer/Application/managerApplications';
  public static readonly assignNursingHomeStaff = 'public' + wso2Version + '/consumer/assignNursingHomeStaff';
  public static readonly workItem = 'work-item' + wso2Version + '/create-work-item/~~';
  public static readonly caseAssignedTo = 'work-item' + wso2Version + '/case-assigned-to/~~';
  public static readonly PROGRAM = '/program-selection';
  public static readonly SCREENING = 'application-process' + wso2Version + '/screening';
  public static readonly userAnalytics = 'application-process' + wso2Version + '/user-analytics/';
  public static readonly correspondenceGetUrl = 'application-process' + wso2Version + '/cc/search/~~';
  public static readonly correspondanceInsert = 'application-process' + wso2Version + '/cc/insert/~~';
  public static readonly getNotifications = 'application-process' + wso2Version + '/~~/getNotifications';
  public static readonly updateNotifications = 'application-process' + wso2Version + '/updateNotification';
  public static readonly ccidInsert = 'application-process' + wso2Version + '/retrieveEcmDocs';
  static readonly saveClient: string = 'client-management' + wso2Version + '/new';
  static readonly saveClientForCP: string = 'client-management' + wso2Version + '/new/~~';
  static readonly clearanceSearchUrl: string = 'client-management' + wso2Version + '/clearance';
  static readonly customerDetails: string = 'client-management' + wso2Version + '/~~';
  static readonly individualDetails: string = 'client-management' + wso2Version + '/individual/~~';
  public static readonly editMemberWithSSN = 'client-management' + wso2Version + '/~~/cp/edit-member';
  static readonly saveHouseholdUrl: string = 'application-process' + wso2Version + '/screening/~~/household';
  static readonly scheduleInterview: string = 'application-process' + wso2Version + '/screening/~~/schedule-interview';
  static readonly withdrawApplication: string = 'application-process' + wso2Version + '/screening/~~/withdraw-application';
  static readonly screeningGeneratePDF: string = 'application-process' + wso2Version + '/screening/~~/summarypdf';
  static readonly interviewsummarypdf: string = 'application-process' + wso2Version + '/interview/~~/interviewsummarypdf';
  static readonly addressVerify: string = 'verification' + wso2Version + '/~~/address';
  static readonly householdVerify: string = 'verification' + wso2Version + '/~~/household';
  static readonly fileUpload: string = 'document' + wso2Version + '/~~/upload';
  static readonly caseSubmit: string = 'http://internal-mdteee1dev4-ccapp-elb-1632599036.us-east-1.elb.amazonaws.com/ccapi/createTrigger';
  public static readonly taxInformation = 'application-process' + wso2Version + '/interview/~~/tax-filingInformation';
  static readonly fileUploadCitizenship: string = 'document' + wso2Version + '/~~/uploadCitizenship';
  static readonly fileDownload: string = 'document' + wso2Version + '/~~/download';
  static readonly sidFileDownload: string = 'document' + wso2Version + '/download/sid';
  static readonly fileDownloadCorrespondence: string = 'application-process' + wso2Version + '/cc/pdfMerge/~~';
  static readonly fileBulkDownload: string = 'document' + wso2Version + '/bulkDownload';
  static readonly fileDownloadCitizenship: string = 'document' + wso2Version + '/~~/downloadCitizenship';
  static readonly fileDelete: string = 'document' + wso2Version + '/~~/delete';
  static readonly fileDeleteCitizenship: string = 'document' + wso2Version + '/~~/deleteCitizenship';
  static readonly newCase: string = 'application-process' + wso2Version + '/new';
  static readonly saveExistingMember = 'application-process' + wso2Version + '/~~/add-member';
  static readonly editExistingMember = 'application-process' + wso2Version + '/~~/edit-member';
  static readonly deleteExistingMember = 'application-process' + wso2Version + '/~~/delete-member';
  static readonly interviewStudent = 'application-process' + wso2Version + '/interview/~~/household/~~/student';
  static readonly intervHealthDisab = 'application-process' + wso2Version + '/interview/~~/household/~~/health-disability';
  static readonly addMedicare = 'application-process' + wso2Version + '/interview/~~/household/~~/add-medicare';
  static readonly editMedicare = 'application-process' + wso2Version + '/interview/~~/household/~~/edit-medicare';
  static readonly intervInstitution = 'application-process' + wso2Version + '/interview/~~/household/~~/institution';
  static readonly intervEduDetail = 'application-process' + wso2Version + '/interview/~~/household/~~/student';
  static readonly intervAsset = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/asset';
  static readonly intervInsurance = 'application-process' + wso2Version + '/interview/~~/~~/health-insurance';
  static readonly trialBudget = 'application-process' + wso2Version + '/trial-budget/new/eligibility';
  static readonly trialBudgetEligibility = 'application-process' + wso2Version + '/trial-budget/~~/eligibility';
  static readonly county = '/lookupsApi.json';
  static readonly interviewHouseholdCitizenship = 'application-process' + wso2Version + '/interview/~~/household/~~/citizenship-immigration';
  static readonly voterRegistration = 'application-process' + wso2Version + '/interview/~~/household/~~/voter-registration';
  static readonly interviewHouseholdVeteran = 'application-process' + wso2Version + '/interview/~~/household/~~/veteran-information';
  static readonly unearnedIncomeUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/unearned-income';
  static readonly assetsUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/asset-income/new';
  static readonly shelterUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/shelter-expenses';
  static readonly utilityUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/utility-expense';
  static readonly interviewMedical = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/medical-expenses';
  static readonly dependCareUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/dependent-care-expenses';
  static readonly earnedIncomeUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/earned-income';
  static readonly earnedIncomeUpdateUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/earned-income';
  public static readonly changeReportingUrl = 'case-management' + wso2Version + '/~~/change-reporting';
  public static readonly narrativeSearch = 'application-process' + wso2Version + '/narrative/search';
  public static readonly saveNarrative = 'application-process' + wso2Version + '/~~/~~/narrative';
  public static readonly eaInformation = 'application-process' + wso2Version + '/interview/~~/ea-information';
  public static readonly emergencyAssistance = 'application-process' + wso2Version + '/interview/~~/emergency-assistance';
  public static readonly absentParentIdentification =
    'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/identification';
  public static readonly absentParentContact = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/contact';
  public static readonly absentParentEmployment =
    'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/employment';
  public static readonly absentParentOtherIncome =
    'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/employment';
  public static readonly absentParentCourtOrder =
    'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/court-order';
  public static readonly customerSearchUrl = 'application-process' + wso2Version + '/inquiry/customer-search';
  public static readonly instituteSearchUrl = 'application-process' + wso2Version + '/inquiry/vendor-search';
  public static readonly unearnedIncomeUpdateUrl =
    'application-process' + wso2Version + '/interview/~~/financial-information/~~/unearned-income';
  public static readonly saveTaxInformationUrl = 'application-process' + wso2Version + '/interview/~~/tax-information';
  public static readonly saveHouseholdInfoUrl = 'application-process' + wso2Version + '/interview/~~/household';
  public static readonly caseSearchUrl = 'application-process' + wso2Version + '/inquiry/case-search';
  public static readonly saveFinancialInfoUrl = 'application-process' + wso2Version + '/interview/~~/financial-information';
  public static readonly retrieveOrCreateCase = 'application-process' + wso2Version + '/inquiry/retrieveCustomerCase/~~';
  public static readonly realTimeCaseStatus = 'public' + wso2Version + '/consumer/real-time-case-status/~~';
  public static readonly appliedUnearnedIncomeUpdateUrl =
    'application-process' + wso2Version + '/interview/~~/financial-information/~~/applied-unearned-income';
  public static readonly interviewMemberIdentificationUrl = 'application-process' + wso2Version + '/interview/~~/household/~~/individual';
  public static readonly administrativeInformationUrl = 'application-process' + wso2Version + '/interview/~~/administrative-information';
  public static readonly conditionalCaseRetrieveUrl = 'application-process' + wso2Version + '/conditionalCaseRetrieve/~~';
  public static readonly initiateFinalizeUrl = 'application-process' + wso2Version + '/finalize/~~';
  public static readonly programStatus = 'application-process' + wso2Version + '/interview/~~/program-status';
  public static readonly finalizeEligibility = 'application-process' + wso2Version + '/finalize/~~/eligibility';
  public static readonly finalizeEligibilityRunMonths = 'application-process' + wso2Version + '/finalize/~~/run-months';
  public static readonly interviewSummary = 'application-process' + wso2Version + '/interview/~~/summary';
  public static readonly interviewSummarySignature = 'application-process' + wso2Version + '/interview/~~/signature';
  public static readonly programFinalization = 'application-process' + wso2Version + '/finalize/~~/confirm/~~';
  static readonly citizenshipVerify: string = 'verification' + wso2Version + '/~~/citizenship';
  static readonly verifyIncome: string = 'verification' + wso2Version + '/~~/income';
  public static readonly runEligibility: string = 'application-process' + wso2Version + '/screening/~~/eligibility';
  public static readonly prevEligibility: string = 'application-process' + wso2Version + '/screening/~~/prev-eligibility';
  static readonly helpText: string = 'public' + wso2Version + '/help';
  public static readonly redetermination = 'case-management' + wso2Version + '/~~/redetermination';
  public static readonly verificationChecklist = 'application-process' + wso2Version + '/interview/~~/verification-checklist';
  public static readonly inbox = 'worker-case-management' + wso2Version + '/inbox';
  public static readonly myCase = 'worker-case-management' + wso2Version + '/my-cases';
  static readonly interviewPotentialAssets = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/potentialAsset';
  static readonly deletePotentialAssets = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/delete-potentialAsset';
  public static readonly headersUrl = 'common' + wso2Version + '/headers';
  public static readonly rolesUrl = 'common' + wso2Version + '/roles';
  public static readonly ccTrigger = 'work-item' + wso2Version + '/~~/create-cc-trigger/~~';
  public static readonly sdrUrl = 'EnterpriseSearch' + sdrWso2Version + '/ee-cp-sdr-search';
  static readonly searchIndividualByMdmIdUrl = 'client-management' + wso2Version + '/mdm/individual/~~';
  public static readonly assignDO = 'client-management' + wso2Version + '/assignDO';
  public static readonly setApplicationSubmitDate = 'client-management' + wso2Version + '/applicationDate/~~';
  public static readonly emailUpdateUrl = 'application-process' + wso2Version + '/interview/account-management/updateEmail';
  public static readonly pwdUpdateUrl = 'application-process' + wso2Version + '/interview/account-management/updatePassword';
  public static readonly getSRAssessment = 'application-process' + wso2Version + '/interview/~~/spousal-resource-assessment';
  public static readonly addSRAssessment = 'application-process' + wso2Version + '/interview/~~/spousal-resource-assessment/~~/add';
  public static readonly editSRAssessment = 'application-process' + wso2Version + '/interview/~~/spousal-resource-assessment/~~/edit';
  public static readonly sendConsumerEmail = 'public' + wso2Version + '/consumer/sendEmail';
  public static readonly applyProgram = 'application-process' + wso2Version + '/screening/~~/apply-program';
  public static readonly assignCoPayWorker = 'application-process' + wso2Version + '/copay/assign-copay-worker/~~/~~/~~';
  public static readonly interimSearch = 'case-management' + wso2Version + '/interim/case-search/~~/~~';
  public static readonly redetInitiate = 'case-management' + wso2Version + '/cp/interim_redet_initiate';
  public static readonly caseDatatoCP = 'case-management' + wso2Version + '/importCaseDataToCp/~~/~~/~~';
  public static readonly initialImportData = 'case-management' + wso2Version + '/importCaseToCp/~~/~~';
  public static readonly specificImportData = 'case-management' + wso2Version + '/importDataToCp/~~/~~';
  public static readonly icredetDownload = 'case-management' + wso2Version + '/cp_ic_download/~~';
  public static readonly redetSearch = 'case-management' + wso2Version + '/redet/case-search/~~/~~';
  public static readonly pinSearch = 'case-management' + wso2Version + '/cp_redet/pincheck/~~/~~';
  public static readonly lookupUrl = 'case-management' + wso2Version + '/cp_redet/pincheck/~~/~~';
  public static readonly getDocumentsForIcRedet = 'application-process' + wso2Version + '/screening/get_inactive_documents/~~';
  public static readonly lookupSearchPost = 'public' + wso2Version + '/consumer/lookup-search';
  public static readonly institutionSearchPost = 'verification' + wso2Version + '/avs/search/institution-search';
  public static readonly lookupSearchGet = 'public' + wso2Version + '/consumer/lookup-search/~~';
  public static readonly saveIntake = 'public' + wso2Version + '/consumer/jsonSave/~~/~~';
  public static readonly getIntake = 'public' + wso2Version + '/consumer/jsonRetrieve';
  public static readonly cpInitiate = 'public' + wso2Version + '/consumer/case_initiate';
  public static readonly clearanceA = 'public' + wso2Version + '/consumer/cp-clearance';
  public static readonly caseControlSearch = 'public' + wso2Version + '/consumer/case-control-search/~~';
  public static readonly intakeSubmitApi = 'public' + wso2Version + '/consumer/~~/cp-case-submit';
  public static readonly newSubmitApi = 'public' + wso2Version + '/consumer/submit/~~/~~';
  public static readonly ipAddressCapture = 'application-process' + wso2Version + '/consumer/save-submit-details';
  public static readonly getScreenId = 'public' + wso2Version + '/consumer/screenId-lookup';
  public static readonly cpFileUpload = 'document' + wso2Version + '/~~/cp_upload';
  public static readonly cpFileUploadSid = 'document' + wso2Version + '/cp_upload/~~';
  public static readonly cpFileGet = 'document' + wso2Version + '/~~/cp_view_document';
  public static readonly cp9701Download = 'case-management' + wso2Version + '/cp_9701_download/~~';
  public static readonly caseSummary = 'case-management' + wso2Version + '/cp_case_creation_dataview/~~';
  public static readonly caseCorrespondence = 'case-management' + wso2Version + '/cp_redet/get-correspondence';
  public static readonly activeCases = 'application-process' + wso2Version + '/cp-my-benefits/~~';
  public static readonly myWorkInProgress = 'application-process' + wso2Version + '/cp-my-works/~~/~~';
  public static readonly getRelations = 'application-process' + wso2Version + '/screening/~~/household-relationship/~~';
  public static readonly getInverseRelationship = 'public' + wso2Version + '/consumer/retrieve-relationships/~~';
  public static readonly consumerSearch = 'case-management' + wso2Version + '/cp_redet/consumer-control-search';
  public static readonly getAllSidDocs = 'case-management' + wso2Version + '/cp_redet/get-sids';
  public static readonly sidDocsSearch = 'case-management' + wso2Version + '/cp_redet/get-sid-documents/~~';
  public static readonly cboList = 'case-management' + wso2Version + '/get_cboMapping/~~';
  public static readonly linkCase = 'application-process' + wso2Version + '/mybenefits/linkopencase';
  public static readonly linkSidCase = 'application-process' + wso2Version + '/mybenefits/linksiddob';
  public static readonly wpImport = 'case-management' + wso2Version + '/~~/cp_import_case/~~';
  public static readonly readySR = 'application-process' + wso2Version + '/cp-my-sr/~~';
  public static readonly myRedets = 'application-process' + wso2Version + '/cp-my-redets/~~';
  public static readonly getVoterDetails = 'public' + wso2Version + '/consumer/retrieveVoterRegistration/~~';
  public static readonly saveVoterRegDetails = 'public' + wso2Version + '/consumer/saveVoterRegistrationApplication';
  public static readonly downloadVoterApp = 'public' + wso2Version + '/consumer/downloadVoterReg/~~/~~';
  public static readonly reqPin = 'public' + wso2Version + '/consumer/generate-pin';
  public static readonly reqPinNew = 'application-process' + wso2Version + '/request-pin';
  public static readonly searchCBOReports = 'case-management' + wso2Version + '/cp_redet/cbo-reports';
  public static readonly retrieveHelpText = 'public' + wso2Version + '/consumer/retrieve-help-text';
  public static readonly indivEligStatus = 'application-process' + wso2Version + '/cp-my-benefits/status/~~';
  public static readonly indivDetailsForMAcard = 'application-process' + wso2Version + '/cp-my-benefits/indiv-details/~~';
  public static readonly reqMAcard = 'application-process' + wso2Version + '/cp-my-benefits/req-ma-card';
  public static readonly getIndividualId = 'public' + wso2Version + '/consumer/Application/getIndividualId/~~';
  public static readonly newLogicMember = 'public' + wso2Version + '/consumer/member';
  public static readonly newLogicEditMember = 'public' + wso2Version + '/consumer/member-edit/~~';
  public static readonly localIP = 'application-process' + wso2Version + '/consumer/save-submit-details';
  public static readonly eliResults = 'application-process' + wso2Version + '/eligibilityResults/~~';
  public static readonly ohepCompanies = 'Utility/OhepCompanies/~~/~~/~~';
  public static readonly ohepCompaniesWW = 'Utility/OhepCompaniesWW/~~/~~/~~';
  public static readonly ohepDoAddress = 'public' + wso2Version + '/get-ohepdoaddress/~~';
  public static readonly ohepWWCompanies = 'Utility/OhepCompanies/~~';

  // footer-family-investment-am-i-eligible
  static readonly authQuestionsGet: string = 'unauthenticated' + wso2Version + '/securityQuestions';
  static readonly preEligiMemberGet: string = 'unauthenticated' + wso2Version + '/pre-eligibility/retrieve/~~';
  static readonly preEligiMemberPost: string = 'unauthenticated' + wso2Version + '/pre-eligibility/client';
  static readonly addPreEligibilityMember: string = 'unauthenticated' + wso2Version + '/pre-eligibility/client-member';
  static readonly preEligibiltyExpense: string = 'unauthenticated' + wso2Version + '/pre-eligibility/expense';
  static readonly getResults: string = 'unauthenticated' + wso2Version + '/pre-eligibility/determine-pre-elig/~~';
  public static readonly createFSPUserPost = 'unauthenticated' + wso2Version + '/createUser';
  public static readonly deleteApp = 'public' + wso2Version + '/consumer/update/~~';
  public static readonly getCountyByZipCode = 'public' + wso2Version + '/consumer/getCountyByZipCode/~~';
  public static readonly contactSupport = 'public' + wso2Version + '/customer-support';
  public static readonly contactSupportUpload = 'public' + wso2Version + '/upload-doc/~~/~~';
  public static readonly consumerDetailsFromHeader = 'public' + wso2Version + '/consumer/details/~~';

  // Help Doc
  public static readonly getPublicDoc: string = 'unauthenticated' + wso2Version + '/document/cp-public-docs/view-all/~~';
  public static readonly uploadDoc = 'unauthenticated' + wso2Version + '/document/cp-public-docs/upload';
  public static readonly downloadDoc = 'unauthenticated' + wso2Version + '/document/cp-public-docs/view/~~/~~';

  // OHEP
  public static readonly ohepStatus = '' + wso2Version + 'OHEPStatus/~~/~~/~~'; //{lastName}/{lastFourSSN}/{birthdate}
  public static readonly mydhrToken = '' + wso2Version + 'ohep-user-authentication/~~';
  public static readonly ohepReportDownload = '' + wso2Version + 'ohep-ssrs-download';
  public static readonly ohepSubmtApps = '' + wso2Version + 'ohep-submitted-applications';
  public static readonly getOhepForms = 'public' + wso2Version + '/consumer/getOhepForms/~~';

  public static readonly getLaunchCounty = 'public' + wso2Version + '/consumer/county';
  public static readonly inProgressMyDhrApps = '' + wso2Version + 'fia-inprogress-applications';

  //ridp
  public static readonly getRidpQuestionarre = 'consumerportal' + wso2Version + '/ridp/questionnaire';
  public static readonly saveRidpVerification = 'consumerportal' + wso2Version + '/ridp/verifyAnswers';

  //account details
  public static readonly getAccountInfo = 'public' + wso2Version + '/consumer/user-details/~~';
  public static readonly saveAccountInfo = 'public' + wso2Version + '/consumer/user-details/~~';

  //Benefit Review

  public static readonly getBenefitReviewApps = 'public' + wso2Version + '/consumer/benefit-review-report/geteligibilecases/~~';
  public static readonly getBenefitReviewData = 'public' + wso2Version + '/consumer/benefit-review-report/~~';
  public static readonly beneftReviewImportData = 'public' + wso2Version + '/consumer/benefit-review-report/importwpdata/~~';
  public static readonly beneftReviewSaveData = 'public' + wso2Version + '/consumer/benefit-review-report/save/~~';
  public static readonly submitBeneftReviewData = 'public' + wso2Version + '/consumer/benefit-review-report/submit/~~/~~';
  // public static readonly saveMemMBR = 'public' + wso2Version + '/consumer/submitBenefitReview/member/~~';
  public static readonly saveMemMBR = 'public' + wso2Version + '/consumer/benefit-review-report/member/save/~~';
  public static readonly mbrDownload = 'public' + wso2Version + '/consumer/benefit-review-report/download/~~';

  public static readonly refreshToken = `unauthenticated/refreshToken/~~`;
  
  fileUploadtProgress$;
  fileUploadProgressObserver;
  private loaderStatus = new Subject<any>();
  personalId = '7c438d573165cdd1552231ed3bdd33'; // please add your personal id

  constructor(@Inject(DOCUMENT) private document: Document,
              private storage: StorageService,
              private httpClient: HttpClient,
              private alertService: AlertService,
              private loader: LoaderService,
              private emitter: EventEmitterService) {
  //  this.fileUploadtProgress$ = Observable.create(observer => {
   //   this.fileUploadProgressObserver = observer
   // }).share();
  }

  private emitTimer() {
    this.emitter.broadcast('timer', null);
  }

  getLoaderStatus(): Observable<any> {
    return this.loaderStatus.asObservable();
  }

  public makeFileRequest(url: string, params: any): Observable<Response> {
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (const key in params) {
        if (key) {
          formData.append(key, params[key]);
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.loader.hide();
            this.emitTimer();
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            this.loader.hide();
            this.emitTimer();
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        this.fileUploadProgressObserver.next(Math.round(event.loaded / event.total * 100));
        this.emitTimer();
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Accept-Language', 'en-US');

      if (environment.addForgerockHeaders) {
        xhr.setRequestHeader('uid', this.storage.getUserId());
        xhr.setRequestHeader('uniqueid', this.storage.getUniqueId());
        xhr.setRequestHeader('access-token', this.storage.getAccessToken());
        xhr.setRequestHeader('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,');
      }

      xhr.send(formData);
      this.emitTimer();
    });
  }

  fetchEliData(url: string, params: any, requestType: number, body: any, queryParams?: string[]): Observable<any> {
    this.loader.show();
    let result;
    let finalUrl: string = ApiManager[url];

    if (finalUrl && finalUrl.includes('~~') && queryParams != undefined && queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        finalUrl = finalUrl.replace('~~', queryParams[i]);
      }
    }
    // if (ApiManager[url] == ApiManager.authQuestionsGet) {
    //   const bearerToken = 'Bearer b00eb6cd-0b46-330e-8de3-478273c17db0';
    //   let customHeaders = new HttpHeaders();
    //   customHeaders.append('Content-Type', 'application/json');
    //   customHeaders.append('Authorization', bearerToken);
    //   result = this.http.get(finalUrl, {headers: customHeaders});
    // } else {
    if (requestType === ApiManager.GET) {
      result = this.httpClient.get(environment.baseUrlDev + finalUrl);
    } else if (requestType === ApiManager.POST) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body);
    } 
    else if (requestType === ApiManager.DOCGET) {
      const customHeaders = {
        'Content-Type': 'application/json',
        'role': 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL',
        // 'uniqueId': 'mydhr30bf2179ad6f4d50837443ab3',
        // 'uid': 'systemUser'
        'uniqueId': this.storage.getUniqueId(),
        'access-token': this.storage.getAccessToken(),
        'uid': this.storage.getUserId()
      }
      result = this.httpClient.get(environment.baseUrlDev + finalUrl, {headers: customHeaders});
    } 
    else if (requestType === ApiManager.DOWNLOAD) {
      const customHeaders = {
        'Content-Type': 'application/json',
        'role': 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL',
        // 'uniqueId':  'mydhr30bf2179ad6f4d50837443ab3',
        // 'uid': 'systemUser'
        'uniqueId':  this.storage.getUniqueId(),
        'access-token': this.storage.getAccessToken(),
        'uid': this.storage.getUserId()
      }
      result = this.httpClient.get(environment.baseUrlDev + finalUrl, {
        responseType: 'arraybuffer',
        headers: customHeaders
      }).subscribe(response => this.downLoadFile(response, "application/pdf"));
    }

    this.emitTimer();
    this.loader.hide();
    return result;
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], {type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  fetchData(url: string, params: any, requestType: number, body: any, successMethod?: Function, failureMethod?: Function, queryParams?: string[], file?: File, silentRefresh = false) {
    if(!silentRefresh){
      this.loader.show();
    }
    let result ;

    let finalUrl: string = (url.includes('retrieve-help-text')) ? url : ApiManager[url];
    // this.slimLoadingBarService.start(() => {
    //     console.log('Loading complete');
    // });
    if (finalUrl.includes('~~') && queryParams != undefined && queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        finalUrl = finalUrl.replace('~~', queryParams[i]);
      }
    }
    if (requestType === ApiManager.GET) {
      result = this.httpClient.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptions(params));
    } else if (requestType === ApiManager.POST && finalUrl.includes('refreshToken')) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, {...this.getOptionsWithRequestOptions(params, true), observe: 'response'}); 
    } 
    else if (requestType === ApiManager.POST && url != 'sdrUrl' && url != 'icSave' && url != 'saveIntake') {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params, true));
    } else if (requestType === ApiManager.POST && (url == 'icSave' || url == 'saveIntake')) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params, false, false, true));
    } else if (requestType === ApiManager.POST && url == 'sdrUrl') {
      result = this.makeSdrRequest(environment.sdrUrl + finalUrl, body);
    } else if (requestType === ApiManager.PUT) {
      result = this.httpClient.put(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params));
    } else if (requestType === ApiManager.JSONFILE) {
      result = this.httpClient.get(ApiManager.baseJsonUrlDev + finalUrl)
    } else if (requestType === ApiManager.FILEUPLOAD) {
      result = this.makeFileRequest(environment.baseUrlDev + finalUrl, params);
    } else if (requestType === ApiManager.FILEDOWNLOAD) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params, true, true));
    } else if (requestType === ApiManager.FILEDOWNLOADGET) {
      result = this.httpClient.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptions(params, false, true));
    } else if (requestType === ApiManager.FILEBULKDOWNLOAD) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params, true, true));
    } else if (requestType === ApiManager.CUSTOMFILEDOWNLOAD) {
      result = this.httpClient.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptions(params, false, true));
    } else if (requestType === ApiManager.FILEDELETE) {
      result = this.httpClient.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptions(params, true));
    } else if (requestType === ApiManager.SUBMIT) {
      result = this.httpClient.post(ApiManager.caseSubmit, body, this.getOptionsWithRequestOptions(params, true));
    }
    return result.map((res: Response) => {
      console.log('API Call Success');
      // this.slimLoadingBarService.complete();
      
      if(!silentRefresh){
        this.loader.hide();
        this.emitTimer();
      }
      if (successMethod) {
        successMethod();
      }
      if (res.url && res.url.indexOf('cp_9701_download') == -1 && res.json) {
        return res.json();
      } else {
        return res;
      }
    }).catch((error: Response | any) => {
      if(!silentRefresh){
        this.loader.hide();
        this.emitTimer();
        this.loaderStatus.next(false);
      }
      console.log('API call failure: Catch');
      // const errorMessage = JSON.parse(error._body);
      const errorMessage = error.error || JSON.parse(error._body);
      if(errorMessage.userMessages && errorMessage.userMessages.length > 0){      
        this.alertService.errMesg.next(errorMessage);
        this.alertService.error(errorMessage.userMessages[0].message);
      }
      // this.alertService.error('Error : Unexpected error.');
      if(!silentRefresh){
        this.document.body.scrollTop = 0;
        window.scrollTo(0, 0);
      }
      // this.slimLoadingBarService.stop();
      let errMsg = '';
      let errorStatus; // status code for response
      let errorShortMessage; // short message of exception thrown
      let errorJson; // JSON of exception thrown
      if (error instanceof Response) {
        const errorBody = error.json() || '';
        errorJson = errorBody.error || JSON.stringify(errorBody);
        if (!errorJson && error['_body']) {
          errorJson = error['_body'];
        }
        errorStatus = error.status;
        errorShortMessage = error.statusText || '';
        errMsg = `${errorStatus} - ${errorShortMessage} ${errorJson}`;
      } else {
        if(error.error){
          const errorBody = error || '';
          errorJson = JSON.stringify(errorBody.error) || JSON.stringify(errorBody);
          if (!errorJson && error['_body']) {
            errorJson = JSON.stringify(error['_body']);

          }
          errorStatus = error.status;
          errorShortMessage = error.statusText || '';
          errMsg = `${errorStatus} - ${errorShortMessage} ${errorJson}`;
        }else{
          errMsg = error.message ? error.message : error.toString();
        }
        
      }
      console.error('error ' + errMsg);
      if (failureMethod !== undefined) {
        failureMethod();
      }
      return Observable.throw(errMsg);
    });
  }

  getOptionsWithRequestOptions(params: any, addHeader: boolean = false, isResponseBlob: boolean = false, addHeaderPlainText?: boolean) {
    let customHeaders = new HttpHeaders();

    if (addHeader) {
      customHeaders = customHeaders.append('Content-Type', 'application/json');
    }
    if (addHeaderPlainText) {
      customHeaders = customHeaders.append('Content-Type', 'text/plain');
    }
    if (environment.addForgerockHeaders) {
       //customHeaders.append('uid', 'systemUser');
       //customHeaders.append('uniqueId', 'mydhr30bf2179ad6f4d50837443ab3');
      customHeaders = customHeaders.append('uid', this.storage.getUserId());
      customHeaders = customHeaders.append('uniqueId', this.storage.getUniqueId());
       customHeaders.append('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=CP_NH_STAFF');
       customHeaders = customHeaders.append('access-token', this.storage.getAccessToken());
    //   customHeaders = customHeaders.append('access-token', 'eyJ0eXAiOiJKV1QiLCJ6aXAiOiJOT05FIiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiJ0ZXN0MDAyMEBnZXRuYWRhLmNvbSIsImN0cyI6Ik9BVVRIMl9TVEFURUxFU1NfR1JBTlQiLCJhdXRoX2xldmVsIjowLCJhdWRpdFRyYWNraW5nSWQiOiI3M2I4MmY0Ni04MWQxLTQ5ZmYtODczYS0yOTAyY2Q0ODUzM2ItMjAxMzU3NzIiLCJpc3MiOiJodHRwczovL2FjY2Vzcy5teW1kdGhpbmsubWFyeWxhbmQuZ292OjQ0My9tZHRzc28vb2F1dGgyL3JlYWxtcy9yb290L3JlYWxtcy9teW1kdGhpbmsiLCJ0b2tlbk5hbWUiOiJhY2Nlc3NfdG9rZW4iLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwiYXV0aEdyYW50SWQiOiJCTzNySGN5VWdiZlhOV0ZEWVpKTWNsbWhsb2MiLCJhdWQiOiJNWU1EVEVFQ2xpZW50IiwibmJmIjoxNjgxNzgxNzUxLCJncmFudF90eXBlIjoicmVmcmVzaF90b2tlbiIsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiXSwiYXV0aF90aW1lIjoxNjgxNzY1MTkzLCJyZWFsbSI6Ii9teW1kdGhpbmsiLCJleHAiOjE2ODE3ODI2NTEsImlhdCI6MTY4MTc4MTc1MSwiZXhwaXJlc19pbiI6OTAwLCJqdGkiOiJXOHVMN0lnbUliUXd2eUMxNDBrZEVuSU5vdlUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0MDAyMEBnZXRuYWRhLmNvbSIsInJvbGUiOlsiQ1BfUFVCTElDIl19.P6M-Dwd90ihYka6hRZiAm7L0cMez9KyPbOIkTuR37GtnIR3uhbx80NWo68rZQJMTDZTq4-hR8NwmNlLSLZXF6g');
      //customHeaders = customHeaders.append('role', this.storage.getHeaderRoles());

    }
    customHeaders = customHeaders.append('Accept-Language', 'en-US');
    // if (isResponseBlob) {
    //     customHeaders.append('responseType','arraybuffer');
    // }

    const customParams: URLSearchParams = new URLSearchParams();
    for (const key in params) {
      if (key) {
        customParams[key] = params[key];
      }
    }
    let requestOptions = {headers: customHeaders, search: customParams };
    if (isResponseBlob) {
      requestOptions['responseType'] = 'blob';
    }
    // this.emitTimer();
    return requestOptions;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file);
      let headers = new HttpHeaders();
      /** No need to include Content-Type in Angular 4 */
      // headers.append('Content-Type', 'multipart/form-data');
      // headers.append('Accept', 'application/json');
      headers.append('Accept-Language', 'en-US');
      let options = {headers: headers};
      this.httpClient.post(environment.baseUrlDev + ApiManager.fileUpload, formData, options)
        .catch(error => Observable.throw(error))
        .subscribe(
          data => {
            console.log('success');
            this.emitTimer();
          },
          error => console.log(error)
        )
    }
  }

  ApiWithCaseId(
    url: string,
    params: any,
    requestType: number,
    caseId: string,
    body: any,
    option?: string,
    successMethod?: Function,
    failureMethod?: Function
  ) {
    // let result: Observable<Response>;
    let result;
    // this.slimLoadingBarService.start(() => {
    //     console.log('Loading complete');
    // });
    if (requestType === ApiManager.GET) {
      result = this.httpClient.get(
        environment.baseUrlDev + ApiManager[url] + '/' + caseId + ApiManager[option], this.getOptionsWithRequestOptions(params)
      );
    } else if (requestType === ApiManager.POST) {
      result = this.httpClient.post(
        environment.baseUrlDev + ApiManager[url] + '/' + caseId + ApiManager[option], body, this.getOptionsWithRequestOptions(params, true)
      );
    } else if (requestType === ApiManager.PUT) {
      result = this.httpClient.put(
        environment.baseUrlDev + ApiManager[url] + '/' + caseId + ApiManager[option], body, this.getOptionsWithRequestOptions(params)
      );
    } else if (requestType === ApiManager.JSONFILE) {
      result = this.httpClient.get(
        ApiManager.baseJsonUrlDev + ApiManager[url] + '/' + caseId + ApiManager[option]
      )
    }
    return result.map((res: Response) => {
      this.emitTimer();
      console.log('API Call Success');
      // this.slimLoadingBarService.complete();
      if (successMethod !== undefined) {
        successMethod();
      }
      if (res.json)
        return res.json();
      else return res;
    }).catch((error: Response | any) => {
      this.emitTimer();
      console.log('API call failure: Catch');
      // this.slimLoadingBarService.stop();
      let errMsg = '';
      if (error instanceof Response) {
        const body = error.json() || '';
        const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
      console.error(errMsg);
      if (failureMethod !== undefined) {
        failureMethod();
      }
      return Observable.throw(errMsg);
    });
  }

  public makeSdrRequest(url: string, params: any): Observable<Response> {
    console.log('params sdr', params);
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (const key in params) {
        if (key && params[key] && params[key] != '' && params[key] != undefined) {
          formData.append(key, params[key]);
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };


      xhr.open('POST', url, true);
      xhr.setRequestHeader('Accept-Language', 'en-US');
      if (environment.addForgerockHeaders) {
        console.log('add sdr heafers');
        // xhr.setRequestHeader('uid', 'systemUser');
        // xhr.setRequestHeader('uniqueid', 'mydhr30bf2179ad6f4d50837443ab3');
        xhr.setRequestHeader('uid', this.storage.getUserId());
        xhr.setRequestHeader('uniqueid', this.storage.getUniqueId());
        xhr.setRequestHeader('access-token', this.storage.getAccessToken());
        xhr.setRequestHeader('role', 'cn=EE_SUPER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SCREE,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_FINAL,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_INTER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=Internal Users,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SUPUSR,ou=groups,dc=openam,dc=forgerock,dc=org');
      }
      xhr.send(formData);
    });
  }

}