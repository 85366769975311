import {Routes, RouterModule} from '@angular/router';
import {environment} from "../environments/environment";

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'dashboard/consumer-dashboard/myBenefits/myActiveCases',
    // redirectTo: 'dashboard/consumer-dashboard/dashboard?type=AP',
    redirectTo: (environment.enableAutoRedirectionToMyDHR) ? 'dashboard/landing-page' : 'dashboard/consumer-dashboard/myBenefits/myActiveCases',
    // redirectTo: (environment.envName != 'prod') ? 'dashboard/decision' : ((environment.enableAutoRedirectionToMyDHR) ? 'dashboard/landing-page' : 'dashboard/consumer-dashboard/myBenefits/myActiveCases'),
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard.module#DashboardModule'
  },
  {
    path: '**',
    // redirectTo: 'dashboard/consumer-dashboard/myBenefits/myActiveCases',
    // redirectTo: 'dashboard/consumer-dashboard/dashboard?type=AP',
    redirectTo: (environment.enableAutoRedirectionToMyDHR) ? 'dashboard/landing-page' : 'dashboard/consumer-dashboard/myBenefits/myActiveCases',
    // redirectTo: (environment.envName != 'prod') ? 'dashboard/decision' : ((environment.enableAutoRedirectionToMyDHR) ? 'dashboard/landing-page' : 'dashboard/consumer-dashboard/myBenefits/myActiveCases')
  }
];

export const routing = RouterModule.forRoot(routes, {
  useHash: true
});
