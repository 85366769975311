import { BaseBean } from './base-bean.class';
import { Program } from './program.class';
import { TrialBudgetClient } from './trial-budget-client.class';
import { ScrVehicularAsset } from './scr-vehicular-asset.class';
import { UnearnedIncome } from './unearned-income.class';
import { Asset } from './asset.class';
import { ScrAmountType } from "./scr-amount-type.class";

export class TrialBudgetRequest extends BaseBean{
    constructor(
        public livingArrangement: string = '',
        public householdMemberSize: number = undefined,
        public program: Program = new Program(),
        public irsDependents: string = '',
        public clients: TrialBudgetClient[] = [],
        public deemarEarnedIncome: number = undefined, // number
        public deemarEarnedIncomeExpense: number = undefined, // number
        public unearnedIncomes: Set<ScrAmountType> = null,
        public spouseUnearnedIncome: number = undefined, // number
        public deemarUnearnedIncome: number = undefined, // number
        public rentMortgageExpense: number = undefined, // number
        public utilityExpense: number = undefined, // number
        public medicalExpense: number = undefined, // number
        public ltcDeductionExpense: number = undefined, // number
        public utilityMethodType: string = '',
        public assets: Set<ScrAmountType> = null,
        public vehicularAssets: ScrVehicularAsset[] = [],
    ) {
        super();
    }
}