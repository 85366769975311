import { ChangeReporting } from './change-reporting.class';
import { ProgramGroup } from './program-group.class';
import { EmergencyAssistance } from './emergency-assistance.class';
import { BaseBean } from './base-bean.class';
import { HouseholdMember } from './household-member.class';
import { ContactInformation } from './contact-information.class';
import { AdditionalInformation } from './additional-information.class';
import { Program } from './program.class';
import { HouseholdCircumstance } from './household-circumstance.class';
import { AuthorizedRepresentative } from './authorized-representative.class';
import { Eligibility } from './eligibility.class';
import { Signature } from './signature.class';
import { ScheduleInterview } from './schedule-interview.class';
import { ApplicationProcessSteps } from './application-process-steps.class';
import { ClientVerification } from './client-verification.class';
import { AbsentParent } from './absent-parent.class';
import { BusinessErrors } from './business-errors.class';
import { Narrative } from './narrative.class';
import { InterviewSummary } from './interview-summary.class';
import { Redetermination } from './redetermination.class';
import { ProgramsSelected } from './programs-selected.class';
// import { IndividualTaxFiling } from './individual-tax-filing-status.class';

export class BenefitsCase extends BaseBean {
  constructor(
    public caseId: string = '',
    public applicationDate: Date = null,
    public householdMembers: HouseholdMember[] = [],
    public narratives: Narrative[] = [],
    public contactInformation: ContactInformation = new ContactInformation(),
    public additionalInformation: AdditionalInformation = new AdditionalInformation(),
    public requestedPrograms: Program[] = [],
    //public householdCircumstance: HouseholdCircumstance = new HouseholdCircumstance(),
    public authorizedRepresentatives: AuthorizedRepresentative[] = [],
    public emergencyAssistances: EmergencyAssistance[] = [],
    public eligibility: Eligibility = new Eligibility(),
    public approvedEligibility: Eligibility = new Eligibility(),
    public signature: Signature = new Signature(),
    public interviewSignature: Signature = new Signature(),
    public scheduleInterview: ScheduleInterview = new ScheduleInterview(),
    public reviewUpdateNarrativeYN: string = '',
    public requestBackgroundVerificationYN: string = '',
    public caseStatusCode: string = '',
    public steps: ApplicationProcessSteps = new ApplicationProcessSteps(),
    public absentParents: AbsentParent[] = [],
    public errors: BusinessErrors[] = [],
    public programGroup: ProgramGroup = new ProgramGroup(),
    public interviewSummarys: InterviewSummary[] = [],
    public caseMode: string = '',
    public changeReporting: ChangeReporting = new ChangeReporting(),
    public redetermination: Redetermination = new Redetermination(),
    public determinedYN: string = '',
    public selectedPrograms: ProgramsSelected[] = [],
    // public individualTaxFilingStatus: IndividualTaxFiling[] = [],
    public ccid?
  ) {
    super();
  }
}
