import { DocumentDetails } from './document-details.class';
import { Verification } from './verification.class';

export class ClientVerification extends Verification {
  constructor(
    public verificationDocumentCd: string = '',
    public verificationRequestedYN: string = '',
    public docNames: Map<string, string> = new Map<string, string>(),
    public documentDetails: DocumentDetails[] = [],
    public verificationType: string = '',
    public verifiedYN: string = '',
    public verificationSourceCode: string = 'NO',
    public dueDate: Date = null,
    public automatedVerificationYN: string = '',
  ) { super(); }
}
