import { Injectable } from "@angular/core";
import { ApiManager } from "./api-manager.service";
import { Dictionary } from "underscore";
import { LookupClass } from "../model/api/lookup.class";
import { Observable } from "rxjs/Observable";
import * as _ from 'underscore';

@Injectable()
export class LookupManagerService {
  public static readonly english = 'en_US';
  public static readonly spanish = 'es_US';
  public static readonly lookupTypes = [];
  public allLookUps: any[] = [];
  private resultLookUps: any[] = [];

  constructor(private apiManager: ApiManager) {
    LookupManagerService.lookupTypes['hohInfo'] = ['RelationshipType', 'Gender', 'Race', 'Ethnicity', 'Suffix', 'MartialStatus', 'DisabilityType', 'State', 'EducationalLevel', 'HighestGrade', 'StudentStatusCode', 'GoodStandingSchoolAttendance'];
    LookupManagerService.lookupTypes['contactInfo'] = ['PrimaryLanguage', 'HearingImpaired', 'State', 'County', 'CommunicationMode', 'PhoneType', 'HomeTypes', 'MyDHRLivingArrangements'];
    LookupManagerService.lookupTypes['calender'] = ['County'];
    // LookupManagerService.lookupTypes['authRep'] = ['AuthRepRelation', 'AuthrepProgramType'];
    LookupManagerService.lookupTypes['authRep'] = ['AuthRepTypeCd', 'State', 'AuthRepAccessType'];
    LookupManagerService.lookupTypes['tax'] = ['TaxFilingStatus'];
    LookupManagerService.lookupTypes['simplifiedReportsLandingPage'] = ['Citizenship', 'MartialStatus'];
    LookupManagerService.lookupTypes['living'] = ['LivingArrangement', 'ParentalStatus', 'FosterCarAdoptType'];
    LookupManagerService.lookupTypes['citizenship-immig'] = ['Citizenship', 'ImmigrationDocumentType', 'CountryOfOrigin', 'USCISStatus', 'RefugeeResettlementAgency'];
    LookupManagerService.lookupTypes['veteran'] = ['VeteransStatus', 'RelationshipType'];
    LookupManagerService.lookupTypes['relationship'] = ['IndividualRelationship'];
    LookupManagerService.lookupTypes['mediInsurance'] = ['Suffix', 'RelationshipType', 'State'];
    LookupManagerService.lookupTypes['memberInfo'] = ['IndividualRelationship', 'Gender', 'Race', 'Ethnicity', 'MartialStatus', 'State', 'StudentStatusCode', 'EducationalLevel', 'HighestGrade', 'Suffix', 'goodstandingschoolattendance'];
    LookupManagerService.lookupTypes['mediExpenses'] = ['ServiceProvided', 'MedicalExpenseType', 'Frequency'];
    LookupManagerService.lookupTypes['courtOrder'] = ['Frequency', 'ChildSpousalExpense'];
    LookupManagerService.lookupTypes['shelUtility'] = ['ShelterExpense', 'UtilityExpense'];
    LookupManagerService.lookupTypes['careExpenses'] = ['FrequencyOfPayment', 'PhoneType', 'State', 'ChildSupportExpenseVerification', 'DayCareExpense', 'DayCareExpenseVerification', 'RelationshipType'];
    LookupManagerService.lookupTypes['earned'] = ['EarningType', 'State', 'PhoneType', 'Frequency'];
    LookupManagerService.lookupTypes['unearned'] = ['UnearnedIncomeType', 'BenefitsPensions', 'CompensationPayments', 'SupportAlimony', 'ContributionsInKind', 'VendorPayments', 'Loans', 'EducationalAssistance', 'ReturnOnInvestments', 'FosterCareAdoption', 'NeedsBasedAssistance', 'PublicAssistance', 'Relocation', 'ShelterRelated', 'ProjectIndependence', 'LumpSum', 'VolunteerRelated', 'VariableInfrequent', 'OtherUI', 'CompensationPayments', 'SocialSecurity', 'Frequency', 'AppliedUnearnedIcomeStatus', 'AppliedUnearnedIncomeType', 'appliedunearnedassettype'];
    LookupManagerService.lookupTypes['assets'] = ['AddAssetsAssetTypeParent', 'Insurance', 'LiquidAssetType', 'VehicleType', 'PropertyTypeOther', 'AddAssetsAssetTypeRealEstateProperties', 'TransferOfAssetType', 'State', 'ReasonForTransfer', 'AUTransfereeRelationship', 'UndueHardshipReason', 'RealPropertyUse', 'EAgoodcause', 'EAasset'];
    LookupManagerService.lookupTypes['income'] = ['EAincome'];
    LookupManagerService.lookupTypes['expenses'] = ['EAexpense'];
    LookupManagerService.lookupTypes['utilityElectricInfo'] = ['County'];
    LookupManagerService.lookupTypes['absentParent'] = [
      'Suffix',
      'YesNo',
      'Sex',
      'Gender',
      'Race',
      'CountryOfOrigin',
      'State',
      'MartialStatus',
      'HairColor',
      'EyeColor',
      'VeteranStatus',
      'VeteranBranch',
      'RecepientDropDown',
      'IVDCooperationIndicator',
      'FrequencyOfPayment',
      'RelationOfAbsentParentToChild',
      'Deprivation',
      'GoodCauseReasons',
      'RelationshipTo',
      'GoodCauseStatus',
      'PhoneType',
      'UnearnedIncomeType',
      'BenefitsPensions',
      'NeedsBasedAssistance',
      'SupportAlimony',
      'ContributionsInKind',
      'VendorPayments',
      'Loans',
      'EducationalAssistance',
      'ReturnOnInvestments',
      'FosterCareAdoption',
      'PublicAssistance',
      'Relocation',
      'ShelterRelated',
      'ProjectIndependence',
      'LumpSum',
      'VolunteerRelated',
      'VariableInfrequent',
      'CompensationPayments',
      'OtherUI',
      'RelationshipType',
      'Ethnicity',
      'VerfSourceYesNo'
    ];
    LookupManagerService.lookupTypes['summary'] = [
      'Suffix',
      'Program',
      'YesNo',
      'PropertyTypeOther',
      'Sex',
      'Gender',
      'Race',
      'MedicalExpenseType',
      'RelationshipTo',
      'State',
      'MartialStatus',
      'HairColor',
      'EyeColor',
      'VeteranStatus',
      'VeteranBranch',
      'RecepientDropDown',
      'IVDCooperationIndicator',
      'RelationOfAbsentParentToChild',
      'Deprivation',
      'GoodCauseReasons',
      'GoodCauseStatus',
      'PhoneType',
      'UnearnedIncomeType',
      'BenefitsPensions',
      'NeedsBasedAssistance',
      'SupportAlimony',
      'ContributionsInKind',
      'VendorPayments',
      'Loans',
      'EducationalAssistance',
      'ReturnOnInvestments',
      'FosterCareAdoption',
      'PublicAssistance',
      'Relocation',
      'ShelterRelated',
      'ProjectIndependence',
      'LumpSum',
      'VolunteerRelated',
      'VariableInfrequent',
      'CompensationPayments',
      'OtherUI',
      'IndividualRelationship',
      'FrequencyOfPayment',
      'Race',
      'Ethnicity',
      'Citizenship',
      'VerfSourceYesNo',
      'UtilityExpense',
      'ShelterExpense',
      'EarnedIncomeType',
      'UnearnedIncomeType',
      'UnearnedIncomeType',
      'AddAssetsAssetTypeParent',
      'LiquidAssetType',
      'AppliedUnearnedIcomeStatus',
      'AppliedUnearnedIncomeType',
      'appliedunearnedassettype',
      'DayCareExpense',
      'ServiceProvided',
      'PotentialAssetType',
      'AuthRepRelation',
      'AuthRepTypeCd',
      'AuthRepAccessType',
      'LivingArrangement',
      'TaxFilingStatus'
    ]
  }

  private extractData(res) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  getLookUpValues(code: string[], lang: string): Promise<any> {
    let notCached: LookupClass[] = [];
    this.resultLookUps = [];
    code.forEach(v => {
      if (!this.allLookUps[v]) {
        let lookup: LookupClass = new LookupClass();
        lookup.lookupTypeCd = v;
        lookup.localeCd = lang;
        notCached.push(lookup);
      } else {
        this.resultLookUps[v] = this.allLookUps[v];
      }
    });
    let obs = Observable.create(observer => {
      if (notCached.length > 0) {
        this.callDatabaseForLookups(notCached).then(res => {
          let resLook: Dictionary<LookupClass[]>[] = [];
          resLook = res;
          _.forEach(res, (val, key) => {
            let lookVal: any;
            this.resultLookUps[key] = val;
            this.allLookUps[key] = val;
          });
          observer.next(this.resultLookUps);
          observer.complete();
        }).catch(err => {
          console.log(err);
        });
      } else {
        observer.next(this.resultLookUps);
        observer.complete();
      }
    });
    return obs.toPromise();
  }

  callDatabaseForLookups(notCached: LookupClass[]) {
    return this.apiManager.fetchData('lookupSearchPost', undefined, ApiManager.POST, notCached).toPromise().then(this.extractData).catch(this.handleError);
  }

  getLookupDesc(lookupType: string, code: string): string {
    let retValue;
    let look: LookupClass[] = this.allLookUps[lookupType];
    _.forEach(look, v => {
      if (v.lkpDesc == code) {
        retValue = v.lookupLongVal;
      }
    });
    return retValue ? retValue : '';
  }

  getLookupDescFromLongVal(lookupType: string, code: string): string {
    let retValue;
    let look: LookupClass[] = this.allLookUps[lookupType];
    _.forEach(look, v => {
      if (v.lookupLongVal == code) {
        retValue = v.lkpDesc;
      }
    });
    return retValue ? retValue : '';
  }

  getLookupYNDesc(lookupType: string, code: string): string {
    let retValue;
    let look: LookupClass[] = this.allLookUps[lookupType];
    _.forEach(look, v => {
      if (v.lkpDesc == code) {
        retValue = v.lookupLongVal;
      }
    });
    return retValue ? retValue : 'No';
  }
}
