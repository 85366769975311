import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { AppUtilService } from './app.util.service';

const TOKEN_HEADER_KEY = 'access-token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private storage: StorageService, 
        private authService: AuthService,
        private utilService: AppUtilService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req;
        const token =   this.storage.getAccessToken();
        if (token != null) {
            authReq = this.addTokenHeader(req, token);
        }

        return <any>next.handle(authReq).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                return this.handle401Error(authReq, next);
            }
            // if (error instanceof HttpErrorResponse && error.status === 403) {
            //     this.utilService.logout();
            // }
            return throwError(error);
        }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {        
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            const {refreshToken} = this.storage.getUserInfoPrefetch().user;
            if (refreshToken)
                return this.authService.refreshToken(refreshToken).pipe(
                    switchMap((res: any) => {
                        let accessToken;
                        if(res.headers){
                            accessToken = res.headers.get('access-token')
                            let idToken = res.headers.get('id-token')
                            let refToken = res.headers.get('refresh-token')

                            let userInfo = this.storage.getUserInfoPrefetch();
                            if(userInfo && userInfo.user){
                                userInfo.user.accessToken = accessToken;
                                userInfo.user.idToken = idToken;
                                userInfo.user.refreshToken = refToken;
    
                                this.storage.setUserInfoPrefetch(userInfo);
                            }
                            if(accessToken){
                                this.storage.setAccessToken(accessToken);
                            }
                            if(idToken){
                                this.storage.setIdToken(idToken);
                            }
                        }
                        
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(refreshToken);
                        return next.handle(this.addTokenHeader(request, accessToken));
                    }),
                    catchError((err) => {
                        this.refreshTokenInProgress = false;
                        this.storage.destroyAll();
                        return throwError(err);
                    })
                );
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        if (!token) {
            return request;
        }
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
    }
}