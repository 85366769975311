import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-screen',
  templateUrl: './homeScreen.component.html',
  styleUrls: ['./homeScreen.component.css']
})
export class HomeScreenComponent implements OnInit {


  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  myDhrSignin() {
    this.router.navigate(['dashboard/consumer-dashboard/dashboard']);
  }

  myDhrRegister() {
    // this.router.navigate(['']);
  }

}
