import { Injectable } from "@angular/core";
import { WizardClass } from "../model/custom/wizard.class";
import { NavigationService } from "./navigation.service";
import * as _ from 'underscore';
import { Router } from "@angular/router";
import { ApiManager } from "./api-manager.service";
import { SessionStorageService } from "ngx-webstorage";
import { StorageService } from "./storage.service";

@Injectable()
export class NavigationWizardService {
  public intakeData: any;
  public intakeDefaultData: any[] = [];
  public static readonly pristine = 'Not Entered';
  public static readonly complete = 'Complete';
  public static readonly error = 'Incomplete';
  public static readonly next = 1;
  public static readonly back = -1;
  public activeWizard: any;
  public currentNavs: WizardClass[] = [];
  public selectedType: string = '';
  public wizardActive: boolean = false;
  public requiredPages: WizardClass[] = [];
  public appInComplete: boolean = false;
  public appType: string = this.session.retrieve('appType');
  public navCounter: number = 0;
  programs;

  constructor(private navigationService: NavigationService,
    private router: Router,
    private apiManager: ApiManager,
    private session: SessionStorageService,
    private storage: StorageService) {
    this.programs = [];
    if (this.storage.getPrograms()) {
      this.storage.getPrograms().map(program => {
        if (program.programSelected) {
          this.programs.push(program.programSelected.programName);
        }
      });
    }
  }

  defaultData() {
    this.intakeDefaultData = [];
    this.navigationService.getNavItems().forEach(v => {
      if (v.isRequired == 'Y' && v.nvgtn_cd != 'inFinancialUnearned' && v.nvgtn_cd != 'inFinancialunearnedApps' && (v.nvgtn_cd != 'inProgramQuestionsFelony' ||
        this.programs.includes('OHEP') == true)
      //   && v.nvgtn_cd != 'inProgramQuestionsUtilityHeatInfo'
      //   && v.nvgtn_cd != 'inProgramQuestionsUtilityElectricInfo'
      // && v.nvgtn_cd != 'inProgramQuestionsUtilityWasteWaterInfo'
      && v.nvgtn_cd != 'inHeadOfHouseholdContactInfo' && v.nvgtn_cd != 'inHeadOfHouseholdCitizenshipImmigration' && v.nvgtn_cd != 'inEmergencyAssets') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: v.screen_defn,
          screenId: v.screen_id,
          status: '',
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: v.tag_txt,
          parentTag: v.prnt_desc,
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      } else if (v.nvgtn_cd == 'inFinancialunearnedApps') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: '/dashboard/consumer-app/intake-app/fin/unearned',
          screenId: v.screen_id,
          status: NavigationWizardService.pristine,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: 'Unearned Income Apps',
          parentTag: 'parentFinancial',
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      } else if (v.nvgtn_cd == 'inFinancialUnearned') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: '/dashboard/consumer-app/intake-app/fin/unearned',
          screenId: v.screen_id,
          status: NavigationWizardService.pristine,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: 'Unearned Income',
          parentTag: 'parentFinancial',
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      }  
      else if (v.nvgtn_cd == 'inProgramQuestionsUtilityHeatInfo'
      || v.nvgtn_cd == 'inProgramQuestionsUtilityElectricInfo'
      || v.nvgtn_cd == 'inProgramQuestionsUtilityWasteWaterInfo') {
      this.intakeDefaultData[v.nvgtn_cd] = {
        screenUrl: v.screen_defn,
        screenId: v.screen_id,
        status: NavigationWizardService.pristine,
        completed: 0,
        total: 0,
        isParent: v.is_parent,
        name: v.tag_txt,
        parentTag: v.prnt_desc,
        sort: v.sort_order_nbr,
        screenTag: v.nvgtn_cd,
        screen_actv_ind: 'Y'
      }
    }
      else if ((v.nvgtn_cd == 'inProgramQuestionsFelony' && this.programs.includes('OHEP') == false) || v.nvgtn_cd == 'inProgramQuestionsUtilityProblems') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: v.screen_defn,
          screenId: v.screen_id,
          status: NavigationWizardService.error,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: v.tag_txt,
          parentTag: v.prnt_desc,
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      } else if (v.nvgtn_cd == 'inHeadOfHouseholdContactInfo') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: v.screen_defn,
          screenId: v.screen_id,
          status: NavigationWizardService.error,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: v.tag_txt,
          parentTag: v.prnt_desc,
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      } else if (v.nvgtn_cd == 'inHeadOfHouseholdCitizenshipImmigration') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: v.screen_defn,
          screenId: v.screen_id,
          status: NavigationWizardService.error,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: v.tag_txt,
          parentTag: v.prnt_desc,
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      } else if (v.nvgtn_cd == 'inEmergencyAssets') {
        this.intakeDefaultData[v.nvgtn_cd] = {
          screenUrl: v.screen_defn,
          screenId: v.screen_id,
          status: NavigationWizardService.error,
          completed: 0,
          total: 0,
          isParent: v.is_parent,
          name: v.tag_txt,
          parentTag: v.prnt_desc,
          sort: v.sort_order_nbr,
          screenTag: v.nvgtn_cd,
          screen_actv_ind: 'Y'
        }
      }
    });
    console.log('Intake Default Data: ', this.intakeDefaultData);
    return this.intakeDefaultData;
  }

  isWizardActive(v?: boolean) {
    if (v == true || v == false) {
      this.session.store('wizardActive', v)
    }
    return this.session.retrieve('wizardActive');
  }

  isAppComplete(v?: boolean) {
    if (v == true || v == false) {
      this.appInComplete = v;
    }
    return this.appInComplete;
  }

  importFilter(v) {
    let dataView: any = {};
    _.each(v, (value: any, index: string) => {
      if (index == 'inhomePage' || index == 'inHeadOfHouseholdHohInfo' || index == 'inMembersMemberInfo') {
        if (!dataView[index]) {
          dataView[index] = [];
        }
        dataView[index] = value;
      } else if (value && value.length > 0) {
        _.forEach(value, (p: any) => {
          if (p.importSelected) {
            if (!dataView[index]) {
              dataView[index] = [];
            }
            dataView[index].push(p);
          }
        })
      }
    });
    return dataView;
  }

  setData(dataView) {
    this.appType = this.session.retrieve('appType');
    this.intakeData = this.defaultData();
    console.log('Data View: ', dataView);
    let memLen = 0;
    dataView['inhomePage'].map(v => {
      if (v.dirtyStatus.toLowerCase() != 'delete') {
        memLen++;
      }
    });
    _.each(dataView, ((value: any, index: string) => {
      let total = 0, completed = 0, invalid: boolean = false;

      if (index == 'inHeadOfHouseholdCitizenshipImmigration' && !this.storage.getStandAloneFSP()) {
        total = memLen;
      } else if (index == 'inMembersrelationship') {
        total = (memLen == 1) ? 0 : memLen;
      }
      if (value && value.length && value.length > 0) {
        _.forEach(value, (v: any) => {
          if (v.dirtyStatus == 'delete') {
            v.status = true;
          }          
          if (total == 0) {
            total++;
          } else if (total >= 0 && (index == 'inFinancialUnearned') || (index == 'inFinancialShelterUtility')
            || (index == 'inFinancialMediExpenses') || (index == 'inFinancialAssets') || (index == 'inFinancialPotentialAssets')
            || (index == 'inFinancialEarned') || (index == 'inFinancialStriker') || (index == 'inFinancialCareExpenses')
            || (index == 'inFinancialunearnedApps') || (index == 'inFinancialTaxInfo') || (index == 'inFinancialCourtOrder')
            || (index == 'inMembersRoomer') || (index == 'inProgramQuestionsUtilityHeatInfo') || (index == 'inProgramQuestionsUtilityElectricInfo')
            || (index == 'inProgramQuestionsUtilityWasteWaterInfo')) {
            total++;
          }
          // else if (total >= 0 && index == 'inFinancialShelterUtility') {
          //   total++;
          // }
          if (v && v.status == true) {
            completed++;
          } else if (index == 'inHeadOfHouseholdHohInfo' && this.appType != 'AP') {
            completed++;
          } else if (v.importSelected === false && this.appType != 'AP' && v.status != false) {
            completed++;
          } else if (index == 'inEmergencyAssets') {
            if (!this.storage.getEAFormValidity()) {
              completed++;
            }
          }
        });
      }
      if (this.intakeData[index]) {
        this.intakeData[index].status = total == 0 ? (((this.intakeData[index].screenTag == 'inProgramQuestionsFelony'
          && !this.storage.getStandAloneFSP() && this.programs.includes('OHEP') == false)
          // || this.intakeData[index].screenTag == 'inProgramQuestionsUtilityHeatInfo'
          // || this.intakeData[index].screenTag == 'inProgramQuestionsUtilityElectricInfo'
          || this.intakeData[index].screenTag == 'inProgramQuestionsUtilityProblems'
          // || this.intakeData[index].screenTag == 'inProgramQuestionsUtilityWasteWaterInfo'
          || this.intakeData[index].screenTag == 'inHeadOfHouseholdContactInfo'
          || (this.intakeData[index].screenTag == 'inHeadOfHouseholdCitizenshipImmigration'
            && !this.storage.getStandAloneFSP()) || this.intakeData[index].screenTag == 'inEmergencyAssets') ?
          NavigationWizardService.error : NavigationWizardService.pristine) :
          ((total > completed) ? NavigationWizardService.error : NavigationWizardService.complete);
        this.intakeData[index].completed = completed;
        this.intakeData[index].total = total;
      }
    }));
    return this.intakeData;
  }

  getParents(data): WizardClass[] {
    let result: WizardClass[] = [];
    _.mapObject(data, (v: WizardClass) => {
      if (v.isParent) {
        result.push(v);
      }
    });
    result = _.sortBy(result, 'sort');
    return result;
  }

  getChildren(data, parent): WizardClass[] {
    let result: WizardClass[] = [];
    _.mapObject(data, (v: WizardClass) => {
      if (v && !v.isParent && v.parentTag == parent) {
        result.push(v);
      }
    });
    result = _.sortBy(result, 'sort');
    if (this.session.retrieve('intakeMembers')) {
      result.map(nav => {
        if (nav.screenUrl == '/dashboard/consumer-app/intake-app/members/relationship') {
          nav.screen_actv_ind = (this.session.retrieve('intakeMembers').length > 1) ? 'Y' : 'N';
        }
      });
    }
    return result;
  }

  manipulateForVW(data, active?: boolean) {
    let result = [];
    let i = [];
    this.requiredPages = [];
    i[NavigationWizardService.pristine] = 0;
    i[NavigationWizardService.error] = 0;
    i[NavigationWizardService.complete] = 0;
    result[NavigationWizardService.pristine] = [];
    result[NavigationWizardService.error] = [];
    result[NavigationWizardService.complete] = [];
    this.getParents(data).forEach(v => {
      this.getChildren(data, v.parentTag).forEach(v => {
        v.sort = i[v.status];
        i[v.status]++;
        if (result[v.status]) {
          result[v.status].push(v);
        } else {
          result[v.status] = [];
          result[v.status].push(v);
        }
      });
    });
    this.activeWizard = result;
    console.log('Comp, InComp, NotEnt', result);
    return result;
  };


  setCurrentNavs(type) {
    this.selectedType = type;
    this.currentNavs = this.activeWizard[type];
    return this.currentNavs;
  }

  getCurrentNavs() {
    return this.currentNavs;
  }

  getCurrentNav() {
    return this.currentNavs.filter(v => {
      return v.screenUrl == this.router.url;
    })[0];
  }

  navigationCounter(val?: boolean) {
    if (val) {
      this.navCounter = 0;
    } else {
      this.navCounter++;
      return this.navCounter;
    }
  }

  navigate(dir, s?) {
    let i = 0, f = -1;
    let c = this.getCurrentNav();
    if (c && dir != 'finish') {
      this.currentNavs.forEach((v, key) => {
        if (v.screenTag == c.screenTag) {
          i = key;
        }
      });
      this.router.navigate([this.currentNavs[i + NavigationWizardService[dir]].screenUrl]).then(() => {
        this.navigationCounter(true);
      }).catch(() => {
        this.router.navigate(['/dashboard/consumer-app/intake-app/wizard/nav'])
      });
    } else {
      if (dir == 'next') {
        for (let j = 0; j < this.currentNavs.length; j++) {
          if (this.currentNavs[j].sort > s) {
            f = j;
            break;
          }
        }
      } else if (dir == 'back') {
        for (let j = this.currentNavs.length - 1; j >= 0; j--) {
          if (this.currentNavs[j].sort < s) {
            f = j;
            break;
          }
        }
      }
      if (f == -1) {
        this.isWizardActive(false);
        this.router.navigate(['/dashboard/consumer-app/intake-app/wizard/nav']).then(() => {
          this.navigationCounter(true);
        });
      } else {
        this.router.navigate([this.currentNavs[f].screenUrl]).then(() => {
          this.navigationCounter(true);
        }).catch(() => {
          this.router.navigate(['/dashboard/consumer-app/intake-app/wizard/nav'])
        });
      }
    }
  }

  consumerControlId() {
    return this.session.retrieve('intakeConsumerControlId');
  }

  resetData(dir?, re?) {
    this.apiManager.fetchData('caseSummary', undefined, ApiManager.GET, undefined, undefined, undefined, [this.consumerControlId()]).toPromise().then(res => {
      // this.manipulateForVW(this.setData(res),true);
      console.log(this.setData(res));
      let c = this.setData(res);
      let s = 0;
      if (re) {
        this.manipulateForVW(c);
        this.setCurrentNavs(NavigationWizardService.error);
      } else {
        let w: WizardClass[] = this.activeWizard[NavigationWizardService.error];
        console.log(w);
        if (w && w.length > 0) {
          w = w.filter(val => {
            if (this.router.url == val.screenUrl) {
              s = val.sort;
            }
            if (c[val.screenTag] && c[val.screenTag].status == NavigationWizardService.error) {
              return val;
            }
          });
          this.activeWizard[NavigationWizardService.error] = w;
          this.currentNavs = w;
        }
        if (dir) {
          this.navigate(dir, s);
        } else {
          this.isWizardActive(false);
          this.router.navigate(['/dashboard/consumer-app/intake-app/wizard/nav']);
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  checkForData() {
    this.apiManager.fetchData('caseSummary', undefined, ApiManager.GET, undefined, undefined, undefined, [this.consumerControlId()]).toPromise().then(res => {
      this.manipulateForVW(this.setData(res));
      if (this.setCurrentNavs(NavigationWizardService.error).length > 0) {
        this.isAppComplete(true);
        this.router.navigate(['/dashboard/consumer-app/intake-app/wizard/nav']);
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
