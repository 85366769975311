import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';

import { Address } from './address.class'
export class SchoolInformation extends BaseBean{
    constructor(
        public recordId: number = undefined,
        public educationLevel: string = '',
        public highestGrade: string = '',
        public schoolName: string = '',
        public schoolNumber: string = '',
        public graduationDate : Date = null,
        public graduationDateVerification: ClientVerification = new ClientVerification(),
        public goodStanding: string = '',
        public goodStandingVerification: ClientVerification = new ClientVerification(),
        public schoolAddress: Address = new Address()
    ) { super();}
}
