import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar/calendar.component';
import {AngularMaterialModule} from "../angular-material/angular-material.module";
import { CalendarPopupComponent } from './calendar-popup/calendar-popup.component';
import {MatDialogConfig} from "@angular/material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [CalendarComponent,CalendarPopupComponent ],
  providers: [MatDialogConfig],
  entryComponents: [CalendarPopupComponent]
})
export class AppointmentCalendarModule { }
