import { Person } from './person.class';
import { PersonName } from './person-name.class';
import { ContactInformation } from './contact-information.class';
import { ClientVerification } from './client-verification.class';
import { ABChild } from './ab-child.class';
import { ABCourtOrder } from './ab-court-order.class';
import { Employer } from './employer.class';
import { OtherIncome } from './other-income.class';

export class AbsentParent extends Person {
  constructor (
    public absentParentId: number = undefined,
    public nameUnknownYN: string = '',
    public approximateAge: string = '',
    public relationship: string = '',

    public birthCity: string = '',
    public birthState: string = '',
    public birthCountry: string = '',

    public maritalStatus: string = '',
    public maritalDate: Date = null,
    public marriageCity: string = undefined,
    public marriageState: string = '',
    public marriageCountry: string = '',

    public height: string = '',
    public weight: string = '',
    public hairColor: string = '',
    public eyeColor: string = '',

    public incarcerationYN: string = '',
    public previousIncarcerationYN: string = '',
    public institution: string = '',
    public incarcerationCity: string = '',
    public incarcerationState: string = '',
    public incarcerationCountry: string = '',

    public unionOrLocal: string = '',

    public healthInsuranceYN: string = '',

    public children: ABChild[] = [],
    public ivdNumber: string = '',
    public ivdCoop: string = '',
    public ivdAssignRightsYN: string = '',
    public goodCauseClaimYN: string = '',
    public goodCauseReason: string = '',
    public goodCauseStatus: string = '',
    public effectiveDate: Date = null,

    public referralDate: Date = null,

    public additionalNames: PersonName[] = [],

    public contactInformation: ContactInformation = new ContactInformation(),

    public fatherName: PersonName = new PersonName(),
    public fatherContactInformation: ContactInformation = new ContactInformation(),

    public motherName: PersonName = new PersonName(),
    public motherMaidenName: PersonName = new PersonName(),
    public motherContactInformation: ContactInformation = new ContactInformation(),

    public drivingLicenseState: string = '',
    public drivingLicenseNumber: string = '',
    public licensePlateNumber: string = '',
    public licensePlateState: string = '',

    public veteranStatus: string = '',
    public veteranId: string = '',

    public veteranServiceBranch: string = '',
    public veteranServiceStartDate: string = '',
    public veteranServiceEndDate: string = '',

    public veteranPayingAllotmentYN: string = '',
    public veteranRecepient: string = '',

    public currentEmployers: Employer[] = [],
    public previousEmployers: Employer[] = [],
    public otherIncome: OtherIncome = new OtherIncome(),

    public payingSupportYN: string = '',
    public payee: string = '',
    public lastPaidDate: Date = null,
    public paymentAmount: number = undefined,

    public courtOrders: ABCourtOrder[] = [],

    public ssnVerification: ClientVerification = new ClientVerification(),
    public dobVerification: ClientVerification = new ClientVerification(),

  ) { super(); }
}
