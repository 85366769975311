export class IntakeBaseBeanClass {
  constructor(
    // public personId: number = 0,
    public individualId: any = '',
    // public caseId: any = '',
    public dirtyStatus: string = '',
    public status: boolean = undefined,
    public importedFromWp: string = '',
    public clearanceStatus: string = '',
    public controlId: number = 0,
    // public additionalInfo: string = '',
    public importSelected: boolean = true,
    public editable: boolean = true,
  ) {

  }
}
