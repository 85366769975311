import { BaseBean } from './base-bean.class';
export class Penalty extends BaseBean{
    constructor(
        public penaltyId: number = undefined,
        public penaltyStartDate:  Date = null,
        public penaltyEndDate: Date = null,
        public programName: string = '',
        public penaltyTypeCd: string = '',
        public violation: string = '',
        public violationDt: Date = null
    ) {
        super();
    }
}