import { PaginationData } from './pagination-data.class';
export class SearchModel<T>{
    constructor(
        public search: T = undefined,
        public results: T[] = [],
        public paginationData: PaginationData = new PaginationData(),
        public range: number = null,
        public systemCd: string = ''
    ) { }
}
