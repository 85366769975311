import { Injectable } from '@angular/core';

@Injectable()
export class DataShareService {

  dataMap: Map<string, any>;
  ClientInfo: any[];

  constructor() {
    this.dataMap = new Map();
    this.addDataToMap('sugPrograms', [
      {
        programName: 'first',
        programType: 'programType1',
        medCoverageGroup: 'medCoverageGroup1',
        availableAuId: 'availableAuId1',
        ppiGroup: 'ppiGroup1',
        selectedYN: 'selectedYN1',
        requestedYN: 'requestedYN1'
      }, {
        programName: 'second',
        programType: 'programType2',
        medCoverageGroup: 'medCoverageGroup2',
        availableAuId: 'availableAuId2',
        ppiGroup: 'ppiGroup2',
        selectedYN: 'selectedYN2',
        requestedYN: 'requestedYN2'
      }
    ])
  }

  addDataToMap(key: string, value: any) {
    if (key) {
      this.dataMap.set(key, value);
    }
  }
  getClientData(key: string, value: any) {
    if (key) {
      this.ClientInfo = this.dataMap.get(key);
    }
    return value;
  }

  getDataFromMap(key: string): any {
    let val: any;
    if (key) {
      val = this.dataMap.get(key);
    }
    // if (key === 'currentOffice' && (this.dataMap.get(key) === undefined || this.dataMap.get(key) === '')) {
    //   return 'Harbor View';
    // }
    return val;
  }
  getDataFromMapAndClearData(key: string): any {
    const val: any = this.getDataFromMap(key);
    this.dataMap.delete(key);
    return val;
  }

  removeDataFromMap(key: string){
    this.dataMap.delete(key);
  }
  private _toggleSidebar() {
    this.addDataToMap('showLeftSidebarMenu', !this.getDataFromMap('showLeftSidebarMenu'));
  }

  hideLeftMenu() {
    console.log('Shared Service: hiding left nav');
    this.addDataToMap('hideHeaderMenus', true);
    this.addDataToMap('hideHeaderBar', true);
  }

  showLeftMenu() {
    console.log('Shared Service: show left nav');
    this.addDataToMap('hideHeaderMenus', false);
    this.addDataToMap('hideHeaderBar', false);
  }
}
