import {AfterViewInit, Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {ConsumerNavClass} from "../../model/api/consumer-nav.class";
import {CustomNavClass} from "../../model/api/custom-nav.class";
import {BehaviorSubject} from "rxjs";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import * as _ from 'underscore';

export class NavNodeClass {
  children: NavNodeClass[];
  item: string;
}

export class NavFlatNodeClass {
  item: any;
  level: number;
  expandable: boolean;
}


@Injectable()
export class BuildCustomNav {
  dataChange = new BehaviorSubject<NavNodeClass[]>([]);
  navItems: ConsumerNavClass[] = [];
  navHead: ConsumerNavClass[] = [];

  get data(): NavNodeClass[] {
    return this.dataChange.value
  }

  constructor() {}

  initialize() {
    console.log(this.buildData());
    const data = this.buildNavTree(this.buildData(), 0);
    console.log(data);
    this.dataChange.next(data);
  }

  buildData() {
    let data: any[] = [];
    const allowedParents = ['parentHOH', 'parentMembers', 'parentFinancial', 'parentEA', 'parentProgramQuestions'];
    this.navHead = _.sortBy(this.navHead, 'sort_order_nbr');
    this.navItems = _.sortBy(this.navItems, 'sort_order_nbr');
    this.navHead.forEach(v => {
      if (allowedParents.includes(v.prnt_desc)) {
        if (!data[v.tag_txt]) {
          data[v.tag_txt] = []
        }
        this.navItems.forEach(c => {
          if (c.prnt_desc == v.prnt_desc && !c.is_parent) {
            data[v.tag_txt].push(c);
          }
        });
      }
    });
    return data;
  }

  buildNavTree(obj: { [key: string]: any }, level: number): NavNodeClass[] {
    return Object.keys(obj).reduce<NavNodeClass[]>((accumulator, key) => {
      const value = obj[key];
      const node = new NavNodeClass();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object' && level == 0) {
          node.children = this.buildNavTree(value, level + 1);
        } else {
          node.item = value;
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  setNavData(items: ConsumerNavClass[], heads?: ConsumerNavClass[]) {
    if (items && items.length > 0) {
      this.navItems = items;
    }
    if (heads && heads.length > 0) {
      this.navHead = heads;
    }
  }
}


@Component({
  selector: 'app-select-custom-nav',
  templateUrl: './select-custom-nav.component.html',
  styleUrls: ['./select-custom-nav.component.css'],
  providers: [BuildCustomNav]
})

export class SelectCustomNavComponent implements OnInit, AfterViewInit {
  @Output() checkListOutput: EventEmitter<any> = new EventEmitter();
  @Input() navItems: CustomNavClass[];
  @Input() selectedNavs: CustomNavClass[];
  @Input() set sendToParent(val: boolean) {
    if (val) {
      this.checkListOutput.emit(this.checklistSelection);
    }
  }

  public navHead: CustomNavClass[] = [];
  public navDict: any[] = [];
  flatNodeMap = new Map<NavFlatNodeClass, NavNodeClass>();
  nestedNodeMap = new Map<NavNodeClass, NavFlatNodeClass>();
  selectedParent: NavFlatNodeClass | null = null;
  newItemName = '';
  treeControl: FlatTreeControl<NavFlatNodeClass>;
  treeFlattener: MatTreeFlattener<NavNodeClass, NavFlatNodeClass>;
  dataSource: MatTreeFlatDataSource<NavNodeClass, NavFlatNodeClass>;
  checklistSelection = new SelectionModel<NavFlatNodeClass>(true);
  public displayPage: boolean = false;

  constructor(private customNavBuilder: BuildCustomNav) {}

  ngOnInit() {
    console.log(this.selectedNavs);
    this.selectedNavs.forEach(v => {
      this.navDict[v.nvgtn_cd] = v;
    });
    this.navHead = this.navItems.filter(v => {
      return v.is_parent;
    });
    this.customNavBuilder.setNavData(this.navItems, this.navHead);
    this.customNavBuilder.initialize();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<NavFlatNodeClass>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.customNavBuilder.dataChange.subscribe(data => {
      this.dataSource.data = data;
      console.log(data);
    });

    this.displayPage = true;
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.navDict[this.treeControl.dataNodes[i].item.nvgtn_cd]) {
        this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
      }
    }
    console.log(this.treeControl);
  }


  getLevel = (node: NavFlatNodeClass) => node.level;
  isExpandable = (node: NavFlatNodeClass) => node.expandable;
  getChildren = (node: NavNodeClass): NavNodeClass[] => node.children;
  hasChild = (_: number, _nodeData: NavFlatNodeClass) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: NavFlatNodeClass) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: NavNodeClass, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new NavFlatNodeClass();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: NavFlatNodeClass): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: NavFlatNodeClass): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: NavFlatNodeClass): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: NavFlatNodeClass): void {
    console.log(node);
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: NavFlatNodeClass): void {
    let parent: NavFlatNodeClass | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: NavFlatNodeClass): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: NavFlatNodeClass): NavFlatNodeClass | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
