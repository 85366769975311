import { BaseBean } from './base-bean.class';
export class ChangeReporting extends BaseBean {

    constructor(
        public changeHouseholdYN: string = '',        
        public changeProgramYN: string = '',       
        public changeCaseYN: string = '',
        public dateOfChange: Date = null
      ) {
        super();
      }

}
