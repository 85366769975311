import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { IntakeService } from "../../../common/intake.service";
import { Address } from "../../../model/api/address.class";
import { LookupManagerService } from "../../../common/lookup-manager.service";
import { Dictionary } from "underscore";
import { LookupClass } from "../../../model/api/lookup.class";

@Component({
  selector: 'app-smarty-street-dialog',
  templateUrl: './smarty-street-dialog.component.html',
  styleUrls: ['./smarty-street-dialog.component.css']
})
export class SmartyStreetDialogComponent implements OnInit {
  public lookUpValues: Dictionary<LookupClass[]>[] = [];
  HAAddress: boolean = false;
  MAAddress: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private intakeService: IntakeService,
    private lookupService: LookupManagerService) { }

  ngOnInit() {
    this.lookupService.getLookUpValues(['State', 'County'], LookupManagerService.english).then(res => {
      this.lookUpValues = res;
    }).catch(err => {
      console.log(err);
    });

    this.HAAddress = this.data.HAAddress;
    this.MAAddress = this.data.MAAddress;
  }

  getFullAddress(address: Address, suggAdd?, originalAddObj?): any {
    let fullAddress = '';

    if (!address) {
      return fullAddress;
    }
    if (address.addressLine1) {
      fullAddress += address.addressLine1;
    }
    if (address.addressLine2) {
      fullAddress += '\n ' + address.addressLine2;
    }
    if (address.city) {
      fullAddress += '\n ' + address.city;
    }
    let stateFlag = false;
    if (address.state) {
      if (this.lookUpValues['State']) {
        for (const entry of this.lookUpValues['State']) {
          if (entry.lkpDesc === address.state || entry.lookupShortVal === address.state) {
            fullAddress += '\n ' + entry.lookupLongVal;
            stateFlag = true;
          }
        }
      } if (!stateFlag) {
        fullAddress += '\n ' + address.state;
      }
    }
    let countyFlag = false;
    if(suggAdd == 'suggAdd' && originalAddObj) {
      if(!address.county && !this.data.isLTC) {
        address.county = originalAddObj.county;
      }
    }
    if (address.county && !this.data.isLTC) {
      if (this.lookUpValues['County']) {
        for (const entry of this.lookUpValues['County']) {
          if (entry.lkpDesc == address.county || entry.lookupShortVal == address.county) {
            fullAddress += '\n ' + entry.lookupLongVal;
            countyFlag = true;
          }
        }
      } if (!countyFlag) {
        fullAddress += '\n ' + address.county;
      }
    }
    if (address.district) {
      fullAddress += '\n ' + address.district;
    }
    if (address.zipcode) {
      fullAddress += '\n ' + address.zipcode;
    }
    if (address.zip4code && address.zipcode) {
      fullAddress += '-' + address.zip4code;
    }
    return fullAddress;
  }

  save() {
    const sendObj = {
      HAAddress: this.HAAddress,
      MAAddress: this.MAAddress,
      address: this.data.addressToVerify
    };
    this.dialogRef.close(sendObj);
  }

}
