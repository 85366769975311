import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiManager } from './api-manager.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, 
    private apiManager: ApiManager,
    private storage: StorageService,) { }

  refreshToken(token: string) {
    const body = {
      refresh_token: token
    };
    let queryParams: string[] = [this.storage.getClientId()];
    return this.apiManager.fetchData('refreshToken', undefined, ApiManager.POST, body,undefined,undefined,queryParams, undefined, true);
  }
}