import {BaseBean} from './base-bean.class';
import {PersonName} from './person-name.class';
import {SocialSecurityNumber} from './social-security-number.class';
import {Race} from './race.class';

export class Person extends BaseBean {
  constructor(
    public personName: PersonName = new PersonName(),
    public dob: Date = null, // Date
    public soSec: SocialSecurityNumber = new SocialSecurityNumber(),
    public reSoSec: SocialSecurityNumber = new SocialSecurityNumber(),
    public gender: string = '',  // enum
    public race: Race = new Race(),
    public deletedYN: string = '') {
    super();
  }
}
