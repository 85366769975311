import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})

export class DocumentReceiptService {

  printUserName: any;
  printHohName: any;
  printCtrlId: any;
  printCaseId: any;
  printContent;

  print(): void {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html lang="en">
        <head>
          <title>Document Receipt</title>
          <style>
              * {
                font-family: montRegular, sans-serif !important;
              }
              table {
                font-family: montRegular, sans-serif !important;
                border-collapse: collapse;
                width: 100%;
              }
              td, th {
                border: 2px solid #dddddd;
                text-align: left;
                padding: 8px;
              }
          </style>
        </head>
        <body onload="window.print(); window.close()">
          <img class="pull-left" src="assets/images/MdThinkLogo.png" alt="MdThink Logo" height="150" width="250">
          <br>
          <div class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h2 style="text-align: center !important; font-family: montRegular,sans-serif">Kyyba Customer Portal Document Receipt</h2>
            </div>
            <div class="col-md-4"></div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
               The following documents were successfully uploaded to and received by the Kyyba Customer Portal system:
            </div>
            <br>
            <div class="col-md-12">
              Customer Name: <span style="text-decoration: underline; font-weight: bold">${(this.printHohName) ? this.printHohName : ''}</span><br>
              Case Record Number: <span style="text-decoration: underline; font-weight: bold">${(this.printCaseId) ? this.printCaseId : ''}</span><br>
              Web ID Ref. Number: <span style="text-decoration: underline; font-weight: bold">${(this.printCtrlId) ? this.printCtrlId : ''}</span><br>
              Uploaded by: <span style="text-decoration: underline; font-weight: bold">${this.printUserName}</span><br>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <table>
                ${this.printContent}
              </table>
            </div>
          </div>
        </body>
      </html>
    `);
    popupWin.document.close();
  }
}
