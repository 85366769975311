import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';
export class SocialSecurityNumber extends BaseBean{
    constructor(
        public recordId: number = undefined,
        public soSecPart1: string = '',
        public soSecPart2: string = '',
        public soSecPart3: string = '',
        public ssnVerification: ClientVerification = new ClientVerification()
    ) {
        super();
    }
}
