import {ClientVerification} from './client-verification.class';

export class Race extends ClientVerification {
  constructor(
    public recordId: number = undefined,
    public primaryRace: string = '',
    public otherRaces: string[] = []
  ) {
    super();
  }

}
