import { BaseBean } from './base-bean.class';
import { Address } from './address.class';
import {NewPhoneNumberClasss, PhoneNumber} from './phone-number.class';

export class ContactInformation extends BaseBean{
  constructor(
    public homeAddress: Address = new Address(),
    public mailingAddress: Address = new Address(),
    public sameMailingAddressYN: string = '',
    public phoneNumber: PhoneNumber = new PhoneNumber(),
    public altPhoneNumber: PhoneNumber = new PhoneNumber(),
    public phoneNumberDetails: NewPhoneNumberClasss = new NewPhoneNumberClasss(),
    public email: string = '',
    public preferredModeOfCommunication: string = '',
    public preferredContactTime: string = '',
    public previousAddresses: Address[] = [],
    public previousPhoneNumbers: PhoneNumber[] = [],
    public entryDate: string = null,
    public returnYN: string = '',
    public futureReturnYN: string = ''
  ) {
    super();
  }
}
