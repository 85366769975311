import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatListOption, MatSelectionList, MatStepper} from "@angular/material";
import * as moment from 'moment';
import {ApiManager} from "../../../common/api-manager.service";
import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LookupManagerService} from "../../../common/lookup-manager.service";
import {Dictionary} from "underscore";
import {LookupClass} from "../../../model/api/lookup.class";
import {CpCaseLdssSlotSearchClass} from "../../../model/api/Intake-App/cp-case-ldss-slot-search.class";
import {StorageService} from "../../../common/storage.service";
import {IntakeService} from "../../../common/intake.service";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @Input() caseData: any;
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSelectionList) selectionList: MatSelectionList;
  public calMonth: any[] = [];
  public activeDate: Date = null;
  public interviewType: any = '';
  public missedInterviewYN: any = '';
  public apiObj: any = {ldssCd: "", caseId: null, startDate: "", endDate: ""};
  public weekDays: any[] = [{day: 'SUN', fullDay: 'SUNDAY', code: 0}, {day: 'MON', fullDay: 'MONDAY', code: 1}, {day: 'TUE', fullDay: 'TUESDAY', code: 2}, {day: 'WED', fullDay: 'WEDNESDAY', code: 3}, {day: 'THU', fullDay: 'THURSDAY', code: 4}, {day: 'FRI', fullDay: 'FRIDAY', code: 5}, {day: 'SAT', fullDay: 'SATURDAY', code: 6}];
  public currentDate: any = null;
  public selectedTile: any = null;
  public selectedTimeSlots: any[] = [];
  public interview: FormGroup;
  public lookUpValues: Dictionary<LookupClass[]>[] = [];

  constructor(private api: ApiManager,
              private fb: FormBuilder,
              private lookupService: LookupManagerService,
              private storage: StorageService,
              public intakeService: IntakeService) {}

  ngOnInit() {
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
    let d = new Date();
    this.activeDate = d;
    this.setMonthDetials(d);
    this.detMonth(d);

    this.interview = this.fb.group({
      selectedSlot: [null, Validators.compose([Validators.required])],
      fsInd: ['', Validators.compose([Validators.required])],
      chInd: ['', Validators.compose([Validators.required])],
      interviewType: ['', Validators.compose([Validators.required])]
    });

    this.lookupService.getLookUpValues(['County'], LookupManagerService.english).then(res => {
      this.lookUpValues = res;
    }).catch(err => {
      console.log(err);
    });
  }

  setMonthDetials(d: Date) {
    this.currentDate = {day: d.getDate(), month: moment(d).format('MMMM'), year: d.getFullYear()};
  }

  scheduleAppointment() {
    let formValue = this.interview.value;
    let sendObj: CpCaseLdssSlotSearchClass = {
      ldssCd: this.apiObj.ldssCd,
      caseId: this.caseData.case.caseId,
      startDate: null,
      endDate: null,
      startTime: formValue.selectedSlot[0].startTime,
      endTime: formValue.selectedSlot[0].endTime,
      workersAvailable: null,
      programInterviewScheduleId: null,
      dirtyStatus: "INSERT",
      interviewDate: this.selectedTile.fullDate,
      fsRequestYN: formValue.fsInd,
      chRequestYN: formValue.chInd,
      interviewType: formValue.interviewType,
      ldssTimeSlotId: formValue.selectedSlot[0].ldssTimeSlotId,
      caseWorkerId: this.storage.getUniqueId()
    };
    this.api.fetchData('interviewScheduler', undefined, ApiManager.POST, sendObj).toPromise().then(v => {
      console.log(v);
      this.submit.emit(this.caseData.close);
    }).catch(err => {
      console.log(err);
    })
  }

  updateCal(n) {
    let d = new Date(moment(this.activeDate).add(n, 'month').toISOString());
    if (new Date(d) >= new Date() || (new Date(d).getMonth() == new Date().getMonth() && new Date(d).getFullYear() == new Date(d).getFullYear())) {
      this.activeDate = d;
      this.setMonthDetials(d);
      this.detMonth(d);
    }
  }

  getColor(tile) {
    if (!tile.timeSlots || tile.timeSlots.length === 0) {
      return '#a5a6a9'
    } else if (tile.currentMonth) {
      return '#1F4076';
    } else {
      return '#607D8B'
    }

  }

  detMonth(d?: Date) {
    d = d ? d : new Date();

    this.calMonth = [];
    let firstDate = new Date(d.getFullYear(), d.getMonth(), 1);
    let lastDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    let firstDay = moment(firstDate).day();
    let lastDay = moment(lastDate).day();
    if (this.activeDate.getMonth() === new Date().getMonth()) {
      this.apiObj.startDate = moment(new Date()).format('YYYY-MM-DD');
    } else {
      this.apiObj.startDate = moment(firstDate).subtract(firstDay, 'days').format('YYYY-MM-DD');
    }

    this.apiObj.endDate = moment(lastDate).add(6 - lastDay, 'days').format('YYYY-MM-DD');
    this.apiObj.caseId = this.caseData.case.caseId;
    this.api.fetchData('getTimeSlots', undefined, ApiManager.POST, this.apiObj).toPromise().then(v => {
      this.enterCal(d, v);
      console.log(this.calMonth);
    }).catch(err => {
      console.log(err);
      this.enterCal(d, undefined);
    });

  }

  enterCal(d: Date, v) {
    let cals = [];
    let firstDate = new Date(d.getFullYear(), d.getMonth(), 1);
    let lastDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    let totalDays = moment(d).daysInMonth();
    let firstDay = moment(firstDate).day();
    let lastDay = moment(lastDate).day();
    let currentMonth = moment(d).month();
    let prevDate = moment(d).date(0).date();
    let dat = null;
    for (let i = firstDay - 1; i >= 0; i--) {
      dat = moment(firstDate).subtract(i + 1, 'days');
      this.calMonth.push({
        fullDate: dat.format('YYYY-MM-DD'),
        date: dat.date(),
        day: dat.day(),
        timeSlots: v ? v[dat.format('YYYY-MM-DD')] : [],
        currentMonth: false
      })
    }

    this.calMonth.push({
      fullDate: moment(firstDate).format('YYYY-MM-DD'),
      date: moment(firstDate).date(),
      day: moment(firstDate).day(),
      timeSlots: v ? v[moment(firstDate).format('YYYY-MM-DD')] : [],
      currentMonth: true
    });

    for (let j = 1; j < totalDays; j++) {
      dat = moment(firstDate).add(j, 'days');
      this.calMonth.push({
        fullDate: dat.format('YYYY-MM-DD'),
        date: dat.date(),
        day: dat.day(),
        timeSlots: v ? v[dat.format('YYYY-MM-DD')] : [],
        currentMonth: true
      })
    }

    for (let k = 0; k < 6 - lastDay; k++) {
      dat = moment(lastDate).add(k + 1, 'days');

      this.calMonth.push({
        fullDate: dat.format('YYYY-MM-DD'),
        date: dat.date(),
        day: dat.day(),
        timeSlots: v ? v[dat.format('YYYY-MM-DD')] : [],
        currentMonth: false
      })
    }
  }

  getSlots(s) {
    if (s && s.length > 0) {
      let ret;
      for (let i = 0; i < s.length; i++) {
        if (ret) {
          ret = ret + ', ' + s[i].startTime + ' - ' + s[i].endTime;
        } else {
          ret = s[i].startTime + ' - ' + s[i].endTime;
        }
      }
      return ret;
    } else {
      return 'No slots available'
    }
  }

  navigate(tile, stepper: MatStepper) {
    if (tile.timeSlots && tile.timeSlots.length > 0) {
      this.selectedTile = tile;
      this.selectedTimeSlots = tile.timeSlots;
      console.log(tile);
      stepper.next();
    }
  }

  getDate(date) {
    return date;
  }

}
