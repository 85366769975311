import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {hohMembersClass} from "../model/api/InterimChange/ic-hohmembers.class";
import {HeaderDetailsClass} from "../model/header-details.class";
import {environment} from '../../environments/environment';

@Injectable()
export class StorageService {
  private locaCase: any;

  constructor(private storage: LocalStorageService,
              private session: SessionStorageService) {
  }

  getClientId(){
    return this.session.retrieve('clientId');
  }

  setCaseId(caseId) {
    // if(window.sessionStorage)
    this.session.store('caseId', caseId);
    // window.sessionStorage.setItem('caseId',caseId);
  }

  getCaseId() {
    // this.locaCase =  window.sessionStorage.getItem('caseId');
    return this.session.retrieve('caseId');
  }

  getIntakeHeaderDetails(): HeaderDetailsClass {
    return this.session.retrieve('intakeHeaderDetails');
  }

  setIntakeHeaderDetails(val: HeaderDetailsClass) {
    this.session.store('intakeHeaderDetails', val);
  }

  getInterimHeaderDetails(): HeaderDetailsClass {
    return this.session.retrieve('interimHeaderDetails');
  }

  setInteirmHeaderDetails(val: HeaderDetailsClass) {
    this.session.store('interimHeaderDetails', val);
  }


  setPrograms(selectedPrograms) {
    this.session.store('selectedPrograms', selectedPrograms);
  }

  getPrograms() {
    return this.session.retrieve('selectedPrograms');
  }

  setLanguage(lang) {
    this.session.store('lang', lang);
  }

  getLanguage() {
    return this.session.retrieve('lang');
  }

  setUserId(userId) {
    this.session.store('user', userId);
  }

  getUserId() {
    //return this.session.retrieve('user');
    return environment.uid;
  }

  setRoles(roles) {
    this.session.store('roles', roles);
  }

  getRoles() {
    //return this.session.retrieve('roles');
    return environment.role;
  }

  setIdToken(idToken) {
    this.session.store('idToken', idToken);
  }

  getIdToken() {
    return this.session.retrieve('idToken');
  }


  setHeaderRoles(rolesHeader) {
    this.session.store('rolesHeader', rolesHeader);
  }

  getHeaderRoles() {
    return this.session.retrieve('rolesHeader');
  }

  setAccessToken(accessToken){
    this.session.store('accessToken', accessToken);
  }

  getAccessToken() {
    //return this.session.retrieve('accessToken');
    return environment.accessToken;
  }

  setName(name) {
    this.session.store('name', name);
  }

  getName() {
    return this.session.retrieve('name');
  }

  setPath(path) {
    this.session.store('path', path);
  }

  getPath() {
    return this.session.retrieve('path');
  }

  setSession(sessionId) {
    this.session.store('session', sessionId);
  }

  getSession() {
    return this.session.retrieve('session');
  }

  setUniqueId(uniqueId) {
    this.session.store('unique', uniqueId);
  }

  getUniqueId() {
    //return this.session.retrieve('unique');
    return environment.uniqueId;
  }

  setTimer(time) {
    this.session.store('timer', time);
  }

  getTimer() {
    return this.session.retrieve('timer');
  }

  getModalOpen() {
    return this.session.retrieve('timerModal');
  }

  setModalOpen(val) {
    return this.session.store('timerModal', val);
  }

  getBannerMsg() {
    return this.session.retrieve('bannerMsg');
  }

  setBannerMsg(val) {
    return this.session.store('bannerMsg', val);
  }
  destroyAll() {
    this.session.clear();
    this.storage.clear();
  }

  setVendorId(v) {
    this.session.store('vendorId', v);
  }

  getVendorId() {
    return this.session.retrieve('vendorId');
  }

  setCboIdList(v) {
    this.session.store('cboIdList', v);
  }

  getCboIdList() {
    return this.session.retrieve('cboIdList');
  }

  setCboOrgList(v) {
    this.session.store('cboOrgList', v);
  }

  getCboOrgList() {
    return this.session.retrieve('cboOrgList');
  }

  setIsPublicUser(publicUser) {
    this.session.store('publicUser', publicUser);
  }

  getIsPublicUser() {
    return this.session.retrieve('publicUser');
  }

  setConsumerControlId(id) {
    this.session.store('consumerControlId', id);
  }

  getConsumerControlId() {
    return this.session.retrieve('consumerControlId');
  }

  setInterimMembers(members: hohMembersClass[]) {
    this.storage.store('interimMembers', members);
  }

  getInterimMembers(): hohMembersClass[] {
    return this.storage.retrieve('interimMembers');
  }

  setAppType(type) {
    this.storage.store('appType', type);
  }

  getAppType() {
    return this.storage.retrieve('appType');
  }

  getRelationshipTypes() {
    return this.storage.retrieve('relations');
  }

  setRelationshipTypes(types) {
    this.storage.store('relations', types);
  }

  setIntakeProgramSelection(type) {
    this.storage.store('intakeProgramSelection', type);
  }

  getIntakeProgramSelection() {
    return this.storage.retrieve('intakeProgramSelection');
  }

  currentRole(role?: string) {
    if (role) {
      //this.storage.store('currentRole', role);
      return environment.role;
    } else {
      //return this.storage.retrieve('currentRole');
      return environment.role;
    }
  }

  userSource(val?: string) {
    if (val) {
      this.storage.store('userSource', val);
    } else {
      return this.storage.retrieve('userSource');
    }
  }

  setEAFormValidity(val: boolean) {
    this.session.store('eaForm', val);
  }

  getEAFormValidity() {
    return this.session.retrieve('eaForm');
  }

  setStandAloneFSP() {
    this.session.store('standAloneFSP', true);
  }

  getStandAloneFSP() {
    return this.session.retrieve('standAloneFSP');
  }

  getIPAddress() {
    return this.session.retrieve('ipAdd');
  }

  setIPAddress(ip: any) {
    this.session.store('ipAdd', ip);
  }

  setCpId(cpId) {
    this.session.store('cpId', cpId);
  }

  getCpId() {
    return this.session.retrieve('cpId');
  }

  setMyDHRToken(token) {
    this.session.store('token', token);
  }

  getMyDHRToken() {
    return this.session.retrieve('token');
  }

  setMyDHREmail(email) {
    this.session.store('email', email);
  }

  getMyDHREmail() {
    return this.session.retrieve('email');
  }

  setRedetActiveCase(val) {
    this.session.store('redetactivecase', val);
  }

  getRedetActiveCase() {
    return this.session.retrieve('redetactivecase');
  }

  getUserInfoPrefetch(){
    return this.session.retrieve('userData');
  }

  setUserInfoPrefetch(userData){
    return this.session.store('userData', userData);
  }

}
