export class HelpText {
    constructor(
        public id: number = undefined, // number
        public page: string = '',
        public title: string = '',
        public localeCd: string = '',
        public systemId: string = '',
        public helpText: string = ''
    ) { }
}
