import {Injectable} from '@angular/core';
import * as _ from 'underscore';
import {ApiManager} from './api-manager.service';
import {Dictionary} from 'underscore';

@Injectable()

export class TranslateService {

  public en_us2: any[] = [
    {
      'html_tag': 'contactInformation',
      'tag_txt': 'Contact Information'
    },
    {
      'html_tag': 'from',
      'tag_txt': 'From'
    },
    {
      'html_tag': 'language',
      'tag_txt': 'Language'
    },
    {
      'html_tag': 'to',
      'tag_txt': 'To'
    },
    {
      'html_tag': 'specifyLanguage',
      'tag_txt': 'Specify Language'
    },
    {
      'html_tag': 'interpreterNeeded',
      'tag_txt': 'Interpreter Needed'
    },
    {
      'html_tag': 'impairment',
      'tag_txt': 'Impairment'
    },
    {
      'html_tag': 'visuallyImpaired',
      'tag_txt': 'Visually Impaired'
    },
    {
      'html_tag': 'hearingImpaired',
      'tag_txt': 'Hearing Impaired'
    },
    {
      'html_tag': 'address',
      'tag_txt': 'ADDRESS'
    },
    {
      'html_tag': 'residentialAddress',
      'tag_txt': 'Residential Address'
    },
    {
      'html_tag': 'homeless',
      'tag_txt': 'Homeless'
    },
    {
      'html_tag': 'institution',
      'tag_txt': 'Institution'
    },
    {
      'html_tag': 'address',
      'tag_txt': 'Address'
    },
    {
      'html_tag': 'city',
      'tag_txt': 'City'
    },
    {
      'html_tag': 'zipCode',
      'tag_txt': 'ZIP Code'
    },
    {
      'html_tag': 'zip4Code',
      'tag_txt': 'ZIP+4 Code'
    },
    {
      'html_tag': 'state',
      'tag_txt': 'State'
    },
    {
      'html_tag': 'county',
      'tag_txt': 'County'
    },
    {
      'html_tag': 'mailingAddress',
      'tag_txt': 'Mailing Address'
    },
    {
      'html_tag': 'localDistrictOffice',
      'tag_txt': 'Local District Office'
    },
    {
      'html_tag': 'line1',
      'tag_txt': 'Address Line 1'
    },
    {
      'html_tag': 'line2',
      'tag_txt': 'Address Line 2'
    },
    {
      'html_tag': 'CONTACTINFORMATION',
      'tag_txt': 'Contact Information'
    },
    {
      'html_tag': 'phoneNumber',
      'tag_txt': 'Phone Number'
    },
    {
      'html_tag': 'number',
      'tag_txt': 'Number'
    },
    {
      'html_tag': 'ext',
      'tag_txt': 'Ext'
    },
    {
      'html_tag': 'type',
      'tag_txt': 'Type'
    },
    {
      'html_tag': 'alternatePhoneNumber',
      'tag_txt': 'Alternate Phone Number'
    },
    {
      'html_tag': 'email',
      'tag_txt': 'Email'
    },
    {
      'html_tag': 'preferredModeOfCommunication',
      'tag_txt': 'Preferred Mode of Communication'
    },
    {
      'html_tag': 'preferredContactTime',
      'tag_txt': 'Preferred Contact Time'
    },
    {
      'html_tag': 'previousAddresses',
      'tag_txt': 'Previous Address: Please tell us where you lived for the past 5 years.'
    },
    {
      'html_tag': 'addPreviousAddresses',
      'tag_txt': 'Add Previous Address'
    },
    {
      'html_tag': 'mailingAddressSameAsResidential',
      'tag_txt': 'Mailing Address is the same as Residential/Nursing Facility Address'
    },
    {
      'html_tag': 'Back',
      'tag_txt': 'Back'

    },
    {
      'html_tag': 'Next',
      'tag_txt': 'Next'
    },
    {
      "html_tag": "languagePopoverID",
      "tag_txt": "Add language Preferences"
    },
    {
      "html_tag": "homeAddressPopoverID",
      "tag_txt": "Add Home Address"
    },
    {
      "html_tag": "mailAddressPopoverID",
      "tag_txt": "Add Mailing Address"
    },
    {
      "html_tag": "phonePopoverID",
      "tag_txt": "Add Phone Number"
    },
    {
      "html_tag": "altphonePopoverID",
      "tag_txt": "Add Alternate Phone Number"
    },
    {
      "html_tag": "emailPopoverID",
      "tag_txt": "Add Email"
    },
    {
      "html_tag": "prefferedCommPopoverID",
      "tag_txt": "Add Preferred Mode of Communication"
    },
    {
      "html_tag": "prevAddPopoverID",
      "tag_txt": "Add Previous Address"
    }
  ];

  public es_us2: any[] = [
    {
      'html_tag': 'contactInformation',
      'tag_txt': 'Información del contacto'
    },
    {
      'html_tag': 'language',
      'tag_txt': 'Idioma'
    },
    {
      'html_tag': 'from',
      'tag_txt': 'de'
    },
    {
      'html_tag': 'to',
      'tag_txt': 'a'
    },
    {
      'html_tag': 'specifyLanguage',
      'tag_txt': 'Especificar idioma'
    },
    {
      'html_tag': 'interpreterNeeded',
      'tag_txt': 'Intérprete necesitado'
    },
    {
      'html_tag': 'impairment',
      'tag_txt': 'Discapacidad'
    },
    {
      'html_tag': 'visuallyImpaired',
      'tag_txt': 'Deficientes visuales'
    },
    {
      'html_tag': 'hearingImpaired',
      'tag_txt': 'Personas con discapacidad auditiva'
    },
    {
      'html_tag': 'address',
      'tag_txt': 'dirección'
    },
    {
      'html_tag': 'residentialAddress',
      'tag_txt': 'Dirección residencial'
    },
    {
      'html_tag': 'homeless',
      'tag_txt': 'sin hogar'
    },
    {
      'html_tag': 'address',
      'tag_txt': 'dirección'
    },
    {
      'html_tag': 'city',
      'tag_txt': 'ciudad'
    },
    {
      'html_tag': 'zipCode',
      'tag_txt': 'Código postal'

    },
    {
      'html_tag': 'zip4Code',
      'tag_txt': 'Código Zip + 4'
    },
    {
      'html_tag': 'state',
      'tag_txt': 'Estado'
    },
    {
      'html_tag': 'county',
      'tag_txt': 'Condado'
    },
    {
      'html_tag': 'mailingAddress',
      'tag_txt': 'Dirección de envio'

    },
    {
      'html_tag': 'localDistrictOffice',
      'tag_txt': 'Oficina de distrito local'

    },
    {
      'html_tag': 'line1',
      'tag_txt': 'Línea 1'

    },
    {
      'html_tag': 'line2',
      'tag_txt': 'Linea 2'
    },
    {
      'html_tag': 'CONTACTINFORMATION',
      'tag_txt': 'Información Del Contacto'

    },
    {
      'html_tag': 'phoneNumber',
      'tag_txt': 'Número de teléfono'

    },
    {
      'html_tag': 'number',
      'tag_txt': 'Número'
    },
    {
      'html_tag': 'ext',
      'tag_txt': 'Ext'
    },
    {
      'html_tag': 'type',
      'tag_txt': 'Tipo'
    },
    {
      'html_tag': 'alternatePhoneNumber',
      'tag_txt': 'Número de teléfono alternativo'

    },
    {
      'html_tag': 'email',
      'tag_txt': 'Email'
    },
    {
      'html_tag': 'preferredModeOfCommunication',
      'tag_txt': 'Modo preferido de comunicación'

    },
    {
      'html_tag': 'previousAddresses',
      'tag_txt': 'Direcciones anteriores'

    },
    {
      'html_tag': 'addPreviousAddresses',
      'tag_txt': 'Agregar direcciones anteriores'

    },
    {
      'html_tag': 'institution',
      'tag_txt': 'Institución'
    },
    {
      'html_tag': 'ownedByHouseHoldMember',
      'tag_txt': 'Propiedad de un miembro del hogar'

    },
    {
      'html_tag': 'mailingAddressSameAsResidential',
      'tag_txt': 'La dirección postal es igual a la dirección residencial'
    },
    {
      'html_tag': 'Back',
      'tag_txt': 'Espalda'

    },
    {
      'html_tag': 'Next',
      'tag_txt': 'Siguiente'
    },
    {
      "html_tag": "languagePopoverID",
      "tag_txt": "Agregar preferencias de idioma"
    },
    {
      "html_tag": "homeAddressPopoverID",
      "tag_txt": "Agregar dirección de casa"
    },
    {
      "html_tag": "mailAddressPopoverID",
      "tag_txt": "Agregar dirección postal"
    },
    {
      "html_tag": "phonePopoverID",
      "tag_txt": "Agregar el número de teléfono"
    },
    {
      "html_tag": "altphonePopoverID",
      "tag_txt": "Agregar número de teléfono alternativo"
    },
    {
      "html_tag": "emailPopoverID",
      "tag_txt": "Agregar correo electrónico"
    },
    {
      "html_tag": "prefferedCommPopoverID",
      "tag_txt": "Agregar el modo preferido de comunicación"
    },
    {
      "html_tag": "prevAddPopoverID",
      "tag_txt": "Agregar dirección anterior"
    }


  ];

  public en_us: any[] = [
    {
      'html_tag': 'firstName',
      'tag_txt': 'First Name'
    },
    {
      'html_tag': 'middleName',
      'tag_txt': 'Middle Name'
    },
    {
      'html_tag': 'lastName',
      'tag_txt': 'Last Name'
    },
    {
      'html_tag': 'suffix',
      'tag_txt': 'Suffix'
    },
    {
      'html_tag': 'dob',
      'tag_txt': 'Date Of Birth'
    },
    {
      'html_tag': 'ssn',
      'tag_txt': 'Social Security Number'
    },
    {
      'html_tag': 'sex',
      'tag_txt': 'Sex'
    },
    {
      'html_tag': 'race',
      'tag_txt': 'Race'
    },
    {
      'html_tag': 'ssnReferal',
      'tag_txt': 'SSN Referral'
    },
    {
      'html_tag': 'pregnancyStatus',
      'tag_txt': 'Pregnancy Status'
    },
    {
      'html_tag': 'pregnancyDate',
      'tag_txt': 'Pregnancy Date'
    },
    {
      'html_tag': 'appInfoHead',
      'tag_txt': 'APPLICATION INFORMATION - Please Tell Us About Yourself'
    }
  ];

  public es_us: any[] = [
    {
      'html_tag': 'firstName',
      'tag_txt': 'Nombre de pila'
    },
    {
      'html_tag': 'middleName',
      'tag_txt': 'Segundo nombre'
    },
    {
      'html_tag': 'lastName',
      'tag_txt': 'Apellido'
    },
    {
      'html_tag': 'suffix',
      'tag_txt': 'Sufijo'
    },
    {
      'html_tag': 'dob',
      'tag_txt': 'Fecha de nacimiento'
    },
    {
      'html_tag': 'ssn',
      'tag_txt': 'Número de seguridad social'
    },
    {
      'html_tag': 'sex',
      'tag_txt': 'Género'
    },
    {
      'html_tag': 'race',
      'tag_txt': 'Carrera'
    },
    {
      'html_tag': 'ssnReferal',
      'tag_txt': 'Referencia de SSN'
    },
    {
      'html_tag': 'pregnancyStatus',
      'tag_txt': 'Estado de embarazo'
    },
    {
      'html_tag': 'pregnancyDate',
      'tag_txt': 'Fecha de embarazo'
    },
    {
      'html_tag': 'appInfoHead',
      'tag_txt': 'INFORMACION DEL SOLICITANTE - Por favor, cuéntenos acerca de usted.'
    }
  ];

  private translatedObj: Dictionary<any>;
  private allTranslations: any[] = [];

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  constructor(private apiManager: ApiManager) {}

  getTranslation2(lang, pageNumber) {
    let sendObj: any[];
    if (lang === 'en_us') {
      if (pageNumber == 1) {
        sendObj = this.en_us;
      } else if (pageNumber == 2) {
        sendObj = this.en_us2;
      }
    } else if (lang === 'es_us') {
      if (pageNumber == 1) {
        sendObj = this.es_us;
      } else if (pageNumber == 2) {
        sendObj = this.es_us2;
      }
    } else {
      if (pageNumber == 1) {
        sendObj = this.en_us;
      } else if (pageNumber == 2) {
        sendObj = this.en_us2;
      }
    }
    console.log(this.formatJson2(sendObj));
    return this.formatJson2(sendObj);
  }

  getTranslation(lang, PageNumber): Promise<any> {
    const selectedPage = PageNumber + lang;
    let result;
    if (this.allTranslations[selectedPage]) {
      result = this.allTranslations[selectedPage];
    } else {
      const sendObj = {
        "localeCode": lang,
        "screenName": PageNumber,
        "systemId": "DHSCP"
      };
      result = this.apiManager.fetchData('labelLookUp', undefined, ApiManager.POST, sendObj).toPromise().then(this.extractData).catch(this.handleError);
      console.log("Translation: ", result);
      this.allTranslations[selectedPage] = result;
    }
    return result;
  }

  formatJson(obj: any[]) {
    return _.indexBy(obj, 'htmlTag');
  }

  formatJson2(obj: any[]) {
    return _.indexBy(obj, 'html_tag');
  }

}
