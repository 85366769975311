import { BaseBean } from './base-bean.class';

export class ProgramGroup extends BaseBean{
    constructor(
        public runDate: Date = null,
        public effectiveFromDate: Date = null,
        public effectiveToDate: Date = null,
        public programModeCd: string = '', 
        public programName: string = '',
        public programTypeCd: string = '',
        public programId: number = undefined

    ){
        super();
     }
}
