import { BaseBean } from './base-bean.class';
import { Program } from './program.class';

export class Eligibility extends BaseBean {

  constructor(
    public programs: Program[] = [],
    //public finalizedPrograms: Program[] = [],
    public expeditedFoodStampsYN: string = '',
    public scrEligibilityRunTimestamp: string = ''
  ) {
    super();
  }

}
