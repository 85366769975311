import { BaseBean } from './base-bean.class';
export class PhoneNumber extends BaseBean{
  constructor(
    public phoneNumberId: number = undefined,
    public phoneNumber: number = undefined,  // number
    public phoneNumberType: string = '',
    public phoneNumberExt: string = '', // number
    public phoneNumberStartDate: Date = null,
    public phoneNumberEndDate: Date = null
  ) {
    super();
  }
}

export class NewPhoneNumberClasss extends BaseBean{
  constructor(
    public phoneNumber: number = undefined,
    public altPhoneNumber: number = undefined,
    public phoneNumberTypeCd: string = '',
    public altPhoneNumberTypeCd: string = '',
    public phoneNumberExtension: string = '',
    public altPhoneNumberExtension: string = ''
  ){
    super();
  }
}
