import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNavPopupComponent } from './custom-nav-popup/custom-nav-popup.component';
import {AngularMaterialModule} from "../angular-material/angular-material.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SelectCustomNavComponent} from "./select-custom-nav.component";
import {MatDialogConfig} from "@angular/material";
import {NavigationService} from "../../common/navigation.service";
import {IntakeService} from "../../common/intake.service";

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule
  ],
  declarations: [CustomNavPopupComponent, SelectCustomNavComponent],
  providers: [MatDialogConfig, NavigationService, IntakeService ],
  entryComponents: [CustomNavPopupComponent]
})
export class CustomNavModule { }
