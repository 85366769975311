import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ConsumerNavClass} from "../model/api/consumer-nav.class";
import * as _ from 'underscore';
import {EventEmitterService} from './event.emitter.service';
import {Router} from "@angular/router";
import {ProgramSelected} from "../model/api/JsonModel/IntakeModel/intake-program-selected.class";
import {Observable} from "rxjs";
import {ApiManager} from "./api-manager.service";
import {StorageService} from "./storage.service";
import {hohMembersClass} from "../model/api/InterimChange/ic-hohmembers.class";

@Injectable()
export class NavigationService {
  private navItems: ConsumerNavClass[] = [];
  private navHead: ConsumerNavClass[] = [];
  private navChildren: ConsumerNavClass[];
  private currentNav: ConsumerNavClass[];
  public nav: ConsumerNavClass[] = [];
  public selectedPgms: string[] = [];
  private showSubmit: boolean = false;
  private langSelected: string;

  constructor(private storage: LocalStorageService,
              private session: SessionStorageService,
              private emitter: EventEmitterService,
              private router: Router,
              private apiManager: ApiManager,
              private storageService: StorageService) {
    // spanish translation
    this.emitter.subscribe('changeLang', (data) => {
      this.storageService.setLanguage(data);
      this.changeLanguage(data);
    });

    this.langSelected = this.storageService.getLanguage();
    if (this.langSelected != 'es_us') {
      this.langSelected = 'en_us';
      this.storageService.setLanguage(this.langSelected);
    }
    // this.changeLanguage(this.storageService.getLanguage());
    // end spanish translation
  }

  changeLanguage(lang) {
    this.langSelected = lang;
    if(this.session.retrieve('appType') != 'IC'){
      this.navDBCall().then(val => {
        this.navItems = val;
        this.navHead = _.filter(this.navItems, (val) => {
          return val.is_parent == true;
        });
        this.setNavHead(this.navHead);
        this.setNavItems(this.navItems);
        this.enableAll();
      });
    }
  }

  private extractData(res) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  setNavItems(items) {
    this.navItems = items;
    this.storage.store('allNavItems', items);
  }

  getNavItems() {
    return this.storage.retrieve('allNavItems');
  }

  setNavHead(items) {
    this.navHead = items;
    this.storage.store('navHead', items);
  }

  getShowSubmit(): boolean {
    return this.showSubmit;
  }

  getNavHead() {
    return this.storage.retrieve('navHead');
  }

  buildCustomNavBar(navs: ConsumerNavClass[]) {
    let selectedNavs: ConsumerNavClass[] = [];
    let parentCds: any[] = [];
    navs.forEach(v => {
      if (!parentCds.includes(v.prnt_desc)) {
        parentCds.push(v.prnt_desc);
      }
      selectedNavs.push(v);
    });
    this.navItems.forEach(v => {
      if (v.isRequired == 'N' && v.prnt_desc != 'parentAssistance' && v.prnt_desc != 'parentSummary') {
        selectedNavs.push(v);
      } else if (v.prnt_desc == 'parentSummary') {
        selectedNavs.push(v);
      } else if (v.is_parent && parentCds.includes(v.prnt_desc)) {
        selectedNavs.push(v);
      }
    });
    this.setNavItems(selectedNavs);
    this.setNavHead(selectedNavs.filter(v => {
      return v.is_parent
    }));
    return selectedNavs;
  }


  getPage(url, dir: string) {
    this.currentNav = this.getCurrentNav(url);
    this.navChildren = this.giveChildren(this.currentNav[0].prnt_desc);
    let minMax = this.givMinMax(this.navChildren);
    let parentMinMax;
    let currentParentNum;
    let aParentCd;
    let sendUrl;
    let currPage: ConsumerNavClass[];
    let navHead = this.getNavHead();
    let navItems = this.getNavItems();
    if (dir == 'next') {
      if (this.currentNav[0].sort_order_nbr < minMax.max) {
        let nav;
        for (let u = this.currentNav[0].sort_order_nbr + 1; u <= minMax.max; u++) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
      } else {
        parentMinMax = this.givMinMax(navHead);
        currentParentNum = navHead.filter(v => {
          if (v.prnt_desc == this.currentNav[0].prnt_desc) {
            return v;
          }
        })[0].sort_order_nbr;
        if (currentParentNum < parentMinMax.max) {
          let nav;
          for (let y = currentParentNum + 1; y <= parentMinMax.max; y++) {
            nav = navHead.filter(v => {
              if (v.sort_order_nbr == y) {
                return v;
              }
            });
            if (nav && nav[0]) {
              aParentCd = nav[0].prnt_desc;
              break;
            }
          }
          this.navChildren = this.giveChildren(aParentCd);
          minMax = this.givMinMax(this.navChildren);
          currPage = this.getCurrentNavSO(aParentCd, minMax.min);
          this.currentNav = currPage;
          if (this.currentNav[0].sort_order_nbr == minMax.max && currentParentNum + 1 == parentMinMax.max) {
            this.showSubmit = true;
          }
          sendUrl = currPage[0].screen_defn;
        }
      }
    } else if (dir == 'back') {
      if (this.currentNav[0].sort_order_nbr > minMax.min) {
        let nav;
        for (let u = this.currentNav[0].sort_order_nbr - 1; u >= minMax.min; u--) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
        this.showSubmit = false;
        sendUrl = this.currentNav[0].screen_defn;
      } else {
        parentMinMax = this.givMinMax(navHead);
        currentParentNum = navHead.filter(v => {
          if (v.prnt_desc == this.currentNav[0].prnt_desc) {
            return v;
          }
        })[0].sort_order_nbr;
        if (currentParentNum > parentMinMax.min) {
          for(let nav = 0; nav <= navHead.length; nav++){
            if(navHead[nav].sort_order_nbr == currentParentNum - 1){
              aParentCd = navHead[nav].prnt_desc;
              break;
            } else if(navHead[nav].sort_order_nbr > currentParentNum - 1) {
              aParentCd = navHead[nav - 1].prnt_desc;
              break;
            }
          }
          // aParentCd = navHead.filter(v => {
          //   if (v.sort_order_nbr == currentParentNum - 1) {
          //     return v;
          //   }
          // })[0].prnt_desc;
          this.navChildren = this.giveChildren(aParentCd);
          minMax = this.givMinMax(this.navChildren);
          currPage = this.getCurrentNavSO(aParentCd, minMax.max);
          this.currentNav = currPage;
          this.showSubmit = false;
          sendUrl = currPage[0].screen_defn;
        }
      }
    }
    return sendUrl;
  }

  getCurrentNavSO(parentCd, num) {
    return this.getNavItems().filter(v => {
      if (v.prnt_desc == parentCd && v.sort_order_nbr == num && v.is_parent == false) {
        return v;
      }
    });
  }

  getParentCode(url): string {
    let code: string = '';
    this.getNavItems().forEach(v => {
      if (v.screen_defn == url) {
        code = v.prnt_desc;
      }
    });
    return code;
  }

  checkForSubmit(url) {
    let navHead = this.getNavHead();
    let parentCd = this.getParentCode(url);
    this.navHead.forEach(v => {
      if (v.prnt_desc == parentCd) {
        this.showSubmit = (v.sort_order_nbr == navHead.length);
      }
    })
  }

  getCurrentNav(url): ConsumerNavClass[] {
    return this.getNavItems().filter(v => {
      if (v.screen_defn === url) {
        return v;
      }
    });
  }

  giveChildren(parent_cd: string): ConsumerNavClass[] {
    let sendChild: ConsumerNavClass[];
    sendChild = _.filter(this.getNavItems(), (val) => {
      return (val.is_parent === false && val.prnt_desc === parent_cd);
    });
    return sendChild;
  }

  givMinMax(obj: ConsumerNavClass[]) {
    let returnObj = {
      min: 1,
      max: 1
    };
    if (obj.length == 1) {
      returnObj.min = obj[0].sort_order_nbr;
      returnObj.max = obj[0].sort_order_nbr;
    } else if (obj[0].sort_order_nbr > obj[1].sort_order_nbr) {
      returnObj.min = obj[1].sort_order_nbr;
      returnObj.max = obj[0].sort_order_nbr;
    } else if (obj[0].sort_order_nbr < obj[1].sort_order_nbr) {
      returnObj.min = obj[0].sort_order_nbr;
      returnObj.max = obj[1].sort_order_nbr;
    }
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].sort_order_nbr > returnObj.max) {
        returnObj.max = obj[i].sort_order_nbr;
      }
      if (returnObj.min > obj[i].sort_order_nbr) {
        returnObj.min = obj[i].sort_order_nbr;
      }
    }
    return returnObj;

  }

  getScreenId(currentUrl?): number {
    this.nav = this.getNavItems();
    let screenId: number = 0;
    const url: string = currentUrl ? currentUrl : this.router.url;
    this.nav.forEach(v => {
      if (v.screen_defn == url) {
        screenId = v.screen_id;
      }
    });

    return screenId;
  }

  setPrograms(pgms: ProgramSelected[]): Observable<any> {
    this.storageService.setPrograms(pgms);
    if (pgms && pgms.length > 0) {
      this.selectedPgms = [];
      pgms.forEach(v => {
        if(v.programSelected.programName.toUpperCase() == 'MA') {
          if (v.programSelected.programSubType.toLowerCase() == 'os') {
            this.selectedPgms.push('abd');
          } else if (v.programSelected.programSubType == '') {
            this.selectedPgms.push('LT');
          } else {
            this.selectedPgms.push(v.programSelected.programSubType);
          }
        } else if(v.programSelected.programName.toUpperCase() == 'LT' || v.programSelected.programName.toUpperCase() == 'WV') {
          this.selectedPgms.push('LT');
        } else if(v.programSelected.programName.toUpperCase() == 'OHEP') {
          this.selectedPgms.push(v.programSelected.programSubType);
        } else {
          this.selectedPgms.push(v.programSelected.programName.toUpperCase());
        }
      });
      return this.getNavs();
    }
  }

  getNavs(): Observable<any> {
    return Observable.create(observer => {
      this.navDBCall().then(val => {
        this.navItems = val;
        if(this.session.retrieve('intakeMembers')) {
          this.navItems.map(nav => {
            if(nav.screen_defn == '/dashboard/consumer-app/intake-app/members/relationship'){
              let members: hohMembersClass[] = this.session.retrieve('intakeMembers');
              members = members.length < 0 ? members : members.filter(v => {
                return v.dirtyStatus != 'DELETE';
              });
              nav.screen_actv_ind = (members.length > 1) ? 'Y' : 'N';
            }
          });
        }
        this.navHead = _.filter(this.navItems, (val) => {
          return val.is_parent == true;
        });
        this.setNavHead(this.navHead);
        this.setNavItems(this.navItems);
        observer.next(val);
        observer.complete();
      }).catch(err => {
        observer.next(err);
        observer.complete();
      });
    });
  }

  enableHOH() {
    let nav = this.getNavItems();
    this.setNavItems([]);
    this.setNavHead([]);
    nav.forEach((v, i) => {
      if (v.nvgtn_cd == 'nav-in-head-hoh' || v.nvgtn_cd == 'nav-in-hoh-hohInfo' || v.nvgtn_cd == 'nav-in-assistance-fspQuestions' || v.nvgtn_cd == 'nav-in-assistance-fspQualification') {
        nav[i].disabled = 'N';
      }
    });
    this.setNavItems(nav);
    this.navHead = _.filter(this.getNavItems(), (val) => {
      return val.is_parent == true;
    });
    this.setNavHead(this.navHead);
  }

  enableAll() {
    let nav = this.getNavItems();
    this.setNavItems([]);
    this.setNavHead([]);
    nav.forEach((v, i) => {
      nav[i].disabled = 'N';
    });
    this.setNavItems(nav);
    this.navHead = _.filter(this.getNavItems(), (val) => {
      return val.is_parent == true;
    });
    this.setNavHead(this.navHead);
  }

  navDBCall() {
    if(this.storageService.getPrograms() && this.storageService.getPrograms().length > 0) {
      this.selectedPgms = [];
      this.storageService.getPrograms().forEach(v => {
        if(v.programSelected.programName.toUpperCase() == 'MA') {
          if (v.programSelected.programSubType.toLowerCase() == 'os' || v.programSelected.programSubType.toLowerCase() == 'abd') {
            this.selectedPgms.push('abd');
          } else if (v.programSelected.programSubType == '') {
            this.selectedPgms.push('LT');
          } else {
            this.selectedPgms.push(v.programSelected.programSubType);
          }
        } else if(v.programSelected.programName.toUpperCase() == 'LT' || v.programSelected.programName.toUpperCase() == 'WV') {
          this.selectedPgms.push('LT');
        } else if(v.programSelected.programName.toUpperCase() == 'OHEP') {
          this.selectedPgms.push(v.programSelected.programSubType);
        } else {
          this.selectedPgms.push(v.programSelected.programName.toUpperCase());
        }
      });
    }

    const sendObj = {
      localeCode: (this.langSelected) ? this.langSelected : 'en_us',
      appType: 'AP',
      pgmType: this.selectedPgms && this.selectedPgms.length > 0 ? this.selectedPgms : ['FS']
    };
    return this.apiManager.fetchData('consumerNav', undefined, ApiManager.POST, sendObj).toPromise().then(this.extractData).catch(this.handleError);
  }


}
