import { CDBPInquiryResults } from './cdbpinquiry-results.class';
import { RuleMessages } from './rules-messages.class';
import { LocalDistrictOfficeAddress } from './local-district-office-address.class';
import { Vendor } from './vendor.class';
import { BenefitsCase } from './benefits-case.class';
import { Verification } from './../api/verification.class';
import { WorkerContainer } from './worker-container.class';
import { ClientContainer } from './client-container.class';
import { HelpTextContainer } from './help-text-container.class';
import { Address } from './address.class';
import { TrialBudget } from './trial-budget.class';
import { SearchModel } from './search-model.class';
import { ApiResponseModel } from './api-response-model.class';
import { Narrative } from './narrative.class';
import { WorkerCaseContainer } from './worker-case-container.class';

export class HumanServices extends ApiResponseModel {
    constructor(

        public previousEligibilityMap: Map<string, BenefitsCase> = new Map<string, BenefitsCase>(),
        public benefitsCase: BenefitsCase = new BenefitsCase(),
        public verification: Verification = new Verification(),
        public workerManagement: WorkerContainer = new WorkerContainer(),
        public clientManagement: ClientContainer = new ClientContainer(),
        public helpTextContainer: HelpTextContainer = new HelpTextContainer(),
        public addressSearches: SearchModel<Address>[] = [],
        public benefitsCaseSearches: SearchModel<BenefitsCase> = new SearchModel<BenefitsCase>(),
        public trialBudget: TrialBudget = new TrialBudget(),
        public narrativeSearches: SearchModel<Narrative> = new SearchModel<Narrative>(),
        public vendorSearches: SearchModel<Vendor> = new SearchModel<Vendor>(),
        public ldss: LocalDistrictOfficeAddress = new LocalDistrictOfficeAddress(),
        public rulesMessages: RuleMessages = new RuleMessages(),
        public cdbpInquiry: SearchModel<CDBPInquiryResults> = new SearchModel<CDBPInquiryResults>(),
        public workerCaseContainer: WorkerCaseContainer = new WorkerCaseContainer(),
        public sourceSystem: string = 'C'
        // public vendor: Vendor = new Vendor()
    ) {
        super();
    }
}
