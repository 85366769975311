import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpModule} from '@angular/http';
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {AppComponent} from './app.component';
import {routing} from './app.routes';
import {MapToFile} from './model/custom/map-to-file.class';
import {ApiManager} from './common/api-manager.service';
import {ConstantsService} from './common/constants.service';
import {DataShareService} from './common/data-share.service';
import {AlertService} from './common/alert.service';
import {DirtyStatusService} from './common/dirty-status.service';
import {HelpTextService} from './common/help-text.service';
import {EventEmitterService} from './common/event.emitter.service';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {HomeScreenComponent} from './homeScreen/homeScreen.component';
import {StorageService} from './common/storage.service';
import {TranslateService} from './common/translate.service';
import {OrderModule} from 'ngx-order-pipe';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxSpinnerModule} from 'ngx-spinner';
import {NavigationService} from "./common/navigation.service";
import {LoaderService} from "./common/loader.service";
import {StyleManager} from "./common/style-manager.service";
import {ThemeStorage} from "./common/theme-storage.service";
import {IntakeService} from "./common/intake.service";
import {LookupManagerService} from "./common/lookup-manager.service";
import {InterimChangeService} from "./common/interim-change.service";
import {NavigationWizardService} from "./common/navigation-wizard.service";
import {SharedModule} from "./SharedModule/shared.module";
import {PrintService} from "./common/print.service";
import {DocumentReceiptService} from "./common/document-receipt.service";
import { AuthInterceptor } from './common/auth.interceptor';
import { AppUtilService } from './common/app.util.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeScreenComponent
  ],
  imports: [
    BrowserModule,
    routing,
    HttpModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    NgxSpinnerModule,
    OrderModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    DataShareService,
    ConstantsService,
    ApiManager,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MapToFile,
    AlertService,
    DirtyStatusService,
    HelpTextService,
    EventEmitterService,
    StorageService,
    TranslateService,
    NavigationService,
    LoaderService,
    StyleManager,
    ThemeStorage,
    IntakeService,
    NavigationWizardService,
    LookupManagerService,
    InterimChangeService,
    PrintService,
    DocumentReceiptService,
    AppUtilService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
