import { IndividualTaxStatus } from './individual-tax-status.class';
import { ClientVerification } from './client-verification.class';
import { Relationship } from './relationship.class';
import { InstitutionalInformation } from './institutional-information.class';
import { Program } from './program.class';
import { PersonName } from './person-name.class';
import { SocialSecurityNumber } from './social-security-number.class';
import { Person } from './person.class';
import { IndividualDetails } from './individual-details.class';
import { CitizenshipImmigration } from './citizenship-immigration.class';
import { StudentInformation } from './student-information.class';
import { Disability } from './disability.class';
import { Asset } from './asset.class';
import { EarnedIncome } from './earned-income.class';
import { UnearnedIncome } from './unearned-income.class';
import { AppliedUnearnedIncome } from './applied-unearned-income.class';
import { DependentCareExpenses } from './dependent-care-expenses.class';
import { ShelterExpense } from './shelter-expense.class';
import { ActualUtilityExpenseClaimed } from './actual-utility-expense-claimed.class';
import { SpecialNeeds } from './special-needs.class';
import { MedicalExpenses } from './medical-expenses.class';
import { Eligibility } from './eligibility.class';
import { InsurancePolicyDetails } from './insurance-policy-details.class';
import { PotentialAsset } from './potential-asset.class';
import { IndividualTaxFiling } from './individual-tax-filing-status.class'
import { Medicare } from '../medicare.class';
import {MaritalStatus} from "./marital-status.class";
import {AboutMemberClass} from "./Intake-App/about-member.class";
import {Pregnancy} from "./pregnancy.class";
import {Address} from "./JsonModel/IntakeModel/intake-address.class";

export class HouseholdMember extends Person {
  constructor(
    private matchPercentage: number = undefined,
    public householdMemberId: number = undefined,
    public individualId: string = '',
    public headOfHouseholdYN: string = '',
    public relationshipCd: string = '',
    public relations: Relationship[] = [],
    public altNames: PersonName[] = [],
    public additionalSsns: SocialSecurityNumber[] = [],
    public pregnantYN: string = '',
    public pregnancy: Pregnancy[] = [],
    public aliasClientIds: number[] = [],
    public memberClearanceId: number = undefined,
    public memberClearedYN: string = '',
    public memberEditableYN: string = '',
    public cases: string[] = [],
    // public verification: HouseholdVerification = new HouseholdVerification(),
    public hasSsnYN: string = '',
    public ssnReferral: string = '',
    public eligibility: Eligibility = new Eligibility(),
    public approvedEligibility: Eligibility = new Eligibility(),
    public mdmId: number = undefined,
    public aboutMember: AboutMemberClass = new AboutMemberClass(),
    // interview module
    public financialResposibility: string = '',
    public applicablePrograms: Program[] = [],
    public ssnReferralDate: Date = null,
    public individualDetails: IndividualDetails = new IndividualDetails(),
    public medicare: Medicare = new Medicare(),
    public citizenshipImmigration: CitizenshipImmigration = new CitizenshipImmigration(),
    public priorCitizenshipImmigration: CitizenshipImmigration[] = [],
    // public studentInformation: StudentInformation = new StudentInformation(),
    public disabilities: Disability[] = [],
    public institutionalInformation: InstitutionalInformation = new InstitutionalInformation(),
    public assets: Asset[] = [],
    public potentialAssets: PotentialAsset[] = [],
    public earnedIncomes: EarnedIncome[] = [],
    public unearnedIncomes: UnearnedIncome[] = [],
    public appliedUnearnedIncomes: AppliedUnearnedIncome[] = [],
    public dependentCareExpenses: DependentCareExpenses[] = [],
    public shelterExpenses: ShelterExpense[] = [],
    public utilityExpenseType: string = '',
    public utilityExpenses: ActualUtilityExpenseClaimed[] = [],
    public specialNeeds: SpecialNeeds[] = [],
    public medicalExpenses: MedicalExpenses[] = [],
    public disabledYN: string = '',
    public refugeeYN: string = '',
    public blindYN: string = '',
    public destituteMigrantYN: string = '',
    public shelterIncludesMealsYN: string = '',
    public protectiveLivingArrangementYN: string = '',
    public communityBasedWaiverYN: string = '',
    public medicarePartAEntitlementYN: string = '',
    public absentParentYN: string = '',
    public insurancePolicies: InsurancePolicyDetails[] = [],
    public outStandingVerifications: ClientVerification[] = [],
    public individualTaxStatus: IndividualTaxStatus = new IndividualTaxStatus(),
    public irn: number = null,
    public verificationStatus: Map<String, String> = undefined,
    public taxFilingStatuses: IndividualTaxFiling[] = [],
    public createMdmId: boolean = true,
    public maritalStatus: MaritalStatus = undefined,
    public ethnicity: string = '',
    public voterRegistrationId: number = 0,
    public voterRegistrationCode: string = '',
    public dobVerification: ClientVerification = new ClientVerification(),
    public address: Address = new Address()
  ) {
    super();
  }
}
