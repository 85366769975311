import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { ApiManager } from './common/api-manager.service';
import { environment } from '../environments/environment';
import { Http } from '@angular/http';
import { StorageService } from "./common/storage.service";
import { UserAnalyticsClass } from './model/api/user-analytics.class';
import { LoaderService } from "./common/loader.service";
import { LoaderState } from "./model/loader.class";
import { Subscription } from "rxjs";
import { OverlayContainer } from "@angular/cdk/overlay";
import { StyleManager } from "./common/style-manager.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:onunload', ['$event'])
  unloadHandler(event) {
    window.location.href = this.logoutUrl;
  }

  private logoutUrl: any = environment.logoutURL;
  envName = environment.envName;
  title = 'IB';
  loading: Boolean = false;
  template: string = `<img src="https://cdn.dribbble.com/users/24091/screenshots/882638/metaloader.gif">`;
  private userAnalytics: UserAnalyticsClass;
  private currentUrl: String;

  show: number = 0;
  private subscription: Subscription;

  themeClass: string;

  public constructor(private router: Router,
    private http: Http,
    private storage: StorageService,
    private apiManager: ApiManager,
    private loaderService: LoaderService,
    private overlayContainer: OverlayContainer,
    private styleManager: StyleManager) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {
    this.styleManager.removeStyle('theme');
    this.styleManager.setStyle('dark', 'assets/prebuilt-themes/deeppurple-amber.css');
    this.storage.setLanguage('en_us');
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
    // if(!this.storage.getLanguage()) {
    //   this.storage.setLanguage('en_us');
    // }
     // this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show ? this.show + 1 : this.show - 1;
      console.log(this.show);
    });
  }


  validateIPaddress(inputText) {
    var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (inputText.match(ipformat))
      return true;
    else
      return false;
  }
  getIP(json) {
    if (json != undefined && json != null && this.validateIPaddress(json)) {
      this.storage.setIPAddress(json);
    }
  }
  
  ngAfterViewInit() {
    console.log('IP Address from Window: ' + window.name);
    this.getIP(window.name);
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.show = 0;
      this.show = this.show + 1;
    }
    if (event instanceof NavigationEnd) {
      this.show = this.show - 1;
      this.userAnalyticsMethod(event.url);
    }
  }

  userAnalyticsMethod(url) {
    if (url != this.currentUrl) {
      this.currentUrl = url;
      let caseId: string = (url.indexOf('intake') > -1 || url.indexOf('interim') > -1) ? ((this.storage.getCaseId()) ? this.storage.getCaseId() : null) : null;
      this.userAnalytics = {
        'caseId': caseId,
        'email': this.storage.getUserId(),
        'name': this.storage.getName(),
        'screenDefinition': url,
        'uniqueUserId': this.storage.getUniqueId(),
        'visitType': 'DHSCP'
      };

      this.apiManager.fetchData('userAnalytics', undefined, ApiManager.POST, this.userAnalytics).toPromise().then(res => {
        console.log('userAnalytics: ', res);
      }).catch(err => {
        console.log(err);
      })
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
