import { BaseBean } from './base-bean.class';
import { ScrAmountType } from './scr-amount-type.class';

export class OtherIncome extends BaseBean{
  constructor(
    public recordId: number = undefined,
    public otherIncomes: ScrAmountType[] = [],
    public unemploymentBenefitsYN: string = ''
  ) {
    super();
  }
}
