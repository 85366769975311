import {Component, Inject, OnInit} from '@angular/core';
import {ConsumerNavClass} from "../../../model/api/consumer-nav.class";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {IntakeService} from "../../../common/intake.service";
import {NavigationService} from "../../../common/navigation.service";
import {NavFlatNodeClass} from "../select-custom-nav.component";
import * as _ from "underscore";
import {CustomNavClass} from "../../../model/api/custom-nav.class";
import {ApiManager} from "../../../common/api-manager.service";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

@Component({
  selector: 'app-custom-nav-popup',
  templateUrl: './custom-nav-popup.component.html',
  styleUrls: ['./custom-nav-popup.component.css']
})

export class CustomNavPopupComponent implements OnInit {
  public navItems: CustomNavClass[] = [];
  public callChild: boolean = false;
  public selectedNavItems: CustomNavClass[] = [];
  public selectedNavs: CustomNavClass[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>,
              private intakeService: IntakeService,
              private navigationService: NavigationService,
              private apiManager: ApiManager,
              private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.intakeService.getPage('/dashboard/consumer-app/intake-app/navigation').then(v => {
      if (v != this.intakeService.noJson) {
        this.selectedNavs = JSON.parse(v);
      }
      this.navigationService.getNavs().toPromise().then(val => {
        this.navItems = val;
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  dataFromChild(e) {
    let nodes: NavFlatNodeClass[];
    nodes = e.selected;
    if (nodes) {
      nodes.forEach(v => {
        if (v.level == 1 && !v.expandable) {
          this.selectedNavItems.push(v.item);
        }
      })
    }
    console.log('selectedNavItems: ', this.selectedNavItems);

    let builtNavs: ConsumerNavClass[] = this.navigationService.buildCustomNavBar(this.selectedNavItems);
    builtNavs = _.sortBy(builtNavs, 'sort_order_nbr');
    let firstP;
    builtNavs.forEach(v => {
      if (!firstP && v.is_parent) {
        firstP = v.prnt_desc;
      }
    });
    let url: string = undefined;
    builtNavs.forEach(v => {
      if (!url && !v.is_parent && v.prnt_desc == firstP) {
        url = v.screen_defn;
      }
      v.disabled = 'N';
    });
    this.navigationService.setNavItems(builtNavs);
    this.navigationService.enableAll();
    this.specificImportData(builtNavs, url);
    // this.dialogRef.close({navs: builtNavs, url: url});
  }

  submitData() {
    this.callChild = true;
  }

  specificImportData(navs: ConsumerNavClass[], url) {
    let sendObj = [];
    let filteredObj = [];
    navs.map(nav => {
      if (!nav.is_parent && nav.nvgtn_cd != 'nav-in-navigation') {
        sendObj.push(nav.nvgtn_cd);
      }
    });

    this.selectedNavs.map(selNav => {
      if(sendObj.indexOf(selNav.nvgtn_cd) == -1 && !selNav.is_parent) {
        filteredObj.push(selNav.nvgtn_cd);
      }
    });

    this.apiManager.fetchData('specificImportData', undefined, ApiManager.POST, (filteredObj.length > 0) ? filteredObj : sendObj, undefined, undefined, [this.intakeService.intakeCaseId(), this.intakeService.intakeConsumerControlId()]).toPromise().then(() => {
      this.openSnackBar('Selected Page Data Import Successful', 'Close');
      this.dialogRef.close({navs: navs, url: url});
    }).catch(err => {
      console.log(err);
      this.openSnackBar('Selected Page Data Import Failed!', 'Close');
      this.dialogRef.close({navs: navs, url: url});
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
