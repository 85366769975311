// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'dev',
  isWso2: false,
  isWso2Ver: '',
  sdrWso2Ver: '',
  // below URL for Local
  // This is cloudfront link
  
  baseUrlDev:    'https://dtabbq7bhl64k.cloudfront.net/eeapi/',
  
  // baseUrlDev: 'https://dev2.cp.mdthink.maryland.gov/api/ee/dev2/',

  // baseUrlDev: 'http://10.28.12.150:8080/',
  // baseUrlDev: 'http://10.28.12.215:8080/',
  // below URL for NFT Environment
  // baseUrlDev: 'http://54.152.186.92:9080/eeapi/',
  // below URL for DEV1
  // baseUrlDev: 'http://internal-CMEEe1Dev1App-ELB-1879861302.us-east-1.elb.amazonaws.com/eeapi/',
  // below URL for DEV2
  // baseUrlDev: 'http://internal-cmeee1dev2app-elb-859166226.us-east-1.elb.amazonaws.com/eeapi/',

  // below URL for DEV2 - Food Stamps
  // baseUrlDev: 'https://stg3.eks.cbs.ee2.cp.mdthink.maryland.gov/eeapi/',
  // baseUrlDev: 'https://dev2.cbs.nwd.cp.mdthink.maryland.gov/eeapi/',
 // baseUrlDev: '/api/',

  // below URL for DEV4
  // baseUrlDev: 'http://internal-CMEEe1Dev4App-ELB-1375046792.us-east-1.elb.amazonaws.com/eeapi/',
  // HARIS URL
  // baseUrlsDev: 'https://dev4.cp.mdthink.maryland.gov/api/ee/dev4/',
  loginURL: 'http://internal-CMEEe1Dev4App-ELB-1375046792.us-east-1.elb.amazonaws.com/',
  logoutURL: 'https://dev4.access.mymdthink.maryland.gov/mdtsso/oauth2/realms/root/realms/mymdthink/connect/endSession',
  logoutMdthink: 'https://dev4.access.mymdthink.maryland.gov/mdtsso/json/realms/root/realms/mymdthink/sessions/?_action=logout',
  logoutMhbee:'https://dev-mhbe-securesso-ak.marylandhealthconnection.gov/mdtsso/oauth2/realms/root/realms/mhcexternal/connect/endSession?',
  sdrUrl: 'api/',
  apiLookupUrl: 'assets/mock-data/lookups.json',
  apiHelpTextUrl: 'assets/mock-data/HumanServices.json',
  sessionUrl: 'https://devlogin.mora.mdthink.maryland.gov/openam/identity/',
  mockService: false,
  timeOut: 14,
  addForgerockHeaders: true,
  requestAccess: 'https://eng.identity.mdthink.maryland.gov/identityiq/consumerUi.jsp',
  helpDoc: 'https://stag.ee.mdthink.maryland.gov/documents/public/',
  showIC: true,
  myDhrUrl: 'https://dev1.eemydhr.mymdthink.maryland.gov/',
  cpHomePage: '',
  sessionLoginUrl: 'https://dev2.portal.mymdthink.maryland.gov/cp/',
  myDhrTokenUrl: 'https://dev1.eemydhr.mymdthink.maryland.gov/dashboardClient/#/redirect?tokenid={token}&email={email}',
  enableAutoRedirectionToMyDHR: true,
  enableRIDP: true,
  cms: 'https://stg2.eks.csa.mymdthink.maryland.gov/csa/',
  uid:  'systemUser',
  uniqueId: 'mydhr30bf2179ad6f4d50837443ab3',
  role: 'cn=CP_PUBLIC', //'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=CP_NH_STAFF|cn=CP_PUBLIC',
  accessToken: 'P6M-Dwd90ihYka6hRZiAm7L0cMez9KyPbOIkTuR37GtnIR3uhbx80NWo68rZQJMTDZTq4-hR8NwmNlLSLZXF6g'
 
};
