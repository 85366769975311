import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})

export class PrintService {
  public printName: any;
  public printCtrlId: any;
  public printContent: any;
  public printTitle: any;

  print(): void {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${this.printTitle}</title>
          <style>
              table {
                font-family: montRegular !important;
                border-collapse: collapse;
                width: 100%;
              }
              td, th {
                border: 2px solid #dddddd;
                text-align: left;
                padding: 8px;
              }
          </style>
        </head>
        <body onload="window.print(); window.close()">
            Head of Household: ${this.printName}<br>
            Control Id: ${this.printCtrlId}<br><br>
            ${this.printContent}
        </body>
    </html>`
    );
    popupWin.document.close();
  }
}
