import { BaseBean } from './base-bean.class';
export class ProgramFinancial extends BaseBean{
    constructor(
        public netAssetStandard: number  = undefined,
        public preservedAmount: number  = undefined,
        public totalAssets: number  = undefined,
        public grossIncome: number  = undefined,
        public personNeedsAllowance: number  = undefined,
        public spouseFamilyAmount: number  = undefined,
        public maintenanceAllowance: number  = undefined,
        public nonCoveredMed: number  = undefined,
        public appliedIncomeAmount: number  = undefined,
        public grossUnearnedIncome: number  = undefined,
        public caseDeemedIncome: number  = undefined,
        public netUnearnedIncome: number  = undefined,
        public grossEarnedIncome: number  = undefined,
        public deduction: number  = undefined,
        public netEarnedIncome: number  = undefined,
        public netIncome: number  = undefined,
        public netIncomeStandard: number  = undefined,
        public excess: number  = undefined,
        public spenddownAmount: number  = undefined,
        public medicalExpenseAmount: number  = undefined,
        public netSpenddownAmount: number  = undefined,
        public costOfCare: number  = undefined,
        public totalInsurancePolicy: number  = undefined,
        public totalLiquidAssets: number  = undefined,
        public totalOtherProperties: number  = undefined,
        public totalRealProperties: number  = undefined,
        public totalVehicularAssets: number  = undefined
    ){
        super()
    }
}
