import { BaseBean } from './base-bean.class';
export class ScreeningSteps extends BaseBean{
    constructor(
        public initiateScreeningComplete: boolean = false,
        public registrationRequired: boolean = false,
        public registrationComplete: boolean = false,
        public verificationComplete: boolean = false,
        public householdInformationRequired: boolean = false,
        public householdInformationComplete: boolean = false,
        public contactInformationRequired: boolean = false,
        public contactInformationComplete: boolean = false,
        public householdCircumstancesRequired: boolean = false,
        public householdCircumstancesComplete: boolean = false,
        public recommendedProgramsRequired: boolean = false,
        public recommendedProgramsComplete: boolean = false,
        public assitanceRequiredFormPrinted: boolean = false,
        public screeningDispositionRequired: boolean = false,
        public screeningDispositionComplete: boolean = false,
        public trialBudgetComplete: boolean = false,
        public screeningComplete: boolean = false
    ) {
        super();
    }
}
