import { BaseBean } from './base-bean.class';
import { ApiMessage } from './api-message.class';

export class Verification extends BaseBean {
    constructor(
        public retryCount: number = undefined,
        public messages: ApiMessage[] = []
    ) {
        super();
    }
}
