import { BenefitsCase } from '../model/api/benefits-case.class';
import { DummyFinalizeMedicalEligibility } from '../model/custom/dummy-finalize-medical-eligibility-class';
import { environment } from '../../environments/environment';
import { MapToFile } from '../model/custom/map-to-file.class';
import { FileList } from '../model/custom/file-list.class';
import { Injectable } from '@angular/core';
import { RequestOptions, Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { KeyValue } from '../model/api/key-value.class';
import { DropDown } from '../model/api/drop-down.class';
import { HouseholdMember } from '../model/api/household-member.class';
import { LookupContainerModel } from '../model/api/lookup-container-model.class';
import { HelpText } from '../model/api/help-text.class';
import { ContactInformation } from '../model/api/contact-information.class';
import { EmergencyAssistance } from '../model/api/emergency-assistance.class';
import { ProgramName } from '../model/api/program-name.class';
import { Employer } from '../model/api/employer.class';
import { Signature } from '../model/api/signature.class'
import { AdditionalInformation } from '../model/api/additional-information.class';
import { AbsentParent } from '../model/api/absent-parent.class';
import { Address } from '../model/api/address.class';
import { Eligibility } from '../model/api/eligibility.class'
import { PhoneNumber } from '../model/api/phone-number.class';
import { Program } from '../model/api/program.class';
import { HumanServices } from '../model/api/human-services.class';
import { TrialBudgetRequest } from '../model/api/trial-budget-request.class';
import { AuthorizedRepresentative } from '../model/api/authorized-representative.class';
import { Vendor } from '../model/api/vendor.class';
import { DataShareService } from './data-share.service';
import { TrialBudgetClient } from '../model/api/trial-budget-client.class';
import { SchoolInformation } from '../model/api/school-information.class';
import { StudentInformation } from '../model/api/student-information.class';
import { ClientVerification } from '../model/api/client-verification.class';
import { Institution } from '../model/api/institution.class';
import { Asset } from '../model/api/asset.class';
import { ScheduleInterview } from '../model/api/schedule-interview.class';
import { InsurancePolicyDetails } from '../model/api/insurance-policy-details.class';
import { ProgramsSelected } from '../model/api/programs-selected.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ConstantsService {
  langSelected: any;
  static changedFields: Set<String> = new Set<String>();
  static readonly selections = [
    { 'key': '1', 'value': 'Cash Assistance', 'selected': false },
    { 'key': '2229', 'value': 'Food Supplement', 'selected': false },
    { 'key': '2228', 'value': 'Medical Assistance', 'selected': false },
    { 'key': '2230', 'value': 'Emergency Assistance', 'selected': false },
    { 'key': '7', 'value': 'Individual and Family Grants', 'selected': false },
    { 'key': '8', 'value': 'Repatriated Citizen Assistance', 'selected': false }
  ];
  static showTooltip: boolean;
  static goToHomePage: boolean = true;
  static loaderFlag: boolean = false;

  // Deployment Environments Random, LTC, EA
  static isIB5 = false;
  static readonly deployment_LTC: string = '/LTC';
  static deployment_EA = '/EA';
  static deployment_Random = '/Random';
  static deploymentMode: string = ConstantsService.deployment_Random;
  // Random, LTC, EA
  static contactInfoData: AuthorizedRepresentative;
  static finalizeServiceModel: DummyFinalizeMedicalEligibility[];
  static showClearanceToolTips = true;
  static isEAInFinalize = true;
  static eduacationlDetail: SchoolInformation;
  static readonly programs = 'programs';
  static readonly members = 'members';
  staticPage: String;
  // public headerText = 'WELCOME TO';
  // public subHeader = 'myMDTHINK CONSUMER PORTAL';
  public headerText = 'Consumer Dashboard';
  public subHeader;

  static readonly authRepRelation = [
    'HOH/Self',
    'Child Who Is Parent',
    'Natural or Adopted Child',
    'Other Related Adult',
    'Stepchild',
    'Other Unrelated Adult',
    'Spousal Parent',
    'Other Unrelated Child',
    'Non-Parent Spouse',
    'First Cousin',
    'Other Parent',
    'Niece/Nephew',
    'Grand Great Grandchild',
    'Aunt/Uncle',
    'Sibling',
    'Half Sibling',
    'Step Sibling',
    'Child/Parent treat as Child',
  ]

  // DIRTYSTATUS FLAG VALUE
  static readonly DIRTY_INSERT = 'insert';
  static readonly DIRTY_UPDATE = 'update';
  static readonly DIRTY_DELETE = 'delete';


  static standardizedAddress: Address[] = [];
  // STATIC MESSAGES
  static readonly lastNameRequired = 'Last Name is required';
  static readonly firstNameRequired = 'First Name is required';
  static readonly ssnRequired = 'SSN Number Required';
  static readonly invalidSSN = 'Please enter valid SSN in format XXX-XX-XXXX';

  // REGEX's
  static readonly ssnPattern = '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$';
  static readonly individualIdPattern = '^I\\w{15}$';
  static readonly caseIdPattern = '^C\\w{15}$';
  // LOOKUPS

  static readonly PrimaryLanguage = 'PrimaryLanguage';
  static readonly HearingImpaired = 'HearingImpaired';
  static readonly States = 'States';
  static readonly County = 'County';
  static readonly CommunicationMode = 'CommunicationMode';
  static readonly ContactTime = 'ContactTime';
  static readonly VerificationName = 'VerificationName';
  // contact info dropdowns

  static readonly EarnedIncomeType = 'EarnedIncomeType';
  static readonly UnearnedIncomeType = 'UnearnedIncomeType';
  static readonly AssetType = 'AssetType';
  static readonly AssetTypes = 'AssetTypes';
  // household circumstances

  static readonly MedCoverageGroup = 'MedCoverageGroup';
  static readonly ProgramCode = 'ProgramCode';
  static readonly ProgramTypeMA = 'ProgramTypeMA';
  static readonly ProgramTypePA = 'ProgramTypePA';
  static readonly ProgramTypeFS = 'ProgramTypeFS';
  static readonly ProgramTypeEA = 'ProgramTypeEA';
  static readonly ProgramTypeAF = 'ProgramTypeAF';
  static readonly ProgramTypeGA = 'ProgramTypeGA';
  static readonly ProgramTypeIF = 'ProgramTypeIF';
  static readonly ProgramTypeRP = 'ProgramTypeRP';
  static readonly ProgramTypeRF = 'ProgramTypeRF';

  static readonly MedicalAssistance = 'MedicalAssistance';
  // recommended programs

  static readonly Suffix = 'Suffix';
  static readonly MartialStatus = 'MartialStatus';
  static readonly SsnVerificationOption = 'SsnVerificationOption';
  static readonly DobVerificationOption = 'DobVerificationOption';
  static readonly RelationshipType = 'RelationshipType';
  static readonly Relationship = 'Relationship';
  static readonly RepresentativeType = 'RepresentativeType';
  static readonly Sex = 'Sex';
  static readonly Gender = 'Gender';
  static readonly State = 'State';
  static readonly Race = 'Race';
  static readonly SsnReferral = 'SsnReferral';
  static readonly USCISSTATUS = 'USCISSTATUS';
  static readonly CitizenshipStatus = 'CitizenshipStatus';
  static readonly ImmigrationDocumentType = 'ImmigrationDocumentType';
  static readonly YesNo = 'YesNo';
  static readonly PPIGroup = 'PPIGroup';
  static readonly LiquidAssetsType = 'LiquidAssetsType';
  static readonly Verification = 'Verification';
  static readonly LivingArrangement = 'LivingArrangement';
  static readonly StrikerStatus = 'StrikerStatus';
  static readonly PenaltyOrigin = 'PenaltyOrigin';
  static readonly PenaltyDecision = 'PenaltyDecision';
  static readonly StatusReason = 'StatusReason';
  static readonly StatewideFipGroup = 'StatewideFipGroup';
  static readonly VoterRegisteration = 'VoterRegisteration';
  static readonly DisabilityType = 'Disability/Incapacity';
  static readonly DisabilityApprovalSource = 'Disability/IncapacityApprovalSource';
  static readonly UtilityExpense = 'UtilityExpense';
  static readonly ShelterExpense = 'ShelterExpense';
  static readonly GAType = 'GAType';
  static readonly MedicalReviewRecieved = 'MedicalReviewReceived(GA)';
  static readonly TreatmentParticipation = 'TreatmentParticipation';
  static readonly TreatmentParticipationVerification = 'TreatmentParticipationVerification';
  static readonly HealthInsurance = 'HeathInsturance';
  static readonly TDAPType = 'TDAPType';
  static readonly PremiumOK = 'PremiumOK';
  static readonly Expenses = 'Expenses';
  static readonly GoodCauseReasons = 'GoodCauseReasons';
  static readonly GoodCauseStatus = 'GoodCauseStatus';
  static readonly RelationOfAbsentParentToChild = 'RelationOfAbsentParentToChild';
  static readonly UtilityExpenseMethods = 'UtilityExpenseMethods';
  static readonly Citizenship = 'Citizenship';
  static readonly CitizenshipVerification = 'CitizenshipVerification';
  static readonly MedicareCertificationCode = 'MedicareCertificationCode';
  static readonly StudentStatusCode = 'StudentStatusCode';
  static readonly StudentStatusVerification = 'StudentStatusVerifiction';
  static readonly EducationalLevel = 'EducationalLevel';
  static readonly IV_DCooperation = 'IV_DCooperation';
  static readonly IV_DCooperationIndicator = 'IV_DCooperationIndicator';
  static readonly HIGHESTGRADE = 'HIGHESTGRADE';
  static readonly GraduationDateVerification = 'GraduationDateVer';
  static readonly GOODSTANDINGVERIFICATION = 'GOODSTANDINGVERIFICATION';
  static readonly IVDCooperationIndicator = 'IVDCooperationIndicator';
  static readonly HMOType = 'HMOType';
  static readonly HCBWaiver = 'HCBWaiver';
  static readonly PhoneType = 'PhoneType';
  static readonly ServiceProvided = 'ServiceProvided';
  static readonly TransferOfAssetType = 'TransferOfAssetType';
  static readonly LevelAuth = 'LevelAuth';
  static readonly RefugeeResettlementAgency = 'RefugeeResettlementAgency';
  static readonly INSNumberVerif = 'INSNumberVerif';
  static readonly CountryOfOrigin = 'CountryOfOrigin';
  static readonly SponsoredAlienIndicator = 'SponsoredAlienIndicator';
  static readonly INSStatusVerificationCodes = 'INSStatusVerificationCodes';
  static readonly INSStatus = 'INSStatus';
  static readonly NewlyLegalStatus = 'NewlyLegalStatus';
  static readonly EarnedIncomeExpense = 'EarnedIncomeExpense';
  static readonly IncomeVerification = 'IncomeVerification';

  // Institutional history
  static readonly VendorType = 'VendorType';
  static readonly SpecialtyCodes = 'SpecialtyCodes';

  static readonly RelationshipTo = 'RelationshipTo';
  static readonly VeteranStatus = 'VeteranStatus';
  static readonly VeteranBranch = 'VeteranBranch';
  static readonly PayeeDropDown = 'PayeeDropDown';
  static readonly RecepientDropDown = 'RecepientDropDown';
  static readonly FrequencyDropDown = 'FrequencyDropDown';
  static readonly ChildrenCovered = 'ChildrenCovered';
  static readonly HairColor = 'HairColor';
  static readonly EyeColor = 'EyeColor';
  static readonly MarageStatus = 'MarageStatus';
  static readonly OtherCircumstances = 'OtherCircumstances';      // Household Circumstances

  // EA lookups
  static readonly EmergencyType = 'EmergencyType';
  static readonly EmergencyTypeVerification = 'EmergencyTypeVerification';
  static readonly NeedType = 'NeedType';
  static readonly NeedTypeVerification = 'NeedTypeVerification';
  static readonly Eligibility = 'Eligibility';
  static readonly Reasons = 'Reasons';
  static readonly InsuranceDropped = 'InsuranceDropped';
  static readonly ReasonforTansfer = 'ReasonforTansfer';
  static readonly UndueHardshipReasons = 'UndueHardshipReasons';
  static readonly FMVVerification = 'FMVVerification';
  static readonly TransfereeRelationship = 'TransfereeRelationship';
  static readonly Options = 'Options';
  static readonly PolicySource = 'PolicySource';
  static readonly AddAssetsAssetTypeParent = 'AddAssetsAssetTypeParent';
  static readonly LiquidAssetType = 'LiquidAssetType';
  static readonly Insurance = 'Insurance';
  static readonly VehicleType = 'VehicleType';
  static readonly VehicleFairMarketValueVerification = 'VehicleFairMarketValueVerification';
  static readonly RealPropertyFMVVerification = 'RealPorpertyFMVVerification';
  static readonly RealPropertyEncumbranceVerification = 'RealPropertyEncumbranceVerification';
  static readonly LiquidAssetVerification = 'LiquidAssetVerification';
  static readonly VehicleEncumbranceVerification = 'VehicleEncumbranceVerification';
  static readonly AddAssetsAssetTypeRealEstateProperties = 'AddAssetsAssetTypeRealEstateProperties';
  static readonly PropertyType = 'PropertyType()';
  static readonly AddAssetsAssetTypeTransferOfAssets = 'AddAssetsAssetTypeTransferOfAssets';
  static readonly VehicleUse = 'VehicleUse';
  static readonly Frequency = 'Frequency';
  static readonly AppliedUnearnedIncomeType = 'AppliedUnearnedIncomeType';
  static readonly AppliedUnearnedIcomeStatus = 'AppliedUnearnedIcomeStatus';
  static readonly Vendors = 'Vendors';
  static readonly CompletionStatus = 'CompletionStatus';
  static readonly RedeterminationMethod = 'RedeterminationMethod';
  static readonly Institution = 'Institution';
  static readonly Ethnicity = 'Ethnicity';
  static readonly Deprivation = 'Deprivation';
  static readonly ReasonCode = 'ReasonCode';
  static readonly LevelOfCare = 'LevelOfCare';
  static readonly FinancialResponsibility = 'FinancialResponsibility';
  static readonly DistrictOffice = 'DistrictOffice';
  static readonly Worker = "Worker";
  static readonly SignatureType = 'SignatureType';
  static readonly PresumptiveEligibility = 'PresumptiveEligibility';


  // OtherIncome lookups
  static readonly OtherIncomeType = 'OtherIncomeType';
  static readonly OtherIncomeSubType = 'OtherIncomeSubType';
  static readonly OtherIncomeCompensationPayments = 'OtherIncomeCompensationPayments';
  static readonly OtherIncomeSupportAlimony = 'OtherIncomeSupportAlimony';
  static readonly OtherIncomeContributionsInKind = 'OtherIncomeContributionsInKind';
  static readonly OtherIncomeEducationalAssistance = 'OtherIncomeEducationalAssistance';
  static readonly OtherUI = 'OtherUI';
  static readonly VariableInfrequente = 'VariableInfrequent';
  static readonly VolunteerRelated = 'VolunteerRelated';
  static readonly LumpSum = 'LumpSum';
  static readonly ProjectIndependence = 'ProjectIndependence';
  static readonly ShelterRelated = 'ShelterRelated';
  static readonly Relocation = 'Relocation';
  static readonly PublicAssistance = 'PublicAssistance';
  static readonly NeedsBasedAssistance = 'NeedsBasedAssistance';
  static readonly FosterCareAdoption = 'FosterCareAdoption';
  static readonly ReturnOnInvestments = 'ReturnOnInvestments';
  static readonly Loans = 'Loans';
  static readonly VendorPayments = 'VendorPayments';
  static readonly TaxFilingStatus = 'TaxFilingStatus';

  // trial budget dropdowns
  static readonly Type = 'Type';
  static readonly LivingArrangements = 'LivingArrangements';
  static readonly FoodStampsHHtype = 'FoodStampsHHtype';
  // static readonly MedCoverageGroup='MedCoverageGroup';
  static readonly Pregnant = 'Pregnant';
  static readonly Family = 'Family';
  static readonly Refugees = 'Refugees';
  static readonly ABorD = 'ABorD';
  static readonly MASO = 'MASO';
  static readonly FamilyLTC = 'FamilyLTC';
  static readonly MASOLTC = 'MASOLTC';
  // static readonly HCBWaiver='HCBWaiver';
  static readonly FCandSA = 'FCandSA';
  static readonly MiscellaneousM = 'MiscellaneousM';
  static readonly EIexpenseType = 'EIexpenseType';
  //static readonly EarningType='EarningType';
  static readonly EarningType = 'EarningType';
  static readonly UtilityMethod = 'UtilityMethod';
  static readonly MotorVehUse = 'MotorVehUse';
  static readonly EmployerInsuranceAvailabilityIndicator = 'EmployerInsuranceAvailabilityIndicator'
  static readonly MedBPayer = 'MedBPayer';

  //updated lookups according to DB
  static readonly InsuranceCashValue = 'InsuranceCashValue';
  static readonly PropertyTypeOther = 'PropertyType(Other)';
  // static readonly FMVVerification = 'FMVVerification';
  static readonly EncumbranceAmountVerification = 'EncumbranceAmountVerification';
  static readonly ReasonForTransfer = 'ReasonForTransfer';
  static readonly AmountRecievedVerification = 'AmountRecievedVerification';
  static readonly AUTransfereeRelationship = 'AU/TransfereeRelationship';
  static readonly UndueHardshipReason = 'UndueHardshipReason';
  static readonly ChildSupportExpenseVerification = 'ChildSupportExpenseVerification';
  static readonly FrequencyOfPayment = 'FrequencyOfPayment';
  static readonly DayCareExpense = 'DayCareExpense';
  static readonly DayCareExpenseVerification = 'DayCareExpenseVerification';
  static readonly PublicHousingVerificationt = 'PublicHousingVerificationt';
  static readonly ShelterUtilityAmountVerification = 'Shelter/UtilityAmountVerification';
  static readonly MedicalExpenseType = 'MedicalExpenseType';
  static readonly MedialExpenseVerification = 'MedialExpenseVerification';
  static readonly FrequencyValidVValue = 'FrequencyValidVValue';
  static readonly MedicareBAmountVerification = 'MedicareBAmountVerification';
  static readonly FairMarketValuationVerification = 'FairMarketValuationVerification';
  static readonly RealPropertyUse = 'RealPropertyUse';
  static readonly SSNNumberVerification = 'SSNNumberVerification';

  // static caseId = 'C452154';
  // static caseIdHouseholds = {};
  static readonly datePlaceHolder = 'MM/DD/YYYY';

  // static householdMembers3: HouseholdMember[] = [];
  static humanServiceModel: HumanServices;
  // static interviewServiceModel: HumanServices;

  //static ab: AbsentParent = new AbsentParent();
  // static ab: AbsentParent = new AbsentParent();

  static DOAssignBody = {};

  static systemCd = 'C';

  static childrenData: any = [{
    'individualId': 'ID001',
    'personName': {
      'firstName': 'Steve',
      'lastName': 'Smith'
    }
  },
  {
    'individualId': 'ID002',
    'personName': {
      'firstName': 'Adam',
      'lastName': 'Gilchrist'
    }
  }
  ]
  static interveiwHealthDisabilityInsurance: any = {
    'disabilityType': '',
    'tdapType': '',
    'tdapCounter': '',
    'disabilityApprovalSource': '',
    'disabilityApprovalDate': '',
    'disabilityBeginDate': '',
    'disabilityEndDate': '',
    'loanDate': '',
    'interimAssistanceFormSigningDate': '',
    'swCoopYN': '',
    'healthInsurance': '',
    'hmo': '',
    'liabilityCD': '',
    'managedCareYN': '',
    'insuranceDroppedYN': '',
    'willingToPayYN': '',
    'buyingYN': '',
    'tplYN': '',
    'entitledToMedicarePartAYN': '',
    'purchaseForChildYN': '',
    'medicalReviewReceived': '',
    'treatmentParticipation': '',
    'medicalCheckupDate': '',
    'treatmentParticipationVerification': '',
    'medicalCheckupDateVerification': '',
  }

  static absentParentList: AbsentParent[] = [];
  static absentParent: AbsentParent = new AbsentParent();
  static apAddMode = true;
  static empAddMode = true;
  static courtOrderAddMode = true;

  static abContactInfo: any = {
    'contactInformation': ContactInformation,
    'previousContactInformation': Address,
    'phoneNumber': PhoneNumber
  }

  static courtOrderList = [];

  static specialNeeds = [];

  static selectedCourtOrder: any = {
    'docketNumber': '',
    'frequency': '',
    'supportObligation': '',
    'children': []
  };

  static CourtOrderPayment: any = {
    'payee': '',
    'lastPaid': '',
    'amount': '',
    'supportChild': ''
  };

  static previousEmployers: Employer[] = [];
  static currentEmployers: Employer[] = [];
  static selectedPreviousEmployee: Employer = new Employer();
  static selectedCurrentEmployee: Employer = new Employer();
  static selectedPreviousIndex = 0;
  static selectedCurrentIndex = 0;
  static selectedCourtIndex = -1;
  static selectedAbsentParent: AbsentParent = new AbsentParent();
  static selectedAbsentParentIndex = -1;

  static contactInfo: any = {
    'additionalInformation': AdditionalInformation,
    'homeAddress': Address,
    'mailingAddress': Address,
    'phoneNumber': PhoneNumber,
    'altPhoneNumber': PhoneNumber,
    'sameMailingAddressYN': 'Y',
    'email': '',
    'preferredModeOfCommunication': '',
    'preferredContactTime': ''
  };
  static trialBudgetClient: TrialBudgetClient = null;
  static clientComponent: any = {
    'thirtyAndOneThirdYN': '',
    'thirtyYN': '',
    'dependentCareExpense': '',
    'earnedIncomes': '',
    'earnedIncomeExpenseType': '',
    'earnedIncomeExpense': ''
  }
  static signature: Signature = null;
  static signatureStatus: any = {
    'withdrawAppBeforeSigningYN': '',
    'withdrawAppAfterSigningYN': '',
    'lastNameAndRemarks': '',
    'email': '',
    // 'newSignature':'any',
    'email_assistance': ''
  };
  static scheduleInterviewStatus: any = {
    'worker': Worker,
    'inquiryDate': Date,// date
    'districtOffice': '',
    'emailAssistanceRequestFormYN': ''
  };

  static studentInformation: StudentInformation;
  static studentDetailsData: any = {
    'studentStatus': '',
    'studentStatusVerification': ClientVerification,
    'schools': SchoolInformation,
  };
  static schoolInformation: SchoolInformation[] = [];
  static schoolDetailsData: any = [{
    'educationLevel': '',
    'highestGrade': '',
    'schoolName': '',
    'schoolNumber': '',
    'graduationDate': '',
    'graduationDateVerification': ClientVerification,
    'goodStanding': '',
    'goodStandingVerification': ClientVerification,
    'schoolAddress': Address,
  }];
  static institutions: Institution;
  static institutionDetails: any = [{
    'currentInstitution': Institution,
    'previousInstitutions': Institution,
    'maintenanceAmountYN': '',
    'goodCauseClaimYN': '',
    'returnHomeIn6MonthsYN': '',
    'return6MonthsVerification': '',
    'waiverType': '',
    'waiverDate': '',
    'guardianPersonYN': '',
    'guardianPersonFeeAmount': '',
    'guardianPropertyYN': '',
    'guardianPropertyFeeAmount': ''
  }];
  static vendor: Vendor;
  static assets: Asset;
  static assetDetails: any = [{
    'recordId': '',
    'assetType': '',
    'assetDetailType': '',

    'amount': '',
    'accountNumber': '',
    'fdic': '',
    'institutionName': '',
    'faceAmount': '',
    'cashAmount': '',
    'policyNumber': '',
    'fareMarketValue': '',
    'encumberedAmount': '',
    'year': '',
    'make': '',
    'model': '',
    'licenseNumber': '',
    'vin': '',
    'registration': '',
    'state': '',
    'liceneseAgreementDate': '',
    'use': '',
    'tryingToSellYN': '',
    'address': '',
    'transferDate': '',
    'name': '',
    'reasonOfTransfer': '',
    'amountReceived': '',
    'transfereeRelationship': '',
    'undueHardshipReason': '',
    'discoveryDate': '',
    'effectiveBeginDate': '',
    'effectiveEndDate': '',
    'amountVerification': '',
    'insurancePolicyVerification': '',
    'fareMarketValueVerification': '',
    'encumberedAmountVerification': '',
    'amountReceivedVerification': '',
    'addressLine1': '',
    'addressLine2': '',
    'city': '',
    'zipcode': '',
    // to be changed when api is ready
    'institutionVerification': '',
    'ownedByHouseholdMember': '',
    'accountStartDate': '',
    'accountEndDate': '',
    'member': '',
    'deemorType': '',
    'otherAssetName': ''
  }];
  static programName: ProgramName = null;
  static programNameSelect: any = {
    'programName': ''
  }
  static additionalInformation: AdditionalInformation = null;
  static contactInformation: ContactInformation = null;


  static emergencyAssistanceIndex: number;
  static eaAddMode = true;
  /* static emergencyAssistance: any[] = [{
     'eaType': '',
     'eaApplicationDate': '',
     'eaNeeds': []
   }];*/

  static emergencyAssistance: EmergencyAssistance[] = [];
  static headerTitle: String = 'Screening: Initiate Screening';
  static interviewHeaderTitle = 'Interview: Initiate Interview';
  static headerClientIdTitle: String = '';
  static headerClientIdTitle2: String = '';
  static _navName = {
    SCREENING_MODULE: [
      'initStatus', 'clientStatus', 'contactStatus', 'programStatus',
      'houseHoldStatus', 'recommendedStatus', 'screeningStatus'
    ],
    INTERVIEW_MODULE: [
      'intStatus', 'progStatus', 'contactInfoStatus', 'houseStatus',
      'financialStatus', 'deemedStatus', 'eaStatus', 'ltcStatus',
      'interview_admin_info', 'interview_errors_summary'
    ],
    FINALIZE_MODULE: [
      'intStatus', 'benefitsSummary', 'medElig', 'EAElig', 'FSElig',
      'cashMAElig', 'finalize'
    ],
    TRIAL_BUDGET_MODULE: [
      'trialStatus'
    ],
    CASE_NARRATIVE_MODULE: [
      'caseNarrative'
    ]
  };
  static readonly householdEmptyModel = {
    'householdMemberId': undefined,
    'individualId': '',
    'headOfHouseholdYN': '',
    'relationshipCd': '',
    'altNames': [],
    'additionalSsns': [],
    'pregnantYN': '',
    'individualDetails': {
      'pregnancy': {
        'pregnancyDueDate': null
      }
    },
    'aliasClientIds': [],
    'memberClearanceId': undefined,
    'memberClearedYN': '',
    'memberEditableYN': '',
    'cases': [],
    'verification': {
      'ssnVerificationModel': {
        'verificationDocumentCd': '',
        'verificationRequestedYN': '',
        'docNames': new Map<number, string>(),
        'verificationType': '0',
        'verifiedYN': '',
        'dueDate': undefined,
        // 'verificationFileList': [],
        'retryCount': undefined,
        'messages': []
      }, 'dobVerificationModel': {
        'verificationDocumentCd': '',
        // 'verificationFileList': [],
        'verificationRequestedYN': '',
        'docNames': new Map<number, string>(),
        'verificationType': '0',
        'verifiedYN': '',
        'dueDate': undefined,
        'retryCount': undefined,
        'messages': []
      }
    }, 'hasSsnYN': '',
    'ssnReferral': '',
    'personName': {
      'firstName': '',
      'lastName': '',
      'middleName': '',
      'type': '',
      'suffix': ''
    },
    'dob': undefined,
    'ssn':
      {
        'ssnPart1': undefined,
        'ssnPart2': undefined,
        'ssnPart3': undefined
      },
    'gender': '',
    'race': {
      'primaryRace': '',
      'otherRaces': []
    },
    'deletedYN': '',
    'eligibility': {},
  };

  static clearanceSearchModel: HouseholdMember = JSON.parse(JSON.stringify(ConstantsService.householdEmptyModel));
  static readonly contactInformationEmptyModel = {
    'homeAddress': {
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'county': '',
      'district': '',
      'zipcode': '',
      'homeAddressYN': 'N'
    },
    'mailingAddress': {
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'county': '',
      'district': '',
      'homeAddressYN': 'N',
      'zipcode': ''
    },
    'sameMailingAddressYN': 'Y',
    'phoneNumber': {
      'phoneNumber': '',
      'phoneNumberType': '',
      'phoneNumberExt': ''
    },
    'altPhoneNumber': {
      'phoneNumber': '',
      'phoneNumberType': '',
      'phoneNumberExt': ''
    },
    'email': '',
    'preferredModeOfCommunication': '',
    'preferredContactTime': ''
  };
  static additionalInformationEmptyModel = {
    'preferredLanguage': 'E',
    'otherLanguage': null,
    'interpreterNeededYN': 'N',
    'visuallyImparedYN': 'N',
    'hearingImparedYN': 'N',
    'hearingImparedType': null
  };

  // static trailBudgetData: any = {
  //   'livingArrangement': '',
  //   'householdMemberSize': '',
  //   'program': '',
  //   'maOrFoodHHType': '',
  //   'subType': '',
  //   'irsDependents': '',
  //   'clients': '',
  //   'totalEarnedIncome': '',
  //   'deemarEarnedIncome': '', // number
  //   'deemarEarnedIncomeExpense': '', // number
  //   'unearnedIncomes': '',
  //   'spouseUnearnedIncome': '', // number
  //   'deemarUnearnedIncome': '', // number
  //   'rentMortgageExpense': '', // number
  //   'utilityExpense': '', // number
  //   'medicalExpense': '', // number
  //   'tcDeductionExpense': '', // number
  //   'utilityMethodType': '',
  //   'assets': [],
  //   'vehicularAssets': [],
  // };

  // beg recommended program
  static appDate: string;
  static foodStampYN: boolean;
  static eligibility: Program[] = [
    new Program(
      1,
      'Medical Assistance',
      'Aged',
      'L98 ABD LTC MN',
      '',
      'l',
      '', // selectedYN
      'Y', // requestedYN,
      '',
      'Not Applicable'),
  ];
  static requestedPrograms: Program[] = [];

  private houseHoldModel: HouseholdMember = new HouseholdMember();

  // Header Titles
  headTitle = {
    'SCREENING_MODULE': {
      'initStatus': 'Initiate Screening',
      'clientStatus': 'Customer Registration',
      'contactStatus': 'Contact Information',
      'houseHoldStatus': 'Household Circumstances',
      'recommendedStatus': 'Recommended Programs',
      'screeningStatus': 'Screening Disposition'
    },
    // Interview
    'INTERVIEW_MODULE': {
      'intStatus': 'Initiate Interview',
      'progStatus': 'Program Status',
      'contactInfoStatus': 'Contact Information',
      'houseStatus': 'Household Information',
      'financialStatus': 'Financial Information',
      // 'deemedStatus': 'Deemed Financial Info',
      'eaStatus': 'EA Information',
      'interview_admin_info': 'Administrative Information',
      'interview_errors_summary': 'Summary',
      'individual_details': 'Individual Details',
      'citizenship': 'Citizenship/Immigration',
      'studentInfo': 'Student Information',
      'healthAndDisability': 'Health/Disability',
      'institution': 'Institution Information',
      'assets': 'Assets',
      'earnedIncome': 'Earned Income',
      'unearnedIncome': 'Unearned Income',
      'dependentCareExpenses': 'Dependent Care Expenses',
      'shelterExpenses': 'Shelter Expenses',
      'specialNeeds': 'Special Needs',
      'medicalExpense': 'Medical Expense',
      'demographics': 'Demographics',
      'AbsentParent': 'Absent Parent',
      'absentParentContactInfo': 'Contact Information',
      'absentParentEmploymentHistory': 'Employment History',
      'addInsurancePolicy': 'Add Insurance Policy',
      'courtOrder': 'Court Order',
      'educationalDetails': 'Educational Details',
      'errors': 'Errors',
      'unearnedIncomeApplications': 'Unearned Income Applications',
    },
    // Finalize
    'FINALIZE_MODULE': {
      'intStatus': 'Initiate Finalization',
      'benefitsSummary': 'Benefits Summary'
    },
    // Trial Budget
    'TRIAL_BUDGET_MODULE': {
      'trialStatus': '',
      'trialBudget': 'Trial Budget'
    },
    // Case Narrative
    'CASE_NARRATIVE_MODULE': {
      'initStatus': 'Initiate Case Narrative',
      'caseNarrative': 'Case Narrative'
    },
    //Inquiry
    'INQUIRY_MODULE': {
      'initStatus': ' ',
      'customerSearch': 'Customer Search',
      'caseSearch': 'Case Search'
    }
  }

  // Left Navigation elements
  navigation = {
    'trialBudget': [],
    'screening': [
      {
        'id': 'initStatus',
        'navTitle': 'Initiate Screening',
        'headerTitle': 'Initiate Screening',
        'routerPath': '/dashboard/screening/initiateScreening',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'clientStatus',
        'navTitle': 'Customer Registration',
        'headerTitle': 'Customer Registration',
        'routerPath': '/dashboard/screening/customerRegisterationAddHouseholdMembersComponent',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'contactStatus',
        'navTitle': 'Contact Information',
        'headerTitle': 'Contact Information',
        'routerPath': '/dashboard/screening/contactInformationV1',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'houseHoldStatus',
        'navTitle': 'Household Circumstances',
        'headerTitle': 'Household Circumstances',
        'routerPath': '/dashboard/screening/householdCircumstances',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'recommendedStatus',
        'navTitle': 'Recommended Programs',
        'headerTitle': 'Recommended Programs',
        'routerPath': '/dashboard/screening/recommendedPrograms',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'screeningStatus',
        'navTitle': 'Screening Disposition',
        'headerTitle': 'Screening Disposition',
        'routerPath': '/dashboard/screening/screeningDisposition',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'interview': [
      {
        'id': 'intStatus',
        'navTitle': 'Initiate Interview',
        'headerTitle': 'Initiate Interview',
        'routerPath': '/dashboard/interview/initiateInterview',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'progStatus',
        'navTitle': 'Program Status',
        'headerTitle': 'Program Status',
        'routerPath': '/dashboard/interview/programStatus',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'contactInfoStatus',
        'navTitle': 'Contact Information',
        'headerTitle': 'Contact Information',
        'routerPath': '/dashboard/interview/contactInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'houseStatus',
        'navTitle': 'Household Information',
        'headerTitle': 'Household Information',
        'routerPath': '/dashboard/interview/householdInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'householdInfo',
        'children': [
          {
            'navTitle': 'Household Members',
            'scrollTo': 'householdMember',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Absent Parents',
            'scrollTo': 'absentParent',
            'clickable': true,
            'visible': true
          }
        ]
      },
      {
        'id': 'financialStatus',
        'navTitle': 'Financial Information',
        'headerTitle': 'Financial Information',
        'routerPath': '/dashboard/interview/financialInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'financialInfo',
        'children': [
          {
            'navTitle': 'Assets',
            'scrollTo': 'addAsset',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Earned Income',
            'scrollTo': 'addEarnedIncome',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Unearned Income',
            'scrollTo': 'addUnearnedIncome',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Unearned Income Applications',
            'scrollTo': 'addAppliedUnearnedIncome',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Dependent Care Expenses',
            'scrollTo': 'addDependentCareExpense',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Shelter Expenses',
            'scrollTo': 'addShelterExpense',
            'clickable': true,
            'visible': true
          },
          // {
          //   'navTitle': 'Special Needs',
          //   'scrollTo': 'addSpecialNeed',
          //   'clickable': true,
          //   'visible': true
          // },
          {
            'navTitle': 'Medical Expenses',
            'scrollTo': 'addMedicalExpense',
            'clickable': true,
            'visible': true
          }
        ]
      },
      // {
      //   'id': 'deemedStatus',
      //   'navTitle': 'Deemed Financial Info',
      //   'headerTitle': 'Deemed Financial Info',
      //   'routerPath': '/dashboard/interview/deemedFinancialInformation',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': 'deemedFinancialInfo',
      //   'children': [
      //     {
      //       'navTitle': 'Assets',
      //       'scrollTo': 'addAsset',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Earned Income',
      //       'scrollTo': 'addEarnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Unearned Income',
      //       'scrollTo': 'addUnearnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Dependent Care Expenses',
      //       'scrollTo': 'addDependentCareExpense',
      //       'clickable': true,
      //       'visible': true
      //     }
      //   ]
      // },
      {
        'id': 'eaStatus',
        'navTitle': 'EA Information',
        'headerTitle': 'EA Information',
        'routerPath': '/dashboard/interview/eaInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'interview_admin_info',
        'navTitle': 'Administrative Information',
        'headerTitle': 'Administrative Information',
        'routerPath': '/dashboard/interview/adminInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'interview_errors_summary',
        'navTitle': 'Summary',
        'headerTitle': 'Summary',
        'routerPath': '/dashboard/interview/errors',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
    ],
    'finalize': [
      {
        'id': 'intStatus',
        'navTitle': 'Initiate Finalization',
        'headerTitle': 'Initiate Finalization',
        'routerPath': '/dashboard/finalize/initiateFinalization',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': [
        ]
      },
      {
        'id': 'benefitsSummary',
        'navTitle': 'Benefits Summary',
        'headerTitle': 'Benefits Summary',
        'routerPath': '/dashboard/finalize/benefitsSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'medElig',
        'navTitle': 'Eligibility',
        'headerTitle': 'EA Eligibility',
        'routerPath': '',
        'clickable': false,
        'visible': true,
        'status': false,
        'childrenId': 'medEligMenu',
        'children': []
      }
    ]
  };

  unearnedSubtype: any = [];

  DD: Map<string, KeyValue[]>;
  // end recommended program

  // household circ temp data beg
  authorizedRepresentativeYN = true;
  absentParentYN = true;
  HHCssn = '';
  // household circ temp data end

  // AuthorizedRepresentative beg
  // authRepEdit this veriable keeps track of which authorize rep is being modified
  // if it is -1 it will push a new one
  authRepEdit: number;
  insPolicyEdit: number;
  selectInstitute: any = Vendor;
  authReps: AuthorizedRepresentative[];
  insPolicy: InsurancePolicyDetails[];
  // AuthorizedRepresentative end

  // Student information
  educationalDetailsEdit: number;          // Educational informatio
  education: SchoolInformation[];

  assetEdit: number;
  assetsData: Asset[];

  private lookUps: LookupContainerModel;

  // private trailBudgetUrl = 'http://localhost:4000/trailBudget/1';
  // private postTrailBudgetUrl = 'http://localhost:4000/trailBudget';

  selectedPrograms = { 'programs': [] };
  request: TrialBudgetRequest = new TrialBudgetRequest();

  lookupData: any;
  lookupDataInterview: any;
  lookupDataTrialBudget: any;
  lookupDataFinalize: any;
  localLookupData: LookupContainerModel;
  newApplicationData: any[] = [];

  // Hamburger Menu elements
  hamburgerMenu: any[] = [
    {
      'icon': 'fa-folder',
      'label': 'Application Process',
      'collapseID': 'collapseOne',
      'menu': [
        {
          'label': 'Inquiry',
          'moduleName': 'inquiryModule',
          'url': 'dashboard/inquiry/intiateInquiry'
        },
        {
          'label': 'Trial Budget/Eligibility',
          'moduleName': 'trialBudgetModule',
          'url': 'dashboard/trialBudget/trialBudget'
        },
        {
          'label': 'Screen',
          'moduleName': 'screeningModule',
          'url': 'dashboard/screening/initiateScreening'
        },
        {
          'label': 'Interview',
          'moduleName': 'interviewModule',
          'url': 'dashboard/interview/initiateInterview'
        },
        {
          'label': 'Finalize',
          'moduleName': 'finalizeModule',
          'url': 'dashboard/finalize/initiateFinalization'
        }
      ]
    },
    {
      'icon': 'fa-th-large',
      'label': 'Case Management',
      'collapseID': 'collapseTwo',
      'menu': [
        {
          'label': 'Benefit History',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Case Narrative',
          'moduleName': 'caseNarrativeModule',
          'url': 'dashboard/caseNarrative/initiateNarrative'
        },
        {
          'label': 'Change History',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Change Reporting',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Financial Management',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Mass Modifications',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Medicaid Spendown',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Redet/Reinstate/ Reactivate',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Reporting',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Retro Medicaid',
          'moduleName': '',
          'url': ''
        }
      ]
    },
    {
      'icon': 'fa-tasks',
      'label': 'Correspondence',
      'collapseID': 'collapseThree',
      'menu': [
        {
          'label': 'Email',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Letters',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Notices',
          'moduleName': '',
          'url': ''
        }
      ]
    },
    {
      'icon': 'fa-th-large',
      'label': 'Worker Management',
      'collapseID': 'collapseFour',
      'menu': [
        {
          'label': 'Worker Performance',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Worker Schedule',
          'moduleName': '',
          'url': ''
        }
      ]
    },
    {
      'icon': 'fa-th-large',
      'label': 'System Management',
      'collapseID': 'collapseFive',
      'menu': [
        {
          'label': 'Archive',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Master Client Index',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Print Requests',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Program Parameters',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Reference Data',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Security',
          'moduleName': '',
          'url': ''
        },
        {
          'label': 'Vendor Management',
          'moduleName': '',
          'url': ''
        }
      ]
    }
  ];
  scriptsLoaded: Boolean = false;
  caseNarrativeCurrentPage: number = 0;
  constructor(private mapToFile: MapToFile, 
    private httpClient: HttpClient,
    private shareService: DataShareService) {

    this.authRepEdit = -1;
    this.authReps = [];
    this.insPolicyEdit = -1;
    this.insPolicy = [];
    ConstantsService.humanServiceModel = new HumanServices();
    mapToFile.members = new Map<number, FileList>();


    // ConstantsService.humanServiceModel.benefitsCase.householdCircumstance = {
    //   'earningsAmount': null,
    //   'unearnedAmount': null,
    //   'assetAmount': null,
    //   'shelterAmount': null,
    //   'homelessYN': 'N',
    //   'disabledYN': 'N',
    //   'refugeeYN': 'N',
    //   'blindYN': 'N',
    //   'destituteMigrantYN': 'N',
    //   'shelterIncludesMealsYN': 'N',
    //   'protectiveLivingArrangementYN': 'N',
    //   'communityBasedWaiverYN': 'N',
    //   'medicarePartAEntitlementYN': 'N',
    //   'absentParentYN': 'N',
    //   'absentParent': {
    //     'personName': {
    //       'firstName': 'Sam',
    //       'middleName': 'Elizabeth',
    //       'lastName': 'Smith',
    //       'type': '',
    //       'suffix': 'Jr',
    //       'dirtyStatus': ''
    //     },
    //     'dob': new Date('1/1/2000'),
    //     'ssn': {
    //       'ssnPart1': '111',
    //       'ssnPart2': '22',
    //       'ssnPart3': '3333',
    //       'dirtyStatus': ''
    //     },
    //     'gender': 'M',
    //     'race': {
    //       'primaryRace': '',
    //       'otherRaces': [],
    //       'verificationDocumentCd': '',
    //       'verificationRequestedYN': '',
    //       'docNames': new Map<number, string>(),
    //       'verificationType': '',
    //       'verifiedYN': '',
    //       'dueDate': undefined,
    //       'retryCount': undefined,
    //       'messages': [],
    //       'dirtyStatus': ''
    //     },
    //     'deletedYN': null,
    //     'dirtyStatus': ''
    //   },
    //   'dirtyStatus': ''
    // }
  }

  addHouseholdMemberWithCaseId(newHouseholdMember: HouseholdMember, caseId: string) {
    /*if (!ConstantsService.caseIdHouseholds[caseId]) {
      ConstantsService.caseIdHouseholds[caseId] = new Set();
    }*/
    if ((ConstantsService.humanServiceModel.benefitsCase.householdMembers.some(x =>
      x.individualId === newHouseholdMember.individualId)) === false) {
      ConstantsService.humanServiceModel.benefitsCase.householdMembers.push(newHouseholdMember);
    } else {
      for (const member of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        if (member.individualId === newHouseholdMember.individualId) {
          member.relationshipCd = newHouseholdMember.relationshipCd;
        }
      }
    }
    // if ((ConstantsService.householdMembers3.some(x => x.individualId === newHouseholdMember.individualId)) === false) {
    //   ConstantsService.householdMembers3.push(newHouseholdMember);
    // }
    // ConstantsService.caseIdHouseholds[caseId].add(householdId);
  }

  getLanguage(): string {
    if (this.shareService.getDataFromMap('language')) {
      this.langSelected = this.shareService.getDataFromMap('language');
    } else {
      this.shareService.addDataToMap('language', 'en_us');
      this.langSelected = this.shareService.getDataFromMap('language');
    }
    return this.langSelected;
  }

  removeHouseHoldIdFromCaseId(householdId: any, caseId: string) {
    // if (ConstantsService.caseIdHouseholds[caseId] && ConstantsService.caseIdHouseholds[caseId].has(householdId)) {
    //   ConstantsService.caseIdHouseholds[caseId].delete(householdId);
    // }
    if (ConstantsService.humanServiceModel &&
      ConstantsService.humanServiceModel.benefitsCase) {
      if (ConstantsService.humanServiceModel.benefitsCase.householdMembers.some(x => x.individualId === householdId)) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers =
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.filter(function (obj) {
            return obj.individualId !== householdId;
          });
      }
    }
  }

  getHouseHoldWithHouseHoldId(localHouseholdMemberId: string): HouseholdMember {
    const newHousehold: HouseholdMember = ConstantsService.humanServiceModel.benefitsCase.householdMembers.find((householdMemberX) =>
      householdMemberX.individualId === localHouseholdMemberId
    );
    return newHousehold;
  }

  getCurrentHousehold(): any[] {
    if (this.shareService.getDataFromMap('currentModule') === this.getText('screeningModule')) {
      if (!ConstantsService.humanServiceModel) {
        ConstantsService.humanServiceModel = new HumanServices();
      }
      if (!ConstantsService.humanServiceModel.benefitsCase) {
        ConstantsService.humanServiceModel.benefitsCase = new BenefitsCase();
      }
      if (!ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = [];
      }
      {
        let values_2: any = [];
        for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (value && value.relationshipCd === 'SE') {
            values_2.push(value);
          }
        }
        for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (value && value.relationshipCd !== 'SE') {
            values_2.push(value);
          }
        }
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = values_2;
      }
      return ConstantsService.humanServiceModel.benefitsCase.householdMembers;
    }
    if (this.shareService.getDataFromMap('currentModule') === this.getText('interviewModule')) {
      if (!ConstantsService.humanServiceModel) {
        ConstantsService.humanServiceModel = new HumanServices();
      }
      if (!ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = [];
      }
      return ConstantsService.humanServiceModel.benefitsCase.householdMembers;
    }

  }

  addAuthRep(authRep: AuthorizedRepresentative) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    if (this.authRepEdit >= 0) {
      ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives[this.authRepEdit] = authRep;
      this.authRepEdit = -1;
    } else {
      ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.push(authRep);
    }
  }

  removeAuthRep(index: number) {
    ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.splice(index, 1);
  }

  getProgramSelection(programs: ProgramsSelected) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    // if (programs.map(prog =>{
    if (programs.programId === 0) {
      programs.dirtyStatus = 'insert';
    }
    else if (programs.programId !== 0 || undefined || null) {
      programs.dirtyStatus = 'update';
    }
    // delete prog.selectedYN;
    // }
    // ))
    ConstantsService.humanServiceModel.benefitsCase.selectedPrograms[0] = programs;

  }

  addInsPolicy(insPolicy: InsurancePolicyDetails) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
      if (this.insPolicyEdit >= 0) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers[i].insurancePolicies[this.insPolicyEdit] = insPolicy;
        this.insPolicyEdit = -1;
      } else {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers[i].insurancePolicies.push(insPolicy);
      }
    }
  }

  removeInsPolicy(index: number) {
    ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.splice(index, 1);
  }
  // educationalDetails in interview screens
  // addDetails(schoolDetails: SchoolInformation) {
  //   if (!ConstantsService.humanServiceModel) {
  //     ConstantsService.humanServiceModel = new HumanServices();
  //   }
  //   if (this.educationalDetailsEdit >= 0) {
  //
  //     let individualId: string;
  //     for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
  //       let hhmem = ConstantsService.humanServiceModel.benefitsCase.householdMembers[i];
  //       if (hhmem.individualId === individualId) {
  //         return hhmem.studentInformation;
  //       }
  //       hhmem.studentInformation.schools[this.educationalDetailsEdit] = schoolDetails;
  //     }
  //
  //     //  this.houseHoldModel.studentInformation.schools[this.educationalDetailsEdit]= schoolDetails;
  //     this.educationalDetailsEdit = -1;
  //   } else {
  //     this.houseHoldModel.studentInformation.schools.push(schoolDetails);
  //   }
  // }
  // deleteSchoolAddress(index: number) {
  //   this.houseHoldModel.studentInformation.schools.splice(index, 1);
  // }

  // Financial Assets
  addAsset(assetInformation: Asset) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    if (this.assetEdit >= 0) {
      let individualId: string;
      for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
        let hhmem = ConstantsService.humanServiceModel.benefitsCase.householdMembers[i];
        if (hhmem.individualId === individualId) {
          return hhmem.assets;
        }
        hhmem.assets[this.assetEdit] = assetInformation;
      }
      this.houseHoldModel.assets[this.assetEdit] = assetInformation;
      this.assetEdit = -1;
    }
    else {
      this.houseHoldModel.assets.push(assetInformation);
    }
  }
  deleteAsset(index: number) {
    this.houseHoldModel.assets.splice(index, 1);
  }

  getTrialBudgetData(): TrialBudgetRequest {
    let trialBudget = new TrialBudgetRequest();
    if (ConstantsService.humanServiceModel.trialBudget) {
      trialBudget = JSON.parse(JSON.
        stringify(ConstantsService.humanServiceModel.trialBudget)) as TrialBudgetRequest;
    }
    return trialBudget;
  }

  getText(key: string): string {
    return ConstantsService[key];
  }

  getConstant(key: string): any {
    return ConstantsService[key];
  }

  getDropDownFromLookup(val: string, checkServiceData: boolean = true): any {

    if (checkServiceData) {
      if (this.lookupData && this.lookupData.localizedLookups[val] && this.lookupData.localizedLookups[val].beans) {
        return this.lookupData.localizedLookups[val].beans;
      }
      else
        if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
          this.lookupDataInterview.localizedLookups[val].beans) {
          return this.lookupDataInterview.localizedLookups[val].beans;
        } else
      if (this.localLookupData && this.localLookupData.localizedLookups[val] && this.localLookupData.localizedLookups[val].beans) {
        return this.localLookupData.localizedLookups[val].beans;
      }
    }
    return [];
  }

  getDropdownValue(key: string, value: string): string {
    for (const entry of this.getDropDownFromLookup(key)) {
      if (entry.lookupBean.code == value) {
        return entry.lookupBean.longLabel;
      } else if (entry.lookupBean.shortLabel == value) {
        return entry.lookupBean.longLabel;
      }
    }
    return '';
  }

  getClientById(Id: string): HouseholdMember {
    if (Id) {
      if (ConstantsService.humanServiceModel.clientManagement) {
        for (const entry of ConstantsService.humanServiceModel.clientManagement.results) {
          if (entry.individualId == Id && this.shareService.getDataFromMap('memberEdit') != true) {
            return entry;
          }
        }
      }
      if (ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        for (const entry of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (entry.individualId == Id && this.shareService.getDataFromMap('memberEdit') === true) {
            return entry;
          }
        }
      }

    }
    return undefined;
  }

  getInterviewHouseholdById(Id: string): HouseholdMember {
    if (Id !== '') {
      for (const entry of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        if (entry.individualId === Id) {
          return entry;
        }
      }
    }
    return undefined;
  }

  getContactInformation(): ContactInformation {

    if (ConstantsService.contactInfo !== NaN) {
      return ConstantsService.contactInfo;
    }
    return undefined;
  }
  getClientInformation(): TrialBudgetClient {
    if (ConstantsService.clientComponent !== NaN) {
      return ConstantsService.clientComponent;
    }
    return undefined;
  }
  getProgramName(): string[] {
    if (ConstantsService.programNameSelect !== null) {
      return ConstantsService.programNameSelect;
    }
  }

  getSignature(): Signature {
    if (ConstantsService.signatureStatus !== NaN) {
      return ConstantsService.humanServiceModel.benefitsCase.interviewSignature;
    }
    return undefined;
  }

  getScheduleInterview(): ScheduleInterview {
    if (ConstantsService.scheduleInterviewStatus !== NaN) {
      return ConstantsService.humanServiceModel.benefitsCase.scheduleInterview;
    }
    return undefined;
  }
  getEmergencyAssistance() {
    return ConstantsService.emergencyAssistance;
  }

  getAdditionalInformationById(Id: string): AdditionalInformation {
    const tempId = Number(Id);
    if (tempId !== NaN) {
      return ConstantsService.additionalInformation;
    }
    return undefined;
  }

  // getStudentInformation(individualId: string): StudentInformation {
  //   for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
  //     let hhmem = ConstantsService.humanServiceModel.benefitsCase.householdMembers[i];
  //     if (hhmem.individualId === individualId) {
  //       return hhmem.studentInformation;
  //     }
  //   } return null;
  // }

  // getCurrentEmployment() {
  //   const employmentUrl = 'http://192.168.0.6:8080/api-web-1.0/application-process/interview/78/household/absent-parent/622/emphistory';
  //   console.log(this.http.get(employmentUrl));
  //   return this.http.get(employmentUrl);
  // }
  // getCourtOrder() {
  //   const courtOrderUrl = 'http://192.168.0.11:8080/api-web-1.0/application-process/interview/1/household/absent-parent/631/court-order';
  //   return this.http.get(courtOrderUrl);
  // }
  // postCurrentEmployment(currentEmployer) {
  //   const employmentUrl = 'http://192.168.0.11:8080/api-web-1.0/application-process/interview/988/household/absent-parent/622/emphistory';
  //   return this.http.post(employmentUrl, currentEmployer);
  // }

  getFormerEmployment() {
    const employmentUrl = 'http://192.168.0.3:8080/api-web-1.0/application-process/interview/988/household/absent-parent/1/emphistory';
    console.log(this.httpClient.get(employmentUrl));
    return this.httpClient.get(employmentUrl);
  }
  // getSchoolInformation(): SchoolInformation {
  //   if (ConstantsService.schoolDetailsData != NaN) {
  //     return this.houseHoldModel.studentInformation.schools[0];
  //   }
  // }
  getLookupData(requestLookup: string[]): Observable<DropDown[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let options = { headers: headers };

    return this.httpClient.post(environment.apiLookupUrl, requestLookup, options)
    .map((res: Response) => res.json());
  }

  generateArray(obj) {
    return Object.keys(obj).map((k) => { return { key: k, value: obj[k] } });
  }


  loadLookupData(requestLookup: string[]): Observable<Map<string, KeyValue[]>> {
    if (this.DD) {
      return Observable.of(this.DD);
    }

    let querystring = '';
    for (const key in requestLookup) {
      if (querystring === '') {
        querystring = '?lookupType=' + requestLookup[key];
      } else {
        querystring += '&lookupType=' + requestLookup[key];
      }
    }
    return this.httpClient.get(environment.apiLookupUrl + querystring)
      .map(this.extractLookupData)
      .catch(this.handleError)
  }

  private extractLookupData(response: Response): Map<string, KeyValue[]> {
    const body = response.json();
    const data = body.localizedLookups;
    if (data) {
      const keys: string[] = Object.keys(data); // get all the keys
      this.DD = new Map<string, KeyValue[]>();
      if (keys) {
        for (let i = 0; i < keys.length; i++) {
          const beansArray = data[keys[i]].beans;
          const optionsArray: Array<KeyValue> = new Array<KeyValue>();
          for (let j = 0; j < beansArray.length; j++) {
            optionsArray.push(new KeyValue(beansArray[j].lookupBean.code, beansArray[j].lookupBean.longLabel))
          }
          this.DD.set(keys[i], optionsArray)
        }
      }
      return this.DD;
    } else {
      return null;
    }
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  // *****************Load Lookup data - End********************* */


  // *****************Load Help Text - Start********************* */
  // loadHelpTextData(requestModuleName: string, requestSectionIDs: string[]): Observable<Map<string, HelpText[]>> {
  //   let querystring = '';
  //   if (querystring === '') {
  //     querystring = '?moduleName=' + requestModuleName;
  //   }

  //   for (const key in requestSectionIDs) {
  //     if (querystring === '') {
  //       querystring = '?sectionId=' + requestSectionIDs[key];
  //     } else {
  //       querystring += '&sectionId=' + requestSectionIDs[key];
  //     }
  //   }

  // return this.http.get(environment.apiHelpTextUrl + querystring)
  //   .map((res: Response) => this.extractHelpTextData(requestSectionIDs, res))
  //   .catch(this.handleError)
  // }

  private extractHelpTextData(sectionIDs: string[], response: Response) {
    const body = response.json();
    const data = body.helpTexts.helpTexts;
    if (data) {
      // let keys:string[] = Object.keys(data); //get all the keys//
      const helpTextMap: Map<string, HelpText[]> = new Map<string, HelpText[]>();
      if (sectionIDs) {
        for (let i = 0; i < sectionIDs.length; i++) {
          const sectionHelpTextArray = data[sectionIDs[i]];
          const helptextArray: Array<HelpText> = new Array<HelpText>();
          const keys: string[] = Object.keys(sectionHelpTextArray);
          for (let j = 0; j < sectionHelpTextArray.length; j++) {
            helptextArray.push(new HelpText(sectionHelpTextArray[keys[j]].id,
              sectionHelpTextArray[keys[j]].htmlHelpText,
              sectionHelpTextArray[keys[j]].associatedFieldYN, sectionHelpTextArray[keys[j]].associatedFieldId))
          }
          helpTextMap.set(sectionIDs[i], helptextArray)
        }
      }
      return helpTextMap;
    } else {
      return {}
    }
  }

  //************* Check Duplicate Values in an Array, return type boolean */
  duplicatesExists(arr) {
    let uniq = arr
      .map((name) => {
        return { count: 1, name: name }
      })
      .reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})
    var duplicates = Object.keys(uniq).filter((a) =>
      uniq[a] > 1)
    if (duplicates.length) {
      return true;
    } else {
      return false;
    }
  }
  // *****************Load Help Text - End********************** */

  subheaderTitle(title: string) {
    if (this.shareService.getDataFromMap('currentModule') === this.getText('screeningModule')) {
      ConstantsService.headerTitle = 'Screening: ' + title;
    }
    if (this.shareService.getDataFromMap('currentModule') === this.getText('interviewModule')) {
      ConstantsService.headerTitle = 'Interview: ' + title;
    }

    if (this.shareService.getDataFromMap('currentModule') === this.getText('finalizeModule')) {
      ConstantsService.headerTitle = 'Finalize: ' + title;
    }

    if (this.shareService.getDataFromMap('currentModule') === this.getText('trialBudgetModule')) {
      ConstantsService.headerTitle = 'Trial Budget';
    }

  }

  // getTrailBudget(): Observable<TrialBudgetRequest> {

  //   return this.http.get(this.trailBudgetUrl)
  //     .map(res => res.json())
  //     .catch((error: any) => Observable.throw(error.json().error || 'Server error'));

  // }

  setPrograms(programs) {
    this.selectedPrograms = programs;
  }

  getPrograms() {
    return this.selectedPrograms.programs;
  }

  getAbsentParentInfo() {
    return ConstantsService.absentParent;
  }

  getAbsentParentContactInfo() {
    return ConstantsService.abContactInfo;
  }

  getInterveiwHealthDisabilityInsurance() {
    return ConstantsService.interveiwHealthDisabilityInsurance;
  }

  getBenefitCase(caseId = 'C000000000022777') {
    this.httpClient.get('http://10.88.32.139:8080/eeapi/application-process/screening/' + caseId)
      // .map(res => res.json())
      .subscribe((res: any) => {
        ConstantsService.humanServiceModel.benefitsCase.contactInformation = res.benefitsCase.contactInformation;
        ConstantsService.humanServiceModel.benefitsCase.additionalInformation = res.benefitsCase.additionalInformation;
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = res.benefitsCase.householdMembers;
      },
        (err) => console.log(err));
    return ConstantsService.humanServiceModel.benefitsCase;
  }

  getContactInfo() {
    return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.contactInformation)) as ContactInformation;
  }
  // getClientInfo(){
  //    return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.trialBudgetClient)) as TrialBudgetClient;
  // }
  getAdditionalInfo() {
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.additionalInformation) {
      return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.additionalInformation)) as AdditionalInformation;
    }
  }
  updateContactInfo() {
    return this.httpClient.post('http://10.88.32.139:8080/eeapi/application-process/screening/C000000000022777/contact',
      ConstantsService.humanServiceModel.benefitsCase)
  }



  updateHouseHoldCirc() {
    return this.httpClient.post('http://10.88.32.139:8080/eeapi/application-process/screening/C000000000022777/household-circumstances',
      ConstantsService.humanServiceModel.benefitsCase)
  }

  getEligibility() {
    return JSON.parse(JSON.
      stringify(ConstantsService.humanServiceModel.benefitsCase.eligibility)) as Eligibility;
  }
  showContent(arr: string[]) {
    if (arr) {
      for (let str of arr) {
        if (ConstantsService.deployment_LTC === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
        if (ConstantsService.deployment_EA === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
        if (ConstantsService.deployment_Random === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
      }
    }
    return false;
  }
  getCourOrderPaymentInfo() {
    return ConstantsService.CourtOrderPayment;
  }

  getCoutOrderList() {
    return ConstantsService.courtOrderList;
  }

  getSelectedCourtOrder() {
    return ConstantsService.selectedCourtOrder;
  }
  getSelectedApIndex() {
    return ConstantsService.selectedAbsentParentIndex;
  }
  getCurrentEmployersInfo() {
    return ConstantsService.currentEmployers;
  }
  getFormerEmployersInfo() {
    return ConstantsService.previousEmployers;
  }
  getFormerIndex() {
    return ConstantsService.selectedPreviousIndex;
  }
  getCurrentIndex() {
    return ConstantsService.selectedCurrentIndex;
  }
  getSelectedFormerData() {
    return ConstantsService.selectedPreviousEmployee;
  }
  getSelectedApData() {
    return ConstantsService.selectedAbsentParent;
  }
  getSelectedCurrentData() {
    return ConstantsService.selectedCurrentEmployee;
  }
  getSelectedCourtIndex() {
    return ConstantsService.selectedCourtIndex;
  }


  setStaticPage(pageName) {
    this.staticPage = pageName;
  }

  getStaticPage() {
    return this.staticPage;
  }

  static getSpecialNeeds() {
    return ConstantsService.specialNeeds;
  }
}