import { BaseBean } from "./api/base-bean.class";
import {ClientVerification} from "./api/client-verification.class";

export class Medicare extends BaseBean {
    constructor(
        public recordId: number = undefined,
        public medicareTypeA: string = '',
        public medicareTypeB: string = '',
        public medicareTypeC: string = '',
        public medicareTypeD: string = '',
        public medicareTypeAStatus: string = '',
        public medicareTypeBStatus: string = '',
        public medicareTypeCStatus: string = '',
        public medicareTypeDStatus: string = '',
        // public verificationSourceCode: string = '',
        public medicareVerification: ClientVerification = new ClientVerification(),
        public medicareClaimVerification: ClientVerification = new ClientVerification(),
        public medicareApplicationVerification: ClientVerification = new ClientVerification(),
        public claimId:string = '',
        public premiumAmount:number = undefined,
        // public medicalExpenseId:number = undefined,
        // public medicalExpenseMasterId:number = undefined,
        // public medicalExpenseAmountId:number = undefined,
        public applicationNum: string = '',
        public appliedInd:string = '',
        public rcvdInd: string = ''

    ) {
        super();
    }
}
