import {Injectable} from '@angular/core';
import {ApiManager} from "./api-manager.service";
import {SessionStorageService} from "ngx-webstorage";
import {NavigationService} from "./navigation.service";
import {Router} from "@angular/router";
import {icGetClass} from "../model/api/InterimChange/ic-get.class";
import {hohMembersClass} from "../model/api/InterimChange/ic-hohmembers.class";
import {Address} from "../model/api/address.class";
import {LookupManagerService} from "./lookup-manager.service";
import {IntakeHohInfoClass} from "../model/api/JsonModel/IntakeModel/intake-hohInfo.class";
import {IntakeAuthrep} from "../model/api/JsonModel/IntakeModel/intake-authrep.class";
import {Observable} from "rxjs";
import {PersonName} from "../model/api/JsonModel/IntakeModel/intake-person-name.class"
import {SocialSecurityNumber} from "../model/api/JsonModel/IntakeModel/intake-social-security-number.class"
import {EventEmitterService} from "./event.emitter.service";
import {NavigationWizardService} from "./navigation-wizard.service";
import {LoaderService} from "./loader.service";
import {IntakeMedicareDetailsClass} from "../model/api/JsonModel/IntakeModel/intake-medicare-details.class";
import {StorageService} from "./storage.service";
import {saveAs} from 'file-saver/FileSaver';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class IntakeService {
  public readonly noJson = 'No Json Doc found';
  public readonly appType = 'appType';
  public readonly futureDate: any = '9999-12-31';
  public readonly currentDate: any = new Date().toISOString().split('T')[0];
  public readonly currentTime: any = new Date().toLocaleTimeString();
  public readonly maxDate = new Date();
  public readonly minDate = new Date('01/01/1900');
  private authReps: IntakeAuthrep[] = [];
  public listOfMedDetails: IntakeMedicareDetailsClass[] = [];
  resizeNav: boolean = false;
  hohIndID:any = "";
  frequencies: any[] = [];
  members: hohMembersClass[] = [];
  phoneMask = ['(', /[1-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  ssnPattern = '^^(?!666|000|123-45-6789|888-88-8888|9|773|774|775|776|777|778|779|772\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$';
  // ssnNewPattern = '^^(?!666|000|123456789|888888888|9|773|774|775|776|777|778|779|772\\d{2})\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}$';
  ssnNewPattern = /^^(?!(666|000|123456789|888888888|9|773|774|775|776|777|778|779|772))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
   disableNav: boolean = true;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private static extractData(res: Response) {
    return res || {};
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  constructor(private apiManager: ApiManager,
              private session: SessionStorageService,
              public storage: StorageService,
              private navigationService: NavigationService,
              private router: Router,
              private loader: LoaderService,
              private lookupService: LookupManagerService,
              private eventEmitter: EventEmitterService,
              private navigationWizard: NavigationWizardService) {
  }

  setFrequencies(): any[] {
    let obj = [];
    obj['AN'] = [{
      head: 'Annual Income',
      required: true,
      divideBy: 12
    }];
    obj['AC'] = [{
      head: 'Pay 1',
      required: true,
      divideBy: 1
    },
    {
      head: 'Pay 2',
      required: false,
      divideBy: 1
    }];
    obj['MO'] = [{
      head: 'Monthly Income',
      required: true,
      divideBy: 1
    }];
    obj['OT'] = [{
      head: 'One Time Only Income',
      required: true,
      divideBy: 1
    }];
    obj['ZZ'] = [{
      head: 'One Time Only Income',
      required: true,
      divideBy: 1
    }];
    obj['SN'] = [{
      head: 'Semi Annual Income',
      required: true,
      divideBy: 2
    }];
    obj['QU'] = [{
      head: 'Quarterly Income',
      required: true,
      divideBy: 3
    }];
    obj['BM'] = [{
      head: 'Bi-Monthly Income',
      required: true,
      divideBy: 2
    }];
    obj['SA'] = [{
      head: 'Semi-Annual Income',
      required: true,
      divideBy: 6
    }];
    obj['BW'] = [{
      head: 'Week 1&2 Income',
      required: true
    }, {
      head: 'Week 3&4 Income',
      required: true
    }, {
      head: 'Week 5 Income',
      required: false
    }];
    obj['WE'] = [{
      head: 'Week 1 Income',
      required: true
    }, {
      head: 'Week 2 Income',
      required: true
    }, {
      head: 'Week 3 Income',
      required: true
    }, {
      head: 'Week 4 Income',
      required: true
    }, {
      head: 'Week 5 Income',
      required: false
    }];

    return obj;
  }

  changeFreqIncomToExp(v) {
    let re = /income/gi;
    return v.replace(re, 'Expense');
  }

  public setMembers(members) {
    this.session.store('intakeMembers', members);
  }

  getMembers(): hohMembersClass[] {
    let hoh: hohMembersClass[] = this.session.retrieve('intakeMembers');
    if(hoh) {
      return hoh.length < 0 ? hoh : hoh.filter(v => {
        return v.dirtyStatus != 'DELETE';
      });
    } else return null;
  }

  downloadPdf(docCat, fileName) {
    this.loader.show();
    this.apiManager.fetchData(
      'downloadDoc',
      {},
      ApiManager.FILEDOWNLOADGET,
      undefined,
      undefined,
      undefined,
      [docCat, fileName]
    ).subscribe(res => {
      this.loader.hide();
      let blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  getMemberNameByIndividualId(id, fnOnly?: boolean, lnOnly?: boolean) {
    let name: string = '';
    if (this.getMembers() != null && this.getMembers().length > 0) {
      this.getMembers().map(ele => {
        if (ele.dummyIndividualId == id) {
          if(fnOnly) {
            name = ele.firstName;
          } else if(lnOnly) {
            name = ele.lastName;
          } else {
            name = ele.firstName + ' ' + ele.lastName + ((ele.suffix) ? (' ' + ele.suffix) : '');
          }
        }
      });
    }
    return name;
  }

  currencyFormat(num) {
    if (num == null || num == '') {
      return '';
    } else {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  getFullName(person: PersonName): string {
    let fullName = '';
    if (!person) {
      return fullName;
    }
    if (person.firstName) {
      fullName += person.firstName;
    }
    if (person.middleName) {
      fullName += ' ' + person.middleName;
    }
    if (person.lastName) {
      fullName += ' ' + person.lastName;
    }
    return fullName;
  }

  getFrequencies(type): any[] {
    if (this.frequencies.length == 0) {
      this.frequencies = this.setFrequencies();
    }
    return this.frequencies[type];
  }

  resizeNavBar(val?: boolean) {
    if (val) {
      this.resizeNav = val;
    } else {
      return this.resizeNav;
    }
  }

  consumerAppType(id?) {
    if (id) {
      this.session.store(this.appType, id);
    } else {
      return this.session.retrieve(this.appType);
    }
  }

  intakeConsumerControlId(id?) {
    if (id) {
      this.session.store('intakeConsumerControlId', id);
    } else {
      return this.session.retrieve('intakeConsumerControlId');
    }
  }

  setImportBR(bool?) {
      this.session.store('isImportBR', bool);
  }

  getImportBR() {
    return this.session.retrieve('isImportBR');
  }

  intakeWConsumerControlId(id?) {
    if (id) {
      this.session.store('intakeWConsumerControlId', id);
    } else {
      return this.session.retrieve('intakeWConsumerControlId');
    }
  }

  intakeCaseId(id?) {
    if (id) {
      this.session.store('intakeCaseId', id);
    } else {
      return this.session.retrieve('intakeCaseId');
    }
  }

  clearConsumerControlId() {
    this.session.store('intakeConsumerControlId', undefined);
    this.session.store('intakeWConsumerControlId', undefined);
    this.session.store('intakeCaseId', undefined);
  }

  clearMember(obj: IntakeHohInfoClass[], index?): Promise<any> {
    obj[0].controlId = this.intakeConsumerControlId();
    return this.apiManager.fetchData('clearanceA', undefined, ApiManager.POST, obj).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  newMember(obj): Promise<any> {
    return this.apiManager.fetchData('saveClient', undefined, ApiManager.POST, obj).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  newMemberForHoh(obj): Promise<any> {
    let controlId = [];
    controlId.push(parseInt(this.intakeConsumerControlId()));
    return this.apiManager.fetchData('saveClientForCP', undefined, ApiManager.POST, obj, undefined, undefined, controlId).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  newLogicClearanceForAllMembers(obj): Promise<any> {
    return this.apiManager.fetchData('newLogicMember', undefined, ApiManager.POST, obj, undefined, undefined, undefined).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  };

  newLogicClearanceEditForAllMembers(obj): Promise<any> {
    return this.apiManager.fetchData('newLogicEditMember', undefined, ApiManager.POST, obj, undefined, undefined, [this.intakeCaseId()]).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  insertMember(obj): Promise<any> {
    let params = [];
    let screen = this.navigationService.getScreenId('dashboard/consumer-app/intake-app/summary/homePage');
    params.push(this.intakeConsumerControlId());
    params.push(screen);
    return this.apiManager.fetchData('saveIntake', undefined, ApiManager.POST, obj, undefined, undefined, params).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  editMemberWithSSN(obj, caseId): Promise<any> {
    return this.apiManager.fetchData('editMemberWithSSN', undefined, ApiManager.PUT, obj, undefined, undefined, [caseId]).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  getConsumerDetailsFromHeader(): Promise<any> {
    return this.apiManager.fetchData('consumerDetailsFromHeader', undefined, ApiManager.GET, undefined, undefined, undefined, [this.storage.getUniqueId()]).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  getSummary(): Promise<any> {
    let params = [];
    let screen = this.navigationService.getScreenId('dashboard/consumer-app/intake-app/summary/homePage');
    params.push(this.intakeConsumerControlId());
    params.push(screen);
    return this.apiManager.fetchData('caseSummary', undefined, ApiManager.GET, undefined, undefined, undefined, params).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
  }

  savePage(obj, dir?, url?, screenId?, isWizard?) {
    url = (url) ? url : this.router.url;
    let params = [];
    let screen = screenId ? this.navigationService.getScreenId(screenId) : this.navigationService.getScreenId();
    params.push(this.intakeConsumerControlId());
    params.push(screen);
    this.apiManager.fetchData('saveIntake', undefined, ApiManager.POST, obj, undefined, undefined, params).toPromise().then(res => {
      this.loader.hide();
      console.log(res);
      if (isWizard) {
        this.navigationWizard.resetData(dir, true);
      } else if (dir) {
        this.router.navigate([this.navigationService.getPage(url, dir)]);
      }
    }).catch(err => {
      console.log(err);
      this.loader.hide();
    });
    this.loader.show();
  }

  saveEligPage(obj, url): Observable<any> {
    return this.apiManager.fetchEliData(url, undefined, ApiManager.POST, obj, undefined);
  }

  getEligPage(url) {
    let result: Promise<any>;
    result = this.apiManager.fetchEliData(url, undefined, ApiManager.GET, undefined, [this.storage.getCpId()]).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
    return result;
  }

  savePageAsObs(obj, dir?, url?, screenId?): Observable<any> {
    url = (url) ? url : this.router.url;
    let params = [];
    let screen = screenId ? this.navigationService.getScreenId(screenId) : this.navigationService.getScreenId();
    params.push(this.intakeConsumerControlId());
    params.push(screen);
    return this.apiManager.fetchData('saveIntake', undefined, ApiManager.POST, obj, undefined, undefined, params);
  }

  initialDataImport(): Observable<any> {
    return this.apiManager.fetchData('initialImportData', undefined, ApiManager.GET, undefined, undefined, undefined, [this.intakeCaseId(), this.intakeConsumerControlId()]);
  }

  specificPagesImportData(sendObj): Observable<any> {
    return this.apiManager.fetchData('specificImportData', undefined, ApiManager.POST, sendObj, undefined, undefined, [this.intakeCaseId(), this.intakeConsumerControlId()]);
  }

  getMemberByIndividualId(id) {
    if (!this.members) {
      this.members = this.getMembers();
    }
    let noMem: hohMembersClass = new hohMembersClass();
    let mem = this.members.filter(v => {
      return v.individualId == id;
    });
    if (mem.length > 0) {
      return mem[0];
    } else {
      return noMem;
    }
  }

  getPageAsObs(url?) {
    let sendUrl: string;
    if (url) {
      sendUrl = url;
    } else {
      sendUrl = this.router.url;
    }
    let sendObj: icGetClass = {
      screenDefn: sendUrl,
      controlId: this.intakeConsumerControlId()
    };
    return this.apiManager.fetchData('getIntake', undefined, ApiManager.POST, sendObj);
  }

  getPage(url?) {
    let result: Promise<any>;
    let sendUrl: string;
    if (url) {
      sendUrl = url;
    } else {
      sendUrl = this.router.url;
    }
    let sendObj: icGetClass = {
      screenDefn: sendUrl,
      controlId: this.intakeConsumerControlId()
    };
    result = this.apiManager.fetchData('getIntake', undefined, ApiManager.POST, sendObj).toPromise().then(IntakeService.extractData).catch(IntakeService.handleError);
    return result;
  }

  padWithZero(value) {
    if (value < 10) {
      return '0' + value.toString();
    }
    return value;
  }

  retrieveHelpText(screenDef) {
    let finalUrl = ApiManager['retrieveHelpText'];
    finalUrl = finalUrl + '?screenDefn=' + screenDef;

    return this.apiManager.fetchData(finalUrl, undefined, ApiManager.GET, undefined);
  }

  changeDate(dob: any): any {
    let date: string[];
    let final: string[];
    if (dob) {
      if (dob.toString().indexOf(' ') > -1) {
        dob = this.padWithZero(dob.getMonth() + 1) + '/' + this.padWithZero(dob.getDate() - 1) + '/' + dob.getFullYear() as any;
      } else {
        final = dob.toString().split('T');
        date = final[0].split('-');
        if (date[0] && date[1] && date[2]) {
          dob = date[1] + '/' + date[2] + '/' + date[0] as any;
        }
      }
    }
    return dob;
  }

  changeDateToMMDDYYYYFormat(el): any {
    if (el != undefined && el != '') {
      if (el instanceof Date) {
        el = el.toISOString();
      } else {
        el = new Date(el).toISOString();
      }

      const index = el.toString().indexOf('T');
      if (index != -1) {
        const dateFull: any = el.split('T');
        const date: any = dateFull[0].split('-');
        if (date.length > 1) {
          return (date[1] + '/' + date[2] + '/' + date[0]);
        } else {
          return date[0];
        }
      } else {
        return el;
      }
    }
  }

  redirectMyDhr(token, email) {
    let url = environment.myDhrTokenUrl;
    let urls = url.split('{token}');
    let urlEmail = urls[1].split('{email}');
    url = urls[0] + token + urlEmail[0] + email;
    window.location.href = url;
  }

  getFirstDayOfMonth () {
    return new Date(this.maxDate.getMonth() + 1 + '/01/' + this.maxDate.getFullYear());
  }

  textOnly(event: any) {
    return (event.keyCode === 32 || event.keyCode >= 65 && event.keyCode <= 90 || event.keyCode >= 97 && event.keyCode <= 122);
  }

  textHyphenOnly(event: any) {
    return (event.keyCode === 39 ||event.keyCode === 32 || event.keyCode === 45 || event.keyCode >= 65 && event.keyCode <= 90 || event.keyCode >= 97 && event.keyCode <= 122);
  }

  numberOnly(event: any) {
    return (event.keyCode >= 48 && event.keyCode <= 57);
  }

  alpaNumberOnly(event: any) {
    return (event.keyCode == 13 || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 13 || event.keyCode === 32 || (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122));
  }

  alphaNumericSpecialOnly(event: any) {
    return (event.charCode == 13 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13 || event.charCode === 32 || event.charCode == 45 || event.charCode == 44 || event.charCode === 47 || event.charCode === 64 || event.charCode === 38 || event.charCode === 39 || event.charCode === 46 || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
  }

  wControlIdFormat(event : any) {
    return (this.numberOnly(event) || event.keyCode == 87 || event.keyCode == 119 || event.keyCode == 83 || event.keyCode == 115 || event.keyCode == 68 || event.keyCode == 100);
  }

  public validateWControlOnly(event: any) {
    if(!this.wControlIdFormat(event)) {
      event.preventDefault();
    }
  }

  public validateNumberOnly(event: any) {
    if (!this.numberOnly(event)) {
      event.preventDefault();
    }
  }

  public validateText(event) {
    if (!this.textOnly(event)) {
      event.preventDefault();
    }
  }

  public validateTextHyphen(event) {
    if (!this.textHyphenOnly(event)) {
      event.preventDefault();
    }
  }

  public validateAlphaNumeric(event) {
    if (!this.alpaNumberOnly(event)) {
      event.preventDefault();
    }
  }

  public dateOnly(event: any) {
    // return event.keyCode >= 47 && event.keyCode <= 57;
    let val = event.target.value;
    const charCode = event.keyCode;      // Allow only backspace and delete
    if (charCode === 46 || charCode === 8 || charCode === 37 || charCode === 45) {
      // let it happen, don't do anything
    } else {   // Ensure that it is a number and stop the keypress
      if (!this.numberOnly(event)) {
        event.preventDefault();
      } else {
        let formatFlag = false;
        if (/^\d{2}$/.test(val)) {
          val = val.substring(0, 2) + '/';
          formatFlag = true;
        } else if (/^\d{2}\/\d{2}$/.test(val)) {
          val = val.substring(0, 5) + '/';
          formatFlag = true;
        } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(val)) {
          formatFlag = true;
        }
        if (formatFlag) {
          event.target.value = val;
        }
      }
    }
  }

  prepareDateObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.prepareDateObj(nestCopy));
        } else if (typeof copy[key] == 'object') {
          copy[key] = this.prepareDateObj(copy[key]);
        } else if (key !== 'updateUserId' && (key.endsWith('Date') || key.endsWith('dob') || key === 'monthAndYear' || key === 'dtIndvdlLeft' || key === 'dtIndvdlMovedIntHm' || key === 'startDt' || key === 'date' || key === 'effectiveBeginDt' || key === 'effectiveEndDt' || key === 'anticipatedReceiptDt' || key === 'dateOfBirth' || key === 'beginDt' || key === 'endDt' || key.includes('date') || key === 'EndDt')) {
          copy[key] = copy[key] == '' ? null : this.changeDateToYYYYMMDDFormat(copy[key]);
        }
      }
    }
    return copy;
  }

  prepareObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        copy[key] = copy[key] == null ? '' : copy[key];
      }
    }
    return copy;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] == null) {
          return false;
        }
      }
    }
    return true;
  }

  isValue(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if ((obj[key] != null) && (obj[key] != undefined) && (obj[key] != '')) {
          return true;
        }
      }
    }
    return false;
  }

  changeDateToYYYYMMDDFormat(el): any {
    if (el != undefined && el != '') {
      if (el instanceof Date) {
        el = el.toISOString();
      } else {
        el = new Date(el).toISOString();
      }

      const index = el.toString().indexOf('T');
      if (index != -1) {
        const dateFull: any = el.split('T');
        return dateFull[0];
      } else {
        return el;
      }
    }
  }

  changeDateToMaterialLanguage(val) {
    if (val) {
      if(val.indexOf('T') > -1) {
        return new Date(val);
      } else {
        val = this.changeDateToYYYYMMDDFormat(val);
        val = val.toString().split('-');
        const valMonth = parseInt(val[1]);
        const valDate = parseInt(val[2]);
        const valYear = parseInt(val[0]);
        return new Date(valYear, valMonth - 1, valDate);
      }
    }
  }

  getSsn(ssn: SocialSecurityNumber): string {
    let ssnString = '';
    if (!ssn) {
      return '';
    }
    if (ssn.soSecPart1) {
      ssnString += ssn.soSecPart1 + '-';
    }
    if (ssn.soSecPart2) {
      ssnString += ssn.soSecPart2 + '-';
    }
    if (ssn.soSecPart3) {
      ssnString += ssn.soSecPart3;
    }
    if (new RegExp(this.ssnPattern).test(ssnString)) {
      return ssnString;
    } else {
      return '';
    }
  }

  formatSsn(val) {
    val = val.replace(/\D/g, '');
    val = val.replace(/^(\d{3})/, '$1-');
    val = val.replace(/-(\d{2})/, '-$1-');
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return val;
  }

  splitCamelCaseToString(val) {
    return val.replace(/([A-Z])/g, ' $1')
  }

  displayDollarAmount(el) {
    if (el) {
      return '$' + el + '.00';
    }
    else {
      return "$0.00";
    }
  }

  ssnMask(val: any) {
    if(val){
    return val.substring(0 , 3) + '-' + val.substring(3 , 5) + '-' + val.substring(5 , 9)
    }
  }
  remSsnMask(val: any) {
    val = val.toString().split('-');
    return val[0]+val[1]+val[2]
  }
  ressnMask(val: any) {
    if(val){
    return val.substring(0 , 3) + '-' + val.substring(3 , 5) + '-' + val.substring(5 , 9)
    }
  }
  reremSsnMask(val: any) {
    val = val.toString().split('-');
    return val[0]+val[1]+val[2]
  }
  ssnMasking(event: any) {
    let val = event.target.value;
    const charCode = event.keyCode;      // Allow only backspace and delete
    if (charCode === 46 || charCode === 8 || charCode === 37 || charCode === 45) {
      // let it happen, don't do anything
    } else {   // Ensure that it is a number and stop the keypress
      if (!this.numberOnly(event)) {
        event.preventDefault();
      } else {
        let formatFlag = false;
        if (/^\d{3}$/.test(val)) {
          val = val.substring(0, 3) + '-';
          formatFlag = true;
        } else if (/^\d{3}-\d{2}$/.test(val)) {
          val = val.substring(0, 6) + '-';
          formatFlag = true;
        }
        if (formatFlag) {
          event.target.value = val;
        }
      }
    }
  }

  phoneMasking(event: any) {
    let val = event;
    let one, two, three;
    let formatFlag = false;
    if (val.length == 10 && val.indexOf('(') == -1) {
      one = val.substr(0,3);
      two = val.substr(3,3);
      three = val.substr(6);
      val = '(' + one + ') ' + two + '-' + three;
      formatFlag = true;
    }
    if (formatFlag) {
      return val;
    } else return event;
  }

  getFullAddress(address: Address): any {
    let fullAddress = '';
    let lookUpValues = '';

    this.lookupService.getLookUpValues(['State', 'County'], LookupManagerService.english).then(res => {
      lookUpValues = res;
    }).catch(err => {
      console.log(err);
    });

    if (!address) {
      return fullAddress;
    }
    if (address.addressLine1) {
      fullAddress += address.addressLine1;
    }
    if (address.addressLine2) {
      fullAddress += '\n ' + address.addressLine2;
    }
    if (address.city) {
      fullAddress += '\n ' + address.city;
    }
    let stateFlag = false;
    if (address.state) {
      if (lookUpValues['State']) {
        for (const entry of lookUpValues['State']) {
          if (entry.lkpDesc === address.state || entry.lookupLongVal === address.state) {
            fullAddress += '\n ' + entry.lookupLongVal;
            stateFlag = true;
          }
        }
      }
      if (!stateFlag) {
        fullAddress += '\n ' + address.state;
      }
    }
    let countyFlag = false;
    if (address.county) {
      if (lookUpValues['County']) {
        for (const entry of lookUpValues['County']) {
          if (entry.lkpDesc === address.state || entry.lookupLongVal === address.state) {
            fullAddress += '\n ' + entry.lookupLongVal;
            stateFlag = true;
          }
        }
      }
      if (!countyFlag) {
        fullAddress += '\n ' + address.county;
      }
    }
    if (address.district) {
      fullAddress += '\n ' + address.district;
    }
    if (address.zipcode) {
      fullAddress += '\n ' + address.zipcode;
    }
    if (address.zip4code && address.zipcode) {
      fullAddress += '-' + address.zip4code;
    }
    return fullAddress;
  }

  getHOH() {
    let hoh: hohMembersClass[];
    hoh = (this.getMembers()) ? this.getMembers().filter(v => {
      return v.isHoh == 'Y';
    }) : [];
    return hoh;
  }

  getHohIndividualID() {
    let hohId = '';
    this.getHOH().filter(ele => {
      hohId = ele.dummyIndividualId;
    });
    return hohId;
  }

  getAllMemIndividualId() {
    let indId = [];
    this.getMembers().filter(ele => {
      indId.push(parseInt(ele.dummyIndividualId));
    });
    return indId;
  }

  getMemDobByIndiId(val) {
    let dob = '';
    this.getMembers().filter(ele => {
      if (ele.dateOfBirth && ele.dummyIndividualId == val) {
        dob = this.changeDateToMMDDYYYYFormat(ele.dateOfBirth);
      }
    });
    return this.changeDateToMaterialLanguage(dob);
  }

  getAuthRep(authReps?: IntakeAuthrep[]): Observable<any> {
    if (authReps) {
      this.authReps = authReps;
    } else {
      return Observable.create(observer => {
        this.getPage('/dashboard/consumer-app/intake-app/hoh/authRep').then(res => {
          this.authReps = res == this.noJson ? [] : JSON.parse(res);
          observer.next(this.authReps);
          observer.complete()
        }).catch(err => {
          console.log(err);
          observer.next([]);
          observer.complete();
        })
      })
    }
  }

  getDataView(): Promise<any> {
    return this.apiManager.fetchData('caseSummary', undefined, ApiManager.GET, undefined, undefined, undefined, [this.intakeConsumerControlId()]).toPromise();
  }

  getAllIndividualIdForCase(caseId): Promise<any> {
    return this.apiManager.fetchData('getIndividualId', undefined, ApiManager.GET, undefined, undefined, undefined, [caseId]).toPromise();
  }

  toDateFormat(val) {
    return new Date(val);
  }

  updateSaveObject(array: any[], Obj, type, index?, del?) {
    let ds = this.getDirtyStatus(Obj[type].recordId, del);
    let updated = this.updateAllDirtyStatus(Obj, ds);
    console.log(updated);
    if (!updated.dirtyStatus) {
      updated.dirtyStatus = ds;
    }
    updated.importSelected = true;
    if (index > -1 && updated[type].recordId == 0 && del) {
      array.splice(index, 1)
    } else if (index == -1 || index == undefined) {
      array.push(updated);
    } else if (index > -1) {
      array[index] = updated;
    }
    return array;
  }

  updateAllDirtyStatus(theObject, status?) {
    try {
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          this.updateAllDirtyStatus(theObject[i], status);
        }
      } else {
        for (let prop in theObject) {
          if (prop == 'dirtyStatus') {
            theObject[prop] = status

          }
          if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
            this.updateAllDirtyStatus(theObject[prop], status);
          }
        }
      }
      return theObject;
    } catch (e) {
      console.log(e);
    }
  }

  getDirtyStatus(recordId, del?) {
    if (recordId >= 0 && del) {
      return 'delete';
    } else if (recordId > 0) {
      return 'update';
    } else if (recordId == 0 && del==undefined) {
      return 'insert';
    } else {
      return '';
    }
  }

  calculateAge(dob) {
    if (dob) {
      const bdate = new Date(dob);
      const timeDiff = Math.abs(Date.now() - bdate.getTime());
      return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
  }

  findDeletedMembers() {
    let deleteIndiId = [];
    let members: hohMembersClass[] = this.session.retrieve('intakeMembers');
    members.map(mem => {
      if (mem.dirtyStatus == 'DELETE') {
        deleteIndiId.push(mem.dummyIndividualId);
      }
    });
    return deleteIndiId;
  }

  getCurrentMemRecords(pageJson) {
    let sendObj = [];
    let findDeletedMembers = this.findDeletedMembers();

    if (findDeletedMembers.length > 0) {
      findDeletedMembers.map(del => {
        pageJson.map(page => {
          if (page.individualId != del) {
            sendObj.push(page);
          }
        });
      });
      return sendObj;
    } else {
      return pageJson;
    }
  }

  ariaLabelSuffix(val) {
    if(val == 'I') {
      return 'first';
    } else if(val == 'II') {
      return 'second';
    } else if(val == 'III') {
      return 'third';
    } else if(val == 'IV') {
      return 'fourth';
    } else if(val == 'V') {
      return 'fifth';
    } else if(val == 'Jr') {
      return 'junior';
    } else if(val == 'Sr') {
      return 'senior';
    } else return val;
  }

  getOhepCompanies(doCode, zipCode, isElectric): Promise<any> {
    return this.apiManager.fetchData('ohepCompanies', undefined, ApiManager.GET, undefined, undefined, undefined, [doCode, zipCode, isElectric]).toPromise();
  }

  getOhepWWCompanies(doCode, zipCode, utlyType): Promise<any> {
    return this.apiManager.fetchData('ohepCompaniesWW', undefined, ApiManager.GET, undefined, undefined, undefined, [doCode, zipCode, utlyType]).toPromise();
  }

  getLaunchCounty(): Promise<any> {
    return this.apiManager.fetchData('getLaunchCounty', undefined, ApiManager.GET, undefined).toPromise();
  }

  getCountyByZipCode(zipCode): Promise<any> {
    return this.apiManager.fetchData('getCountyByZipCode', undefined, ApiManager.GET, undefined, undefined, undefined, [zipCode]).toPromise();
  }

  hideFinancialAmounts(): boolean {
    return this.session.retrieve('appType') == 'IC' || this.session.retrieve('appType') == 'RD';
  }

  capitalizeFirstLetter(val) {
    if(val) {
      let str = val.split(" ");
      for (let i = 0, x = str.length; i < x; i++) {
        if(str[i]) {
          str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
        }
      }
      return str.join(" ");
    } else {
      return val;
    }
  }

  getHohOrMemData(isHoh): Promise<any> {
    if(isHoh == 'Y') {
      return this.getPage('/dashboard/consumer-app/intake-app/hoh/hohInfo');
    } else if(isHoh == 'N') {
      return this.getPage('/dashboard/consumer-app/intake-app/members/memberInfo');
    }
  }

  BoolObjectConvert(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && key.endsWith('YN')) {
        if (obj[key] === true) {
          obj[key] = 'Y';
        }
        else if (obj[key] === false) {
          obj[key] = 'N';
        }
      }
    }
    return obj;
  }

  BooltoYNObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.BooltoYNObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.BooltoYNObjectConvert(copy[key]);
        }
        else if (/verifiedYN/i.test(key)) {
          if (copy[key] === false || copy[key] === 'N') {
            copy[key] = 'N';
          }
          else if (copy[key] === true || copy[key] === 'Y') {
            copy[key] = 'Y';
          }
        }
        else if (key.endsWith('YN') && (copy[key] === true || copy[key] === 'Y')) {
          copy[key] = 'Y';
        }
        else if (key.endsWith('YN') && (copy[key] === false || copy[key] === 'N' || copy[key] === '')) {
          copy[key] = 'N';
        }
      }
    }
    return copy;
  }

  YNtoBoolObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.YNtoBoolObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.YNtoBoolObjectConvert(copy[key]);
        }
        else if (copy[key] === 'Y') {
          copy[key] = true;
        }
        else if (copy[key] === 'N') {
          copy[key] = false;
        }
      }
    }
    return copy;
  }

  getPrograms() {
    let sendProgram = [];
    this.storage.getPrograms().map(program => {
      if (program.programSelected) {
        sendProgram.push(program.programSelected.programName);
      }
    });
    return sendProgram;
  }
}
