import {Injectable, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {hohMembersClass} from "../model/api/InterimChange/ic-hohmembers.class";
import {ApiManager} from "./api-manager.service";
import {StorageService} from "./storage.service";
import {icGetClass} from "../model/api/InterimChange/ic-get.class";
import {ConsumerNavClass} from "../model/api/consumer-nav.class";
import {NavigationService} from "./navigation.service";
import {IcSearchTypeClass} from "../model/api/InterimChange/ic-search-type.class";

@Injectable()
export class InterimChangeService implements OnInit {
  public interimApp: any[] = [];
  public frequencies: any[] = [];
  public consumerControlId: any;
  public nav: ConsumerNavClass[] = [];
  public members: hohMembersClass[] = [];
  public disableSubmit: boolean = true;
  public static searchTypes: IcSearchTypeClass[] = [
    {name: 'Social Security Number (SSN)', type: 'S'},
    {name: 'Case ID', type: 'C'},
    {name: 'CARES ID / IRN', type: 'I'},
    {name: 'Customer ID', type: 'M'},
    {name: 'MA ID', type: 'MA'},
    {name: 'SID', type:'SI'},
    {name: 'Web Ref ID', type:'WI'}
  ];
  // public static searchTypes: IcSearchTypeClass[] = [
  //   {name: 'Social Security Number (SSN)', type: 'S'},
  //   {name: 'Case ID', type: 'C'},
  //   {name: 'CARES ID / IRN', type: 'I'},
  //   {name: 'Customer ID', type: 'M'},
  //   {name: 'MA ID', type: 'MA'},
  //   {name: 'SID', type:'SI'}
  // ];
  public static applicationTypes: IcSearchTypeClass[] = [
    {name: 'Application', type: 'AP'},
    {name: 'Change', type: 'IC'},
    {name: 'Redet', type: 'RD'}
  ];

  constructor(private router: Router,
              private apiManager: ApiManager,
              private storage: StorageService,
              private navigationService: NavigationService) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  ngOnInit() {
    this.frequencies = this.setFrequencies();
  }

  getMembers(): hohMembersClass[] {
    return this.storage.getInterimMembers();
  }

  getConsumerControlId() {
    return this.consumerControlId;
  }

  setConsumerControlId(id) {
    this.consumerControlId = id;
  }

  getNewId(): number {
    let version: number = 0;
    let parsed: number = 0;
    this.getMembers().forEach(v => {
      if (v.individualId.toString().indexOf('-') > -1) {
        parsed = parseInt(v.individualId.toString().split('-')[1]);
        if (version < parsed) {
          version = parsed;
        }

      }
    });
    return version + 1;
  }

  getHoh(): hohMembersClass {
    return this.getMembers().filter(v => {
      if (v.relationship == 'HOH/Self' || v.relationship == 'SE~HOH/Self' || v.relationship == 'SE') {
        return v;
      }
    })[0];
  }

  getSpouse(): hohMembersClass {
    return this.getMembers().filter(v => {
      if (v.relationship == 'Spouse' || v.relationship == 'CR~Spouse' || v.relationship == 'CR') {
        return v;
      }
    })[0];
  }

  getMembersByIndividualId(id): hohMembersClass {
    for (let mem of this.storage.getInterimMembers()) {
      if (mem.individualId == id) {
        return mem;
      }
    }
  }

  setDisableSubmit(submit?: boolean): boolean {
    if (submit != undefined) {
      this.disableSubmit = submit;
    }
    return this.disableSubmit;
  }

  getMemberNameByPersonId(id): string {
    for (let mem of this.storage.getInterimMembers()) {
      if (mem.personId == id) {
        return mem.firstName + ' ' + mem.lastName;
      }
    }
  }

  resetApplication() {
    this.interimApp = [];
  }

  setFrequencies(): any[] {
    let obj = [];
    obj['AN'] = [{
      head: 'Annual Income',
      required: true,
      divideBy: 12
    }];
    obj['AC'] = [{
      head: 'Monthly Income',
      required: true,
      divideBy: 1
    }];
    obj['OT'] = [{
      head: 'One Time Only Income',
      required: true,
      divideBy: 1
    }];
    obj['SN'] = [{
      head: 'Semi Annual Income',
      required: true,
      divideBy: 2
    }];
    obj['QU'] = [{
      head: 'Quarterly Income',
      required: true,
      divideBy: 3
    }];
    obj['BM'] = [{
      head: 'Bi-Monthly Income',
      required: true,
      divideBy: 2
    }];
    obj['SA'] = [{
      head: 'Semi-Annual Income',
      required: true,
      divideBy: 6
    }];
    obj['BW'] = [{
      head: 'Week 1&2 Income',
      required: true
    }, {
      head: 'Week 3&4 Income',
      required: true
    }, {
      head: 'Week 5&6 Income',
      required: false
    }];
    obj['WE'] = [{
      head: 'Week 1 Income',
      required: true
    }, {
      head: 'Week 2 Income',
      required: true
    }, {
      head: 'Week 3 Income',
      required: true
    }, {
      head: 'Week 4 Income',
      required: true
    }, {
      head: 'Week 5 Income',
      required: false
    }];

    return obj;
  }

  getFrequencies(type): any[] {
    if (this.frequencies.length == 0) {
      this.frequencies = this.setFrequencies();
    }
    return this.frequencies[type];
  }

  getPage(url?): Promise<any> {
    let result: Promise<any>;
    let sendUrl: string;
    if (url) {
      sendUrl = url;
    } else {
      sendUrl = this.router.url;
    }
    let sendObj: icGetClass = {
      screenDefn: sendUrl,
      controlId: this.storage.getConsumerControlId()
    };
    result = this.apiManager.fetchData('icGet', undefined, ApiManager.POST, sendObj).toPromise().then(this.extractData).catch(this.handleError);
    return result;
  }

  getScreenId(currentUrl?): number {
    this.nav = this.navigationService.getNavItems();
    let screenId: number = 0;
    const url: string = currentUrl ? currentUrl : this.router.url;
    this.nav.forEach(v => {
      if (v.screen_defn == url) {
        screenId = v.screen_id;
      }
    });
    return screenId;
  }

  insertPage(obj, dir?, url?, screeId?) {
    url = (url) ? url : this.router.url;
    let params = [];
    let screen = screeId ? this.getScreenId(screeId) : this.getScreenId();
    params.push(this.storage.getConsumerControlId());
    params.push(screen);
    //  const convObj:string = JSON.stringify(obj);
    // console.log(convObj);
    this.apiManager.fetchData('icSave', undefined, ApiManager.POST, obj, undefined, undefined, params).toPromise().then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    });
    if (dir) {
      this.router.navigate([this.navigationService.getPage(url, dir)]);
    }
  }

  insertHohPage(obj, dir?, url?, screeId?) {
    url = (url) ? url : this.router.url;
    let params = [];
    let screen = screeId ? this.getScreenId(screeId) : this.getScreenId();
    params.push(this.storage.getConsumerControlId());
    params.push(screen);
    let result: Promise<any>;
    result = this.apiManager.fetchData('icSave', undefined, ApiManager.POST, obj, undefined, undefined, params).toPromise().then(this.extractData).catch(this.handleError);
    if (dir) {
      this.router.navigate([this.navigationService.getPage(url, dir)]);
    }
    return result;
  }

  convertDropDownToVal(val: string): string {
    let result: string;
    if (val && val.indexOf('~') > -1) {
      result = val.split('~')[1];
    } else {
      result = val;
    }
    return result;
  }

  convertDropDownToCd(val: string): string {
    let result: string;
    if (val.indexOf('~') > -1) {
      result = val.split('~')[0];
    } else {
      result = val;
    }
    return result;
  }

  getDirtyStatus() {
    let dirtyStatus = [{
      type: 'Add',
      value: 'add'
    }, {
      type: 'Delete',
      value: 'delete'
    }, {
      type: 'Change',
      value: 'update'
    }];

    return dirtyStatus;
  }

  freqAmountDivide(freq, amt) {
    if (freq) {
      let thisFreq = this.getFrequencies(freq);
      let totalAmt: number = 0;
      for (let a of amt) {
        if (thisFreq[0].divideBy) {
          totalAmt = a.amount / thisFreq[0].divideBy;
          break;
        } else {
          totalAmt = totalAmt + a.amount;
        }
      }
      return totalAmt;
    } else {
      return 0.00;
    }
  }

  changeDateToMMDDYYYYFormat(el): any {
    const index = el.toString().indexOf('T');
    if (index != -1) {
      const dateFull: any = el.split('T');
      const date: any = dateFull[0].split('-');
      if (date.length > 1) {
        return (date[1] + '/' + date[2] + '/' + date[0]);
      } else {
        return date[0];
      }
    } else {
      return el;
    }
  }

}
