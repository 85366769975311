import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCustomNavComponent } from './select-custom-nav/select-custom-nav.component';
import {AngularMaterialModule} from "./angular-material/angular-material.module";
import {AppointmentCalendarModule} from "./appointment-calendar/appointment-calendar.module";
import {CustomNavModule} from "./select-custom-nav/custom-nav.module";
import { SmartyStreetDialogComponent } from "./smartyStreet-dialog/smarty-street-dialog/smarty-street-dialog.component";
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    AppointmentCalendarModule,
    CustomNavModule
  ],
  declarations: [SmartyStreetDialogComponent],
  exports: [
    AngularMaterialModule,
    AppointmentCalendarModule,
    CustomNavModule
  ],
  entryComponents:[SmartyStreetDialogComponent]

})

export class SharedModule { }
