import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';
import { EligibleImmigrationStatusDoc } from './eligible-immigration-status-doc.class';
import { NaturalizedCitizenshipDoc } from './naturalized-citizenship-doc.class';
export class CitizenshipImmigration extends BaseBean{
    constructor(
        // public usCitizenYN: string = '',
        // public naturalizedCitizenYN: string = '',
        // public eligibleImmigrantYN: string = '',
        // public naturalizedCitizenshipDoc: NaturalizedCitizenshipDoc = new NaturalizedCitizenshipDoc(),
        // public eligibleImmigrationStatusDoc: EligibleImmigrationStatusDoc = new EligibleImmigrationStatusDoc(),
        // public usCitizenshipVerification: ClientVerification = new ClientVerification(),
        // public immigrationStatusVerification: ClientVerification = new ClientVerification(),
        // public naturalizedCitizenshipVerification: ClientVerification = new ClientVerification(),
        public recordId: number = undefined,
        public usCitizenshipStatus: string = '',
        public usCitizenshipVerification: ClientVerification = new ClientVerification(),
        public uscisStatus: string = '',
        public uscisStatusDate: Date = null,
        public uscisStatusVerification: ClientVerification = new ClientVerification(),
        public sponsoredAlienYN: string = '',
        public countryOfOrigin: string = '',
        public entryDate: Date = null,
        public uscisNumber: string = '',
        public uscisNumberVerification: ClientVerification = new ClientVerification(),
        public uscisNumberVerificationDate: Date = null,
        public medicalEmergencyYN: string = '',
        public refugeeSettlementAgency: string = '',
        public specifyAgency: string = '',
        public statePaidProgramYN: string = '',
        public alienNumber: string = '',
        public cardNumber: string = '',
        public docExpirationDate: Date = null,
        public i94Number: string = '',
        public passportNumber: string = '',
        public sevisId: string = '',
        public lawfulResidentFiveYearsYN: string = '',
        public residentshipPeriodYN: string = '',
        public countryOfPassport: string = '',
        public immigrationDocument: string = '',
        public visaNumber: string = '',
        public otherTypeDescription: string = '',
        public lawfulPresenceVerification: ClientVerification = new ClientVerification(),
        public beginDt: Date = null,
        public endDt: Date = null,
        public changeInCitizenshipYN: string = '',
        public changeInVoterYN: string = '',
    ) {
        super();
    }
}
