export class LookupClass {
  constructor(
    public lookupTypeCd: string = '',
    public lkpDesc: string = '',
    public localeCd: string = '',
    // public lookupShortVal:string = '',
    public lookupLongVal: string = '',
    public sortOrderNbr: number = null
  ) {
  }
}
