import { ApiMessage } from './api-message.class'
export class ApiResponseModel {
    constructor(
        public requestActor: string = '',
        public requestOwner: string = '',
        public userMessages: ApiMessage[] = [],
        public requestDateTime: Date = null,
        public requestId: string = '',
        public navigationFlowRequested: boolean = false
    ) { }
}
