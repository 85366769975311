import { BaseBean } from './base-bean.class';
import { AppointmentManagement } from 'app/model/api/appointment-management.class';
import { ClientVerification } from 'app/model/api/client-verification.class';
import { Signatures } from './signatures.class';
export class Signature extends AppointmentManagement {
    constructor(
        public signatures: Signatures[] = [],
    ) {
        super();
    }
}
