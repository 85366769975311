import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { DataShareService } from './data-share.service';
import { Address } from '../model/api/address.class';
import { PersonName } from '../model/api/person-name.class';
import { HouseholdVerification } from '../model/api/household-verification.class';
import { ClientVerification } from '../model/api/client-verification.class';
import { ApiMessage } from '../model/api/api-message.class';
import { SocialSecurityNumber } from '../model/api/social-security-number.class';
import { LocalizedLookupBean } from '../model/api/localized-lookup-bean.class';
import { environment } from '../../environments/environment';
import { StorageService } from "./storage.service";
import { Login } from '../model/api/Auth/login.class';
import { AuthService } from './auth.service';

@Injectable()
export class AppUtilService {
  statesDropdown: any[];

  // Used to maintain Title Strip content
  curModule: any;
  curScreen: any;
  public curId: any;
  private userDetails: Login;
  private logoutUrl: any = environment.logoutURL;
  private loginURL: any = environment.cpHomePage;
  private logoutMdthink: any = environment.logoutMdthink;
  private logoutMhbee: any = environment.logoutMhbee;
  private cpHomePage: any =environment.cpHomePage;
  private envName: any = environment.envName;
  private sessionLogoutUrl: any = environment.sessionLoginUrl;
  clientId: any = this.storage.getClientId();
  idToken: any = this.storage.getIdToken();
  constructor(private constantsService: ConstantsService,
    private shareService: DataShareService,
    private storage: StorageService,
    private authService: AuthService) {
  }

  logout() {
    const {refreshToken} = this.storage.getUserInfoPrefetch().user;

    this.authService.refreshToken(refreshToken).subscribe((res)=>{
      const clntID = this.clientId;
      if(res.headers){
        let accessToken = res.headers.get('access-token')
        let idToken = res.headers.get('id-token')

        if(accessToken){
          this.storage.setAccessToken(accessToken);
        }

        if (clntID === 'MDTEEClient') {
          window.location.href = this.logoutMhbee+`id_token_hint=${idToken}&post_logout_redirect_uri=${this.cpHomePage}&client_id=${clntID}`;
          setTimeout(() => {
            this.storage.destroyAll();
          }, 0);
        } else {
          window.location.href = this.logoutMdthink+`id_token_hint=${idToken}&post_logout_redirect_uri=${this.cpHomePage}&client_id=${clntID}`;
          setTimeout(() => {
            this.storage.destroyAll();
          }, 0);
        } 
      }
    })

    // const goto = '&goto=';
    // const clntID = this.clientId;
    // const idTokenHint = this.idToken;
    // let idToken;
    // let userInfo = this.storage.getUserInfoPrefetch();
    // if(userInfo && userInfo.user){
    //   idToken = userInfo.user.idToken;
    // }
    // let idTokenStorage = idToken || idTokenHint || this.storage.getIdToken();
    // if (clntID === 'MDTEEClient') {
    //   window.location.href = this.logoutMhbee+`id_token_hint=${idTokenStorage}&post_logout_redirect_uri=${this.cpHomePage}&client_id=${clntID}`;
    //   setTimeout(() => {
    //     this.storage.destroyAll();
    //   }, 0);
    // } else {
    //   window.location.href = this.logoutMdthink+`id_token_hint=${idTokenStorage}&post_logout_redirect_uri=${this.cpHomePage}&client_id=${clntID}`;
    //   setTimeout(() => {
    //     this.storage.destroyAll();
    //   }, 0);
    // } 

    // else {
    //   this.storage.destroyAll();
    //   window.location.href = this.logoutUrl;
    // }
    // window.location.href = this.logoutUrl + goto + this.loginURL;
    // if (this.envName == ".dev4" || this.envName == "dev2") {
    //   // const goto = 'openam/logout';
    //   // window.location.href = this.logoutUrl + goto;
    //   window.location.href = this.logoutUrl;
    // } else {
    //   const goto = '&goto=';
    //   window.location.href = this.logoutUrl + goto + this.loginURL;
    // }
  }

  login() {
    this.storage.destroyAll();
    window.location.href = this.loginURL;
  }

  sessionLogout() {
    this.storage.destroyAll();
    const goto = '&goto=';
    window.location.href = this.logoutUrl + goto + this.sessionLogoutUrl;
  }

  getFullName(person: PersonName): string {
    let fullName = '';
    if (!person) {
      return fullName;
    }
    if (person.firstName) {
      fullName += person.firstName;
    }
    if (person.middleName) {
      fullName += ' ' + person.middleName;
    }
    if (person.lastName) {
      fullName += ' ' + person.lastName;
    }
    if (person.suffix) {
      if (this.constantsService.lookupData) {
        // for (const entry of this.constantsService.lookupData.localizedLookups['Suffix'].beans) {
        //   if (entry.lookupBean.code === person.suffix) {
        //     fullName += ' ' + entry.lookupBean.shortLabel;
        //   }
        // }
      }
    }
    return fullName;
  }

  getFirstDayofMonth() {
    let d = new Date();
    d.setDate(1);
    return d;
  }

  changeDateToMMDDYYYYHHMMFormat(el): any {
    if (el) {
      const datetime = el.split('T')
      const date: any = datetime[0].split('-');

      if (datetime.length > 1) {
        const hhmm: any = datetime[1].split(':');
        return (date[1] + '/' + date[2] + '/' + date[0] + ' ' + hhmm[0] + ':' + hhmm[1]);
      } else {
        return date[1] + '/' + date[2] + '/' + date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMMFormat(el): any {
    if (el) {
      const date: any = el.split('/');
      if (date.length > 1) {
        return (date[0] + '-' + date[1] + '-' + date[2]);
      } else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMMFormatHiphen(el): any {
    if (el) {
      const date: any = el.split('-');
      if (date.length > 1) {
        return (date[1] + '/' + date[2] + '/' + date[0]);
      } else {
        return date[0];
      }
    }
    return el;
  }

  checkFutureBeginDate(date) {
    let d = new Date();
    d.setMonth(d.getMonth() + 2);
    let beginFutureDate = d.toISOString();
    let futureBeginDate = this.changeDateToMMDDYYYYFormat(this.changeDateYYYYMMDD(beginFutureDate));
    let eventStartTime = new Date(date);
    let eventFutureTime = new Date(futureBeginDate);
    return eventFutureTime.valueOf() - eventStartTime.valueOf();
  }

  ssnFormat(el): any {
    if (el) {
      const LastFourDigitSSN = el.substring(7);
      return 'XXX-XX-' + LastFourDigitSSN;
    }
  }
  getPersonName(name: string, suffix: string): PersonName {
    let personName: PersonName = new PersonName();
    const arr: any[] = name.split(' ');
    let size: number;
    personName.suffix = suffix;
    size = (arr.length > 3) ? arr.length - 2 :
      (arr.length === 3 && suffix === '') ? arr.length - 1 :
        (arr.length === 3 && suffix !== '') ? arr.length - 2 : arr.length - 1;
    personName.firstName = arr[0];
    if (arr.length > 3 || (arr.length === 3 && suffix === '')) {
      for (let i = 1; i < size; i++) {
        personName.middleName += arr[i];
      }
    }
    if (size > 0) {
      personName.lastName = arr[size];
    }
    if (size !== arr.length - 2) {
      personName.suffix = '';
    }
    return personName;
  }

  addScript(src) {
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.setAttribute('type', 'text/javascript');
    s.async = true;
    document.body.appendChild(s);
  }

  getFullAddress(address: Address): any {
    let fullAddress = '';
    if (!address) {
      return fullAddress;
    }
    if (address.addressLine1) {
      fullAddress += address.addressLine1;
    }
    if (address.addressLine2) {
      fullAddress += '\n ' + address.addressLine2;
    }
    if (address.city) {
      fullAddress += '\n ' + address.city;
    }
    let stateFlag = false;
    if (address.state) {
      if (this.constantsService.lookupData) {
        for (const entry of this.constantsService.lookupData.localizedLookups['State'].beans) {
          if (entry.lookupBean.code === address.state || entry.lookupBean.shortLabel === address.state) {
            fullAddress += '\n ' + entry.lookupBean.shortLabel;
            stateFlag = true;
          }
        }
      } if (!stateFlag) {
        fullAddress += '\n ' + address.state;
      }
    }
    let countyFlag = false;
    if (address.county) {
      if (this.constantsService.lookupData) {
        for (const entry of this.constantsService.lookupData.localizedLookups['County'].beans) {
          if (entry.lookupBean.code == address.county || entry.lookupBean.shortLabel == address.county) {
            fullAddress += '\n ' + entry.lookupBean.longLabel;
            countyFlag = true;
          }
        }
      } if (!countyFlag) {
        fullAddress += '\n ' + address.county;
      }
    }
    if (address.district) {
      fullAddress += '\n ' + address.district;
    }
    if (address.zipcode) {
      fullAddress += '\n ' + address.zipcode;
    }
    if (address.zip4code && address.zipcode) {
      fullAddress += '-' + address.zip4code;
    }
    return fullAddress;
  }

  YNConvert(yesno: string): boolean {
    let bool: boolean
    if (typeof yesno === 'string') {
      yesno = yesno.trim().toLowerCase()
    }
    bool = yesno === 'y' || yesno === 'yes' || yesno === 'true' ? true : false;

    return bool;
  }

  BoolConvert(bool: boolean): string {
    return (bool === true) ? 'Y' : 'N';
  }

  BoolObjectConvert(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && key.endsWith('YN')) {
        obj[key] = (obj[key] === true || obj[key] === 'Y') ? 'Y' : 'N';
      }
    }
    return obj;
  }

  BooltoYNObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.BooltoYNObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.BooltoYNObjectConvert(copy[key]);
        }
        else if (key.endsWith('YN')) {
          copy[key] = copy[key] === true ? 'Y' : 'N';
        }
      }
    }
    return copy;
  }

  YNObjectConvert(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && key.endsWith('YN')) {
        obj[key] = obj[key] == 'Y' ? true : false;
      }
    }
    return obj;
  }

  YNtoBoolObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.YNtoBoolObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.YNtoBoolObjectConvert(copy[key]);
        }
        else if (key.endsWith('YN')) {
          copy[key] = copy[key] === 'Y' ? true : false;
        }
      }
    }
    return copy;
  }

  ConvertDateToNull(dateStr: string) {
    if (dateStr == '') {
      return null;
    }
    return dateStr;
  }

  prepareDateObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.prepareDateObj(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.prepareDateObj(copy[key]);
        }

        else if (key.endsWith('Date') || key === 'monthAndYear') {
          copy[key] = copy[key] == '' ? null : this.changeDateToYYYYMMDDFormat(copy[key]);
        }
      }
    }
    return copy;
  }

  loadDateObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in obj) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.loadDateObj(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.loadDateObj(copy[key]);
        }
        else if (key.endsWith('Date') || key === 'monthAndYear') {
          copy[key] = copy[key] == '' ? null : this.changeDateToMMDDYYYYFormat(copy[key]);
        }
      }
    }
    return copy;
  }

  prepareLoad(obj: any) {
    if (!obj) return null;
    let preDate = this.loadDateObj(obj);
    let preYN = this.YNtoBoolObjectConvert(preDate);
    return preYN;
  }

  prepareSave(obj: any) {
    if (!obj) return null;
    let preDate = this.prepareDateObj(obj);
    let preBool = this.BooltoYNObjectConvert(preDate);
    return preBool;
  }

  getSsn(ssn: SocialSecurityNumber): string {
    let ssnString = '';
    if (!ssn) {
      return '';
    }
    if (ssn.soSecPart1) {
      ssnString += ssn.soSecPart1 + '-';
    }
    if (ssn.soSecPart2) {
      ssnString += ssn.soSecPart2 + '-';
    }
    if (ssn.soSecPart3) {
      ssnString += ssn.soSecPart3;
    }
    if (new RegExp(ConstantsService.ssnPattern).test(ssnString)) {
      return ssnString;
    } else {
      return '';
    }
  }

  getSsnObjectFromString(input: string): SocialSecurityNumber {
    const ssn: SocialSecurityNumber = new SocialSecurityNumber();
    if (new RegExp(ConstantsService.ssnPattern).test(input)) {
      const splittedSsn: string[] = input.split('-');
      ssn.soSecPart1 = splittedSsn[0];
      ssn.soSecPart2 = splittedSsn[1];
      ssn.soSecPart3 = splittedSsn[2];
    }
    return ssn;
  }

  getToFromVo<VO, TO>(input: VO, output: TO) {
    if (input) {
      if (typeof input === 'string' || typeof input === 'number') {
        if (output === undefined || typeof input === typeof output) {
          output = input as any as TO;
        }
      } else {
        for (const prop of Object.getOwnPropertyNames(input)) {
          if (output && output.hasOwnProperty(prop) && typeof output[prop] === typeof input[prop]) {
            if (/*Object.getOwnPropertyNames(input[prop]).length > 0 &&*/ typeof input[prop] === 'object') {
              if (Array.isArray(input[prop])) {
                output[prop] = [];
                for (const arrayElement of input[prop]) {
                  // initialize a new object of type output[prop] array's object type
                  let tempObject = this.getnewObject(arrayElement);
                  tempObject = this.getToFromVo(arrayElement, tempObject);
                  output[prop].push(tempObject);
                }
              } else {
                output[prop] = this.getToFromVo(input[prop], output[prop]);
              }
            } else {
              console.log(typeof input[prop])
              if (input[prop] instanceof Date) {
                const copy = new Date();
                copy.setTime(input[prop].getTime());
                output[prop] = copy;
              } else {
                output[prop] = input[prop];
              }
            }
          } else {
            if (typeof input[prop] === 'string' || typeof input[prop] === 'number') {
              if (output[prop] === undefined || typeof input[prop] === typeof output[prop]) {
                output[prop] = input[prop] as any as TO;
              }
            }
          }
        }
      }
    }
    return output;
  }

  getnewObject<T>(input: T): any {

    const className = input.constructor.name;
    console.log(typeof input);
    if (className === 'PersonName') {
      return new PersonName();
    }
    if (className === 'SocialSecurityNumber') {
      return new SocialSecurityNumber();
    }
    if (className === 'HouseholdVerification') {
      return new HouseholdVerification();
    }
    if (className === 'ClientVerification') {
      return new ClientVerification();
    }
    if (className === 'ApiMessage') {
      return new ApiMessage();
    }
    if (className === 'HouseholdVerification') {
      return new HouseholdVerification();
    }
    return undefined;
  }

  padWithZero(value) {
    if (value < 10) {
      return '0' + value.toString();
    }
    return value;
  }

  changeDate(dob: any): any {
    let date: string[];
    let final: string[];
    if (dob) {
      if (dob.toString().indexOf(' ') > -1) {
        dob = this.padWithZero(dob.getMonth() + 1) + '/' + this.padWithZero(dob.getDate() - 1) + '/' + dob.getFullYear() as any;
      } else {
        final = dob.toString().split('T');
        date = final[0].split('-');
        if (date[0] && date[1] && date[2]) {
          dob = date[1] + '/' + date[2] + '/' + date[0] as any;
        }
      }
    } return dob;
  }

  changeDateFormat(el): string {
    if (el) {
      const date: any = el.split('-');
      return (date[1] + '/' + date[2] + '/' + date[0]);
    }
    return el;
  }

  checkDate(el: any): boolean {
    /*let date = el.toString().split('T');
    let today = new Date().toString().split(' ');
    let createdDate = new Date(date[0]).toString().split(' ');
    today[0].concat(today[1]).concat(today[2]).concat(today[3]);
    createdDate[0].concat(createdDate[1]).concat(createdDate[2]).concat(createdDate[3]);
    if(createdDate[0] < today[0])
      return false;
    else
      return true;*/
    if (el) {
      let todayDate = new Date();
      let createdDate = new Date(el);
      if (createdDate.getUTCDate === todayDate.getUTCDate &&
        createdDate.getUTCMonth === todayDate.getUTCMonth &&
        createdDate.getUTCFullYear === todayDate.getUTCFullYear)
        return true;
      else
        return false;
    }

  }

  changeDateYYYYMMDD(dob: any): any {
    let final: string[];
    if (dob) {
      final = dob.toString().split('T');
      return final[0];
    }
    return dob;
  }

  changeDateToMMDDYYYYFormat(el): any {
    if (el) {
      const date: any = el.split('-');
      if (date.length > 1) {
        return (date[1] + '/' + date[2] + '/' + date[0]);
      } else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMMDDFormat(el): any {
    if (el) {
      const date: any = el.split('/');
      if (date.length > 1) {
        return (date[2] + '-' + date[0] + '-' + date[1]);
      } else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToLocalFromNewDateFormat(el: any): any {
    if (el) {
      if (el instanceof Date) {
        let dt: any = el.toLocaleDateString();
        if (dt && dt.search('/'))
          return dt;
      }
      else {
        let cData = el.split('-');
        return cData[1] + '/' + cData[2] + '/' + cData[0];
      }
    }
    return el;
  }
  addPlaceHolder(classNme: string, placeHolder: string) {
    if (classNme && placeHolder) {
      var elements = document.getElementsByClassName(classNme);
      if (elements && elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].setAttribute('placeholder', placeHolder);
          // console.log(elements[i].setAttribute('placeholder', placeHolder));
        }
      }
    }
  }

  getLonglabelFromLookUp(code: string, lookupKey: string) {
    const lookup: LocalizedLookupBean[] = this.constantsService.getDropDownFromLookup(this.constantsService.getText(lookupKey));
    let longLabel: string = '';
    lookup.forEach(element => {
      if (element.lookupBean.code == code || element.lookupBean.shortLabel == code) {
        longLabel = element.lookupBean.longLabel;
      }
    });
    return longLabel;
  }

  getRace(raceCds: string[]): string {
    let races = '';
    if (raceCds.length > 0) {
      races += raceCds[0];
      /*let counter: number = 0;
      for (let entry of this.constantsService.getLookups()[0].localizedLookups['Race'].beans) {
          if (entry.lookupBean.code == raceCds[counter]) {
              races += ' ' + entry.lookupBean.shortLabel;
              counter ++;
          }
      }*/
    }
    return races;
  }

  getCurrentDate(): any {
    let currentDate: Date = new Date();
    if (currentDate) {
      if (currentDate.toString().indexOf(' ') > -1) {
        currentDate = this.padWithZero(currentDate.getMonth() + 1) + '/' +
          this.padWithZero(currentDate.getDate()) + '/' + currentDate.getFullYear() as any;
      }
    }
    return currentDate;
  }

  getHeadOfHousehold(): any {
    const currentHouseholdMembers = this.constantsService.getCurrentHousehold();
    if (this.shareService.getDataFromMap('currentModule') === this.constantsService.getText('screeningModule')) {
      for (const householdMember of currentHouseholdMembers) {
        if (householdMember.relationshipCd === 'SE') {
          return householdMember;
        }
      }
    } else {
      for (const householdMember of currentHouseholdMembers) {
        if (householdMember.relationshipCd === 'SE') {
          return householdMember;
        }
      }
    }
    return undefined;
  }

  retrieveHeadOfHousehold(): any {

    for (const householdMember of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {

      if (householdMember.headOfHouseholdYN && householdMember.headOfHouseholdYN === 'Y') {
        console.log('householdMember');
        console.log(householdMember);
        return householdMember;
      }
    }
    console.log('123undefined');
    return undefined;
  }

  changedStatusOnDefault(id: any, childScreen: Boolean = false) {
    const str: any = this.shareService.getDataFromMap('currentModule');
    if (id) {
      if (!childScreen) { this.curId = id; }
      this.curModule = this.constantsService.headTitle[str][id];
      let cId: any;
      if (this.curModule) {
        if (str === 'SCREENING_MODULE') {
          this.curScreen = 'screening';
          ConstantsService.headerTitle = 'Screening: ' + this.curModule;
          if (ConstantsService.humanServiceModel.benefitsCase.caseId !== '' &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== undefined) {
            cId = ConstantsService.humanServiceModel.benefitsCase.caseId;
          }
        } else if (str === 'INTERVIEW_MODULE') {
          this.curScreen = 'interview';
          ConstantsService.headerTitle = 'Interview: ' + this.curModule;
          if (ConstantsService.humanServiceModel &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== undefined &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== '') {
            cId = ConstantsService.humanServiceModel.benefitsCase.caseId;
          }
        } else if (str === 'FINALIZE_MODULE') {
          this.curScreen = 'finalize';
          ConstantsService.headerTitle = 'Finalize: ' + this.curModule;
          if (ConstantsService.finalizeServiceModel &&
            ConstantsService.finalizeServiceModel[0] &&
            ConstantsService.finalizeServiceModel[0].caseId !== '' &&
            ConstantsService.finalizeServiceModel[0].caseId !== undefined) {
            cId = ConstantsService.finalizeServiceModel[0].caseId;
          }
        } else if (str === 'CASE_NARRATIVE_MODULE') {
          this.curScreen = 'caseNarrative';
          ConstantsService.headerTitle = this.curModule;
          if (
            ConstantsService.humanServiceModel &&
            ConstantsService.humanServiceModel.benefitsCase &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== '' &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== undefined) {
            cId = ConstantsService.humanServiceModel.benefitsCase.caseId;
          }
        }
        else if (str === 'INQUIRY_MODULE') {
          this.curScreen = 'Inquiry';
          if (this.curModule && !this.curModule.trim())
            ConstantsService.headerTitle = 'Inquiry'
          else
            ConstantsService.headerTitle = 'Inquiry: ' + this.curModule;
          if (
            ConstantsService.humanServiceModel &&
            ConstantsService.humanServiceModel.benefitsCase &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== '' &&
            ConstantsService.humanServiceModel.benefitsCase.caseId !== undefined) {
            cId = ConstantsService.humanServiceModel.benefitsCase.caseId;
          }
        }

        if (id === 'initStatus' || id === 'intStatus') {
          ConstantsService.headerClientIdTitle = '';
          ConstantsService.headerClientIdTitle2 = '';
        } else {
          if (cId !== '' && cId !== undefined) {
            ConstantsService.headerClientIdTitle = 'Case ID: ' + cId;
          } else {
            if (str === 'SCREENING_MODULE' || str === 'CASE_NARRATIVE_MODULE') {
              ConstantsService.headerClientIdTitle = 'Case ID: ' + '<New Case>';
            } else if (str === 'INQUIRY_MODULE') {
              ConstantsService.headerClientIdTitle = '';
            }
            else {
              ConstantsService.headerClientIdTitle = 'Case ID: C452154';
            }
          }
          if (ConstantsService.headerTitle.trim() === 'Finalize: Eligibility') {
            ConstantsService.headerTitle = 'Finalize: ' + 'Medical Eligibility';
          }
          if (str !== 'FINALIZE_MODULE' && str !== 'CASE_NARRATIVE_MODULE' && str !== 'INQUIRY_MODULE') {
            if (this.getHeadOfHousehold() === undefined) {
              if ((str === 'SCREENING_MODULE' && id === 'clientStatus') || str === 'CASE_NARRATIVE_MODULE') {
                ConstantsService.headerClientIdTitle2 = '';
              } else {
                ConstantsService.headerClientIdTitle2 = 'HOH: Bud Davis';
              }
            } else if (this.getHeadOfHousehold() !== undefined) {
              ConstantsService.headerClientIdTitle2 = 'HOH:' +
                this.getFullName(this.getHeadOfHousehold().personName);
            }
          }
        }
      }
    }
  }

  changedStatus(id: string, bool1: Boolean) {
    if (bool1) {
      this.changedStatusOnDefault(id);
    }
    for (let i of this.constantsService.navigation[this.curScreen]) {
      if (i.id === id) {
        i.status = true;
        break;
      }
    }
  }

  formatAddress(address: Address): any {
    let fullAddress: string = undefined;
    if (!address) {
      return '';
    }
    if (address.addressLine1) {
      if (fullAddress) {
        fullAddress += ', ' + address.addressLine1;
      } else {
        fullAddress = address.addressLine1;
      }
    }
    if (address.addressLine2) {
      if (fullAddress) {
        fullAddress += ', ' + address.addressLine2;
      } else {
        fullAddress = address.addressLine2;
      }
    }
    if (address.city) {
      if (fullAddress) {
        fullAddress += ', ' + address.city;
      } else {
        fullAddress = address.city;
      }
    }
    if (address.zipcode) {
      if (fullAddress) {
        fullAddress += ', ' + address.zipcode;
      } else {
        fullAddress = address.zipcode;
      }
    }
    if (address.state) {
      this.statesDropdown = this.constantsService.getDropDownFromLookup(this.constantsService.getText('State'));
      this.statesDropdown.forEach(element => {
        if (element.lookupBean.code === address.state) {
          if (fullAddress) {
            fullAddress += ', ' + element.lookupBean.longLabel;
          } else {
            fullAddress = element.lookupBean.longLabel;
          }
        }
      });
    }
    if (address.county) {
      if (fullAddress) {
        fullAddress += ', ' + address.county;
      } else {
        fullAddress = address.county;
      }
    }
    /* if (address.district) {
        fullAddress += ', ' + address.district;
    } */
    return fullAddress;
  }

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  showLoader() {
    ConstantsService.loaderFlag = true;
  }
  hideLoader() {
    ConstantsService.loaderFlag = false;
  }
  removeExtraSpaces(str: String): String {
    if (str) {
      return str.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
    }
    return null;
  }
  compareBaseAmount(a, b) {
    const sortA = a.sortOrder;
    const sortB = b.sortOrder;

    let comparison = 0;
    if (sortA - sortB > 0) {
      comparison = 1;
    } else if (sortA - sortB < 0) {
      comparison = -1;
    } else
      return 0;
    return comparison;
  }
}
