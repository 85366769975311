import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Router} from "@angular/router";

@Component({
  selector: 'app-calendar-popup',
  templateUrl: './calendar-popup.component.html',
  styleUrls: ['./calendar-popup.component.css']
})

export class CalendarPopupComponent implements OnInit {
  public caseData: any = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>,
              private router: Router) {
  }

  ngOnInit() {
    this.caseData = this.data;
  }

  closePopUp(val) {
    if (val == 'closePop') {
      if (this.router.url == '/dashboard/consumer-app/intake-app/summary/sign') {
        this.router.navigate(['']).then(v => {
          this.dialogRef.close();
        });
      } else {
        this.dialogRef.close();
      }
    }
  }
}
