import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';
export class Institution extends BaseBean {
  constructor(
    public instituteId: string = '',
    public institutionType: string = '',
    public providerId: number = undefined,
    public providerName: string = '',
    public returnHomeIn6MonthsYN: string = '',
    public entryDate: Date = null,
    public exitDate: Date = null,
    public levelOfCare: string = '',
    public authorizationDate: Date = null,
    public medicareCertificationCode: string = '',
    public medicareDenialDate: Date = null,
    public instituteYN: string = ''
  ) {
    super();
  }
}
