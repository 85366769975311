import { BaseBean } from './base-bean.class';
export class VoterRegistration extends BaseBean{
    constructor(
        public voterIdNumber: string = '',
        public electionJudgeFlag: string = '',
        public helpRequiredFlag: string = '',
        public partyCode: number = undefined,
        public licenceNumber: string = '',
        public voterRegLicenseIdAvailCd: string = '',
        public otherPartyName: string = '',
    ) {
        super();
    }
}
