import { InstitutionalAdditionalInfo } from './institutional-additional-info.class';
import { BaseBean } from './base-bean.class';
import { Institution } from './institution.class';
import { ClientVerification } from './client-verification.class';
export class InstitutionalInformation extends BaseBean {
  constructor(
    // public currentInstitution: Institution = new Institution(),
    public institutions: Institution[] = [],
    public institutionalAdditionalInfo: InstitutionalAdditionalInfo[] = []
  ) {
    super();
  }
}
