import { BaseBean } from './base-bean.class';
import { PhoneNumber } from './phone-number.class';
import { Address } from './address.class';
import { Person } from './person.class';
import {ClientVerification} from "./client-verification.class";

export class Employer extends BaseBean{
  constructor(
    public emplrId: number = undefined,
    public companyLegalName: string = '',
    public employerAddress: Address = new Address(),
    public phoneNumber: PhoneNumber = new PhoneNumber(),
    public occupation: string = '',
    public email: string = '',
    public employerEIN: string = '',
    public empContactPerson: Person = new Person(),
    public empContactPersonPhone: string = '',
    public empContactPersonPhoneExt: string = '',
    public empContactPersonEmail: string = '',
    public employerFederalId: string = '',
    public employmentBeginDate: Date = null,
    public employmentEndDate: Date = null,
    public employmentVerification: ClientVerification = new ClientVerification(),

  ) {
    super();
  }
}
