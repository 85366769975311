import { BaseBean } from './base-bean.class';
export class AppointmentManagement extends BaseBean {
    constructor(
        public appointmentStartTime: Date = null,
        public appointmentEndTime: Date = null,
        public appointmentType: string = ''
    ) {
        super();
    }
}
